import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue'),
  },
  {
    path: '/inspire',
    name: 'Inspire',
    component: () => import('../components/paging.vue'),
    children: [
      {
        path: '/',
        name: 'Inspire',
        component: () => import('../views/inspire/index'),
      },
      {
        path: 'online-courses',
        name: 'OnlineCourses',
        component: () => import('../views/inspire/OnlineCourses'),
      },
      {
        path: 'sports-films',
        name: 'SportsFilms',
        component: () => import('../views/inspire/SportsFilms'),
      },
      {
        path: 'sports-films/:filmId',
        name: 'SportsFilmsDetail',
        component: () => import('../views/inspire/SportsFilmsDetail.vue'),
      },
      {
        path: '*',
        redirect: '/inspire',
      },
    ],
  },
  {
    path: '/safeguarding',
    name: 'Safeguarding',
    component: () => import('../views/Protection.vue'),
  },
  {
    path: '/coach-connect',
    name: 'CoachConnect',
    component: () => import('../components/paging.vue'),
    children: [
      {
        path: '/',
        name: 'Connect',
        component: () => import('../views/HomeCoach.vue'),
      },
      {
        path: 'how-verify',
        name: 'HowVerify',
        component: () => import('../views/HowVerify.vue'),
      },
      {
        path: 'coach-profiles',
        name: 'CoachProfiles',
        component: () => import('../views/CoachProfiles.vue'),
      },
      {
        path: 'create-profiles',
        name: 'CreateProfiles',
        component: () => import('../views/CreateProfiles.vue'),
      },
      {
        path: 'opportunities',
        name: 'Opportunities',
        component: () => import('../views/Opportunities.vue'),
      },
      {
        path: 'coach-corner',
        name: 'CoachCorner',
        component: () => import('../views/CoachCorner.vue'),
      }, 
      {
        path: 'school-corner',
        name: 'SchoolCorner',
        component: () => import('../views/SchoolCorner.vue'),
      }, 
      {
        path: 'parents-corner',
        name: 'ParentsCorner',
        component: () => import('../views/ParentsCorner.vue'),
      },
      {
        path: 'create-job',
        name: 'CreateJob',
        component: () => import('../views/CreateJob.vue'),
      },
      {
        path: 'copy-job',
        name: 'CopyJob',
        component: () => import('../views/CopyJob.vue'),
      },
      {
        path: '*',
        redirect: '/coach-connect',
      },
    ],
  },
  {
    path: '/corner',
    name: 'Corners',
    component: () => import('../components/paging.vue'),
    children: [
      {
        path: '/',
        name: 'Corner',
        component: () => import('../views/CoachCorner.vue'),
      },
      {
        path: 'coach-corner',
        name: 'CoachCorner',
        component: () => import('../views/CoachCorner.vue'),
      }, 
      {
        path: 'school-corner',
        name: 'SchoolCorner',
        component: () => import('../views/SchoolCorner.vue'),
      }, 
      {
        path: 'parents-corner',
        name: 'ParentsCorner',
        component: () => import('../views/ParentsCorner.vue'),
      },
      {
        path: '*',
        redirect: '/corner',
      },
    ],
  },
  {
    path: '/coach-action',
    name: 'CoachAction',
    component: () => import('../components/paging.vue'),
    children: [
      {
        path: '/',
        name: 'CoachAction',
        component: () => import('../views/CoachAction.vue'),
      },
      {
        path: 'coaching-tools',
        name: 'CoachTools',
        component: () => import('../views/CoachTools.vue'),
      },
      {
        path: 'sharing',
        name: 'Sharing',
        component: () => import('../views/Sharing.vue'),
      },
      {
        path: '*',
        redirect: '/coach-action',
      },
    ],
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('../views/Message.vue'),
  },
  {
    path: '/member-zone',
    name: 'MemberZone',
    component: () => import('../views/MemberZoneNew.vue'),
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('../views/Partners.vue'),
  },
  // {
  //   path: '/onlineCoursesDetail',
  //   name: 'OnlineCoursesDetail',
  //   component: () => import('../views/inspire/OnlineCoursesDetail.vue')
  // },
  {
    path: '/authorize-login',
    name: 'AuthorizeLogin',
    component: () => import('../views/inspire/AuthorizeLogin.vue'),
  },
  {
    path: '/authorize-token-login',
    name: 'AuthorizeTokenLogin',
    component: () => import('../views/inspire/AuthorizeTokenLogin.vue'),
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: () => import('../views/Footercontent/Copyright.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Footercontent/Privacy.vue'),
  },
  {
    path: '/term-of-use',
    name: 'TermOfUse',
    component: () => import('../views/Footercontent/TermOfUse.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

//路由跳轉到頁面頂部
router.afterEach(() => {
  window.scrollTo(0, 0)
})
