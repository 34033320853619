<template>
  <div id="app" :class="styleLang">
    <myHeader @getpath="getpath" v-if="showHeaderFooter" />
    <div id="main">
      <router-view />
    </div>
    <myFooter :pathname = "pathname" v-if="showHeaderFooter" />
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    myHeader: Header,
    myFooter: Footer,
  },
  data() {
    return {
      showHeaderFooter: true,
      styleLang: '',
      pathname:'',
    }
  },
  created() {
    this.isAnthorizeLoginPage()
    this.styleLang = window.localStorage.getItem('locale') || 'cn'
  },

  watch: {
    $route: 'isAnthorizeLoginPage', // 路由改变后触发的unchangedTab函数
  },
  methods: {
    getpath(val){
      this.pathname = val
    },
    isAnthorizeLoginPage() {
      let str = window.location.href
      let route = str.split('#/')[1].split('?')[0]
      if (route === 'authorize-login' || route === 'authorize-token-login') {
        this.showHeaderFooter = false
      }
    },
  },
}
</script>
<style lang="scss">
#app {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  letter-spacing: 2px;
  max-width: 1200PX;
  margin: 0 auto;
  min-height: 100vh;
  overflow: hidden;
  font-size: 18px;
  color: #333333;
  // font-family: 'NotoSansCJKtc-Regular';
}
#main {
  min-height: 500px;
}
.popper-width{
    max-width:40%;
}
@media screen and (max-width: 480px){
  .el-message-box{
    width: 90% !important;
  }
}
</style>
