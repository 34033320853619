import axios from 'axios'
import md5 from 'js-md5'
import spacetime from 'spacetime'
let time = spacetime.now('Asia/Hong_Kong').unixFmt('YYYY-MM-dd HH:mm:ss')
const nonce = `api${+new Date()}${(Math.random() * 1000).toFixed(0)}`
const secret = md5(`${'564452e4-6501-11e9-bd7e-b8cacc9eb5b0--kwock--'}${time}${nonce}`)

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'https://ihk-coach-api-staging.wistkey.com/',
  // baseURL: 'https://sportscoachhk-api.inspiringhk.org/',
  timeout: 5000, // request timeout
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'app-id': '43f9e1c1-05b0-45da-aaff-8ff8efade815',
    'request-time': time,
    secret: secret,
    'Accept-Language': window.localStorage.getItem('locale') === 'en' ? 'en' : 'zh_hk',
  },
})

// 请求拦截器 - 所有的请求开始之前先到此处
request.interceptors.request.use(
  (config) => {
    // 1.动画、请求token等
    var token = window.sessionStorage.getItem('token') || ''
    // 2.判断
    if (token) {
      // 设置请求头（后期请求接口 http请求头携带Authorization参数）
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器 --- 所有请求的相应先到此处
request.interceptors.response.use(
  (response) => {
    const res = response.data
    const status = response.status
    if (status !== 200 && status !== 201) {
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  (error) => {
    console.log({ error }) // for debug
    return Promise.reject(error)
  }
)

export default request
