import request from '@/utils/request'

// 获取用户注册
export function registerUser(data) {
  return request({
    method: 'POST',
    url: '/member-register',
    data,
  })
}
// 获取用户登陆
export function loginUser(data) {
  return request({
    method: 'POST',
    url: '/member-login',
    data,
  })
}
// 获取用户信息
export function getUserProfile() {
  return request({
    method: 'GET',
    url: '/self-profile',
  })
}
// 修改用户信息
export function updateUserProfile(data) {
  return request({
    method: 'PUT',
    url: '/self-profile',
    data,
  })
}
// 修改用户密碼
export function updatePassword(data) {
  return request({
    method: 'PUT',
    url: '/self-pwd',
    data,
  })
}

export function forgetPasswordCode(data) {
  return request({
    method: 'POST',
    url: '/forget-password/code',
    data,
  })
}

export function forgetPassword(data) {
  return request({
    method: 'PUT',
    url: '/forget-password',
    data,
  })
}

// facebook登陆
export function loginFacebookUser(data) {
  return request({
    method: 'POST',
    url: '/member-login/facebook',
    data,
  })
}

export function loginThirdPartyUser(party, code) {
  if (party == 'google') {
    return request({
      method: 'POST',
      url: '/member-login/google',
      data: {
        code,
      },
    })
  } else if (party == 'linkedin') {
    return request({
      method: 'POST',
      url: '/member-login/linkedin',
      data: {
        code,
      },
    })
  }
}

export function getCourseReport() {
  return request({
    method: 'GET',
    url: '/course-report',
  })
}

export function validateToken(token) {
  return request({
    method: 'POST',
    url: '/validate',
    data: {
      token,
    },
  })
}
export function submitRecord(data) {
  return request({
    method: 'POST',
    url: '/voluntary-record/submit',
    data,
  })
}
export function deleteRecord(id) {
  return request({
    method: 'DELETE',
    url: '/user-records/' + id,
  })
}
export function mostRecordList(data) {
  return request({
    method: 'GET',
    url: '/most-record-list',
    data,
  })
}

export function learnList(data) {
  return request({
    method: 'GET',
    url: '/learn-list',
    data,
  })
}

export function applySharingEvent(object) {
  return request({
    method: 'POST',
    url: '/apply-sharing-event',
    data: object,
  })
}
