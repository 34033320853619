<template>
  <div class="join-us-bg">
    <div class="img-box" @click="register" v-if='!loggedIn'>
      <p>{{ $t("Home.join_tit") }}</p>
    </div>
    <p class="join-txt">{{ $t("Home.join_txt") }}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loggedIn: false
    }
  },
  created() {
    this.checkLogin()
  },
  methods:{
    register(){
      this.$router.push('/message');
    },
    checkLogin() {
      let token = window.sessionStorage.getItem("token");
      this.loggedIn = token==null ? false : true
    }
  }
};
</script>

<style lang="scss" scoped>
.join-us-bg {
  height: 648px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: url("../assets/images/homepage-6-new.png") no-repeat;
  background-size: 100%;
  .img-box {
    height: 115px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../assets/images/button-join.png") no-repeat center;
    background-size: 689px 115px;
    cursor: pointer;
    p {
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      padding-left: 40px;
      // font-family: "MHeiHK-Xbold";
    }
  }
  .join-txt {
    font-size: 32px;
    color: #fff;
    font-weight: bold;
    margin: 62px auto 0 auto;
    letter-spacing: 2px;
    padding: 0 30px;
    transform: skew(-10deg);
  }
}
@media screen and (max-width: 480px) {
  .join-us-bg {
    height: 358px;
    background: url("../assets/images/mobile-homepage-6-new.jpg") no-repeat center;
    background-size: 750px 358px;
    background-position-x: -10%;
    .img-box {
      background: url("../assets/images/button-join.png") no-repeat center;
      background-size: 416px 69px;
      p {
        font-size: 22px;
        font-weight: bold;
        letter-spacing: 0;
        margin-left: 8px;
      }
    }
    .join-txt {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      letter-spacing: 0px;
      margin: 20px 0 0 0;
    }
  }
}
</style>