<template>
  <div id="Home" class="home-us">
    <div class="banner-con">
      <div class="top-left-con">
        <div class="line-one" v-visible="false"></div>
        <div class="line-two" v-visible="false"></div>
        <div class="line-three"></div>
        <!--<p class="banner-tit1" v-visible='$i18n.locale=="en"'>
          {{ $t("Home.banner_tit1") }}
        </p>-->
        <p class="banner-tit1">
          {{ $t('Home.banner_tit1') }}
        </p>
        <p class="banner-tit2">
          {{ $t('Home.banner_tit2') }}
        </p>
        <p class="more" @click="goPush('/about-us')">{{ $t('Home.more') }}<i class="el-icon-arrow-right"></i></p>
      </div>
      <swiper :options="swiperOption" class="swiper" ref="mySwiper">
        <swiper-slide v-for="(item, index) in swiperImg" :key="index" class="swiper-item1">
          <div class="box">
            <img class="img" :src="item.img" alt="" />
          </div>
          <div class="top-right-con"></div>
          <div class="bottom-left-con"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination" />
      </swiper>
    </div>

    <!--<center> 
      <div class='register'>
        <router-link to='/message' class='link'>
          <div class='register-txt-container'>
              <center>{{ $t('Home.Register') }}</center>
          </div>
        </router-link>
      </div>
      <div class='download reg-list'>
        <a href='/pdf/coaching.pdf' target='_blank' class='link reg-item'>
          <div class='download-txt-container' v-if='screenWidth<=480 && $i18n.locale=="en"' style='top: 25% !important'>
              <center>{{ $t('Home.download') }}</center>
          </div>
          <div v-else class='download-txt-container'>
              <center>{{ $t('Home.download') }}</center>
          </div>
        </a>
      </div>
    </center>-->
    <div class="corner-container">
      <p class="link link1" v-html="$t('Home.corner_1')"></p>
      <p class="link link2" v-html="$t('Home.corner_2')"></p>
      <p class="link link3" v-html="$t('Home.corner_3')"></p>
    </div>
    <p class="tit1">{{ $t('Home.txt1') }}</p>

    <iframe
      :width="screenWidth > 480 ? '800' : '330'"
      :height="screenWidth > 480 ? '460' : '170'"
      src="https://www.youtube.com/embed/mq_bU5eYZls"
      title="凝動教練培訓學院 全新教練工作配對平台現正啟動"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>

    <div class="plan-obj-title">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>

      <p>{{ $t('AboutUs.paln_obj_title') }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>
    <div class="plan-txt">
      <span>{{ $t('AboutUs.plan_con') }}</span>
    </div>

    <div class="plan-con">
      <div class="plan-list">
        <div class="item" v-for="(item, index) in planList" :key="index">
          <a :href="item.url" class="item-img"><img :src="item.img" alt="" /><img :src="item.img2" alt="" /></a>
          <div class="item-con">
            <p class="tit" v-html="$t('AboutUs.' + item.tit)"></p>
            <p class="txt">{{ $t('AboutUs.' + item.txt) }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="sports-title">
      <div class="left">
        <p class="line-left1"></p>
        <p class="line-left2"></p>
      </div>
      <p>{{ $t('Home.title1') }}</p>
      <div class="right">
        <p class="line-right1"></p>
        <p class="line-right2"></p>
      </div>
    </div>
    <div class="list-scroll">
      <div class="list-bg">
        <div v-for="(item, index) in Lists" :key="index" class="list-items">
          <div class="list-img">
            <div class="img-box">
              <div class="coming-soon-container">
                <img class="homepage-img" :src="item.img" alt="" />

                <!-- <div
                  class="coming-soon"
                  v-if="index != 0"
                  :style="{
                    'white-space': $i18n.locale == 'cn' ? 'nowrap' : 'normal'
                  }"
                >
                  {{ $t('Home.coming_soon') }}
                </div> -->
              </div>
            </div>
            <p class="title">{{ $t('Home.' + item.tits) }}</p>
            <div class="more_bg">
              <p class="more" @click="goPush(item.jump)">{{ $t('Home.' + item.detail) }}<i class="el-icon-arrow-right"></i></p>
            </div>
          </div>
          <div class="item-drow">
            <div class="content">
              <p class="txt">{{ $t('Home.' + item.txt1) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt2) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt3) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt4) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt5) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt6) }}</p>
              <p class="txt">{{ $t('Home.' + item.txt7) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <joinUsFooter />
  </div>
</template>

<script>
import JoinUsFooter from '../components/JoinUsFooter'
import { loginThirdPartyUser, getUserProfile } from '@/api/memberUser'
export default {
  name: 'Home',
  components: {
    JoinUsFooter,
  },
  data() {
    return {
      screenWidth: window.document.body.clientWidth,
      swiperOption: {
        // slidesPerView: 1,
        // spaceBetween: 30,
        loop: true,
        speed: 1500,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false, // 操作swiper后不会停止播放
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 允许点击小圆点跳转
        },
      },
      swiperImg: [
        {
          img: 'https://df7ocie544p6c.cloudfront.net/images/banner1.jpg',
        },
        {
          img: 'https://df7ocie544p6c.cloudfront.net/images/banner2.jpg',
        },
        {
          img: 'https://df7ocie544p6c.cloudfront.net/images/banner3.jpg',
        },
        {
          img: 'https://df7ocie544p6c.cloudfront.net/images/banner4.jpg',
        },
      ],
      planList: [
        {
          img: require('../assets/images/coach-1.png'),
          img2: require('../assets/images/coach-2.png'),
          tit: 'plan_tit1',
          txt: 'plan_txt1',
          url: '/#/coach-connect/coach-corner',
        },
        {
          img: require('../assets/images/schools-3.png'),
          img2: require('../assets/images/schools-4.png'),
          tit: 'plan_tit2',
          txt: 'plan_txt2',
          url: '/#/coach-connect/school-corner',
        },
        {
          img: require('../assets/images/parent-5.png'),
          tit: 'plan_tit3',
          txt: 'plan_txt3',
          url: '/#/coach-connect/parents-corner',
        },
      ],
      Lists: [
        {
          img: require('../assets/images/homepage-1.webp'),
          tits: 'nav_img1_tit',
          detail: 'more1_detail',
          tit: 'nav_img1_tit',
          txt1: 'nav_img1_txt1',
          txt2: 'nav_img1_txt2',
          txt3: 'nav_img1_txt3',
          txt4: 'nav_img1_txt4',
          txt5: 'nav_img1_txt5',
          txt6: 'nav_img1_txt6',
          txt7: 'null',
          jump: '/inspire',
        },
        {
          img: require('../assets/images/homepage-2.webp'),
          tits: 'nav_img2_tit',
          detail: 'more2_detail',
          tit: 'nav_img2_tit',
          txt1: 'nav_img2_txt1',
          txt2: 'nav_img2_txt2',
          txt3: 'nav_img2_txt3',
          txt4: 'nav_img2_txt4',
          txt5: 'null',
          txt6: 'null',
          txt7: 'null',
          jump: '/safeguarding',
        },
        {
          img: require('../assets/images/homepage-3.webp'),
          tits: 'nav_img3_tit',
          detail: 'more3_detail',
          tit: 'nav_img3_tit',
          txt1: 'nav_img3_txt1',
          txt2: 'nav_img3_txt2',
          txt3: 'nav_img3_txt3',
          txt4: 'nav_img3_txt4',
          txt5: 'nav_img3_txt5',
          txt6: 'nav_img3_txt6',
          txt7: 'null',
          jump: '/coach-connect',
        },
        {
          img: require('../assets/images/homepage-4.webp'),
          tits: 'nav_img4_tit',
          detail: 'more4_detail',
          tit: 'nav_img4_tit',
          txt1: 'nav_img4_txt1',
          txt2: 'nav_img4_txt2',
          txt3: 'nav_img4_txt3',
          txt4: 'nav_img4_txt4',
          txt5: 'nav_img4_txt5',
          txt6: 'nav_img4_txt6',
          txt7: 'nav_img4_txt7',
          jump: '',
        },
      ],
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  created() {
    this.handleThirdPartyLogin()
  },
  methods: {
    goPush(page) {
      this.$router.push(page)
    },
    handleThirdPartyLogin: async function () {
      let res = window.location.href.match(/code=[^&]+&?/g)
      if (!res) {
        return
      }
      let code = res[0].substring(5, res[0].length - 1)
      let party = window.sessionStorage.getItem('party')
      try {
        let token = await loginThirdPartyUser(party, code)
        if (token.result == 'success') {
          window.sessionStorage.setItem('token', token.data.access_token)
          window.sessionStorage.setItem('third_party', true)
          this.$message({
            message: this.$t('Home.login_success'),
            type: 'success',
          })
          window.location.href = '/#/inspire'
        }
      } catch (err) {
        this.$message({
          message: this.$t('Home.login_fail'),
          type: 'error',
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.download {
  display: flex;
  align-items: center;
  width: 70%;
  height: 100px;
  margin: 45px 0 90px 0;
  background: linear-gradient(90deg, rgba(241, 89, 34, 1) 0%, rgba(241, 89, 34, 1) 35%, rgba(241, 89, 34, 0.32566530029980745) 100%);
  text-shadow: 2px 0px lightsalmon;
  transform: skew(-15deg);
  object-fit: cover;
  cursor: pointer;
  .text-item {
    transform: skew(15deg);
  }
}
.corner-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  margin-top: 100px;
  font-size: 25px;
  width: 90%;
  margin: 0 auto;
  padding-top: 90px;
  .link {
    width: 50%;
    font-weight: bold;
    padding: 20px;
    text-decoration: underline;
    display: flex;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    color: white !important;
  }
  .link1 {
    background: #1c4587;
  }
  .link2 {
    background: #f15922;
  }
  .link3 {
    background: #276354;
  }
}
.register {
  background: rgb(14, 133, 212);
  background: linear-gradient(90deg, rgba(14, 133, 212, 1) 0%, rgba(14, 133, 212, 1) 32%, rgba(0, 212, 255, 1) 100%);
  width: 40%;
  transform: skew(-15deg);
  height: 150px;
  object-fit: cover;
  margin-top: 90px;
  margin-left: -15%;
  cursor: pointer;
  text-shadow: 2px 0px lightblue;
}
.download-link {
  display: block;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.download-link,
.download-link:hover,
.download-link:visited,
.download-link:active {
  text-decoration: none;
  color: white;
}
.line {
  background-color: #d2d2d2;
  height: 1px;
  margin: 60px 10%;
}
.coming-soon-container {
  display: flex;
  position: relative;
  text-align: center;
  color: white;
}
.coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  font-weight: 900;
  color: #747678;
}
.home-us {
  .banner-con {
    position: relative;
    .top-left-con {
      width: 62%;
      height: 500px;
      background: #28327b;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg) translateX(-9%);
      z-index: 2;
      .line-one {
        width: 338px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 24%;
        right: 2%;
      }
      .line-two {
        width: 120px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 42%;
        right: 2%;
      }
      .banner-tit1 {
        width: auto;
        margin-top: 18%;
        margin-left: 2%;
        padding-left: 13%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
        // font-family: "NotoSansCJKtc-Bold";
        // font-family: "NotoSansCJKtc-Black";
      }
      .banner-tit2 {
        width: auto;
        margin-top: -2%;
        margin-left: 4%;
        padding-left: 10%;
        text-align: left;
        font-size: 70px !important;
        color: #fff;
        transform: skew(6deg);
        font-weight: bold;
        // font-family: "NotoSansCJKtc-Black";
      }
      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #fff;
        transform: skew(15deg);
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .swiper {
      height: 620px;
      .swiper-item1 {
        position: relative;
        overflow: hidden;
        .box {
          position: absolute;
          right: 0;
          bottom: 0;
          height: 90%;
          width: 50%;
          overflow: hidden;
          transform: skew(-15deg) translateX(12%);
          .img {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 108%;
            transform: skew(15deg) translateX(-9%);
            object-fit: cover;
          }
        }
        .top-right-con {
          width: 40%;
          height: 10%;
          background: #d7ede6;
          position: absolute;
          top: 0;
          right: 0;
          transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
          width: 53.1%;
          height: 20%;
          background: #f15922;
          position: absolute;
          bottom: 0;
          left: 0;
          transform: skew(-15deg) translateX(-3%);
        }
      }
    }
  }
  .tit1 {
    font-size: 26px;
    margin: 3% auto;
    width: 80%;
    letter-spacing: 2px;
    text-align: center;
  }
  .tit2 {
    width: 80%;
    font-size: 30px;
    color: #f15922;
    margin: 3% auto;
    p {
      text-align: left;
      margin: 20px 0;
    }
    p:nth-child(1) {
      text-align: center;
      font-weight: bold;
    }
  }
  .sports-title {
    margin: 4% auto;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f15922;
    font-size: 32px;
    font-weight: bold;
    // font-family: "NotoSansCJKtc-Bold";
    // background: red;
    transform: skew(-10deg);
    overflow: visible;
    .left {
      width: 60px;
      height: 50px;
      position: relative;
      .line-left1 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 18px;
        left: 12px;
      }
      .line-left2 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 26px;
        left: 0px;
      }
    }
    .right {
      width: 60px;
      height: 50px;
      position: relative;
      .line-right1 {
        width: 40px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 18px;
        right: 0px;
      }
      .line-right2 {
        width: 20px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 26px;
        right: 18px;
      }
    }
  }
  .list-scroll {
    .list-bg {
      width: 95%;
      margin: 0 auto 100px auto;
      display: flex;
      .list-items {
        width: 35%;
        margin: 0 0.5%;
        position: relative;
        cursor: pointer;
        .list-img {
          height: 555px;
          transform: skew(-6deg);
          overflow: hidden;
          position: relative;
          .img-box {
            width: 100%;
            .homepage-img {
              width: 100%;
              height: 450px;
              object-fit: cover;
              margin: 60px 0 0 0;
            }
          }
          .title {
            width: 100%;
            height: 60px;
            line-height: 60px;
            position: absolute;
            top: 0%;
            opacity: 1;
            color: #fff;
            font-size: 33px;
            font-weight: bold;
            background: #28327b;
            text-align: center;
            transition: all ease 0.6s;
          }
          .more_bg {
            width: 100%;
            height: 110px;
            line-height: 30px;
            color: white;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0px;
            background: rgba(40, 50, 123, 1);
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .more {
              text-align: right;
              margin-right: 3%;
            }
          }
        }
        .item-drow {
          color: #fff;
          padding: 20px;
          transform: translateX(-29px);
          background: rgba(40, 50, 123, 0.7);
          // background: rgba(186, 186, 207, 0.082);
          display: none;
          .content {
            text-align: left;
            .txt {
              font-size: 20px;
              letter-spacing: 0px;
            }
            .txt:nth-child(1) {
              height: 13%;
              font-size: 22px;
              font-weight: bold;
              display: flex;
              white-space: pre;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
            .txt:nth-child(2) {
              margin-top: 8px;
              font-weight: bold;
              //white-space: pre;
            }
            .txt:nth-child(3) {
              margin-top: 8px;
            }
            .txt:nth-child(4) {
              margin-bottom: 8px;
              //white-space: pre;
            }
            .txt:nth-child(5) {
              margin-top: 8px;
              font-weight: bold;
              //white-space: pre;
            }
            .txt:nth-child(6) {
              margin-top: 8px;
              //white-space: pre;
            }
            .txt:nth-child(7) {
              margin-top: 8px;
              //white-space: pre;
            }
            .img-box {
              text-align: center;
              .img {
                width: 65%;
              }
            }
          }
        }
      }
      .list-items:hover .item-drow {
        display: block;
        animation: hide 0.5s;
      }
      @keyframes hide {
        0% {
          opacity: 0;
        }
        40% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      .list-items:nth-child(1) {
        .list-img:nth-child(1) {
          .img-box {
            .homepage-img {
              width: 120%;
              transform: translateX(-16%);
            }
          }
        }
      }
      .list-items:nth-child(2) {
        .list-img {
          .title {
            background: #f15922;
          }
          .more_bg {
            background: rgba(241, 89, 34, 1);
          }
        }
        .item-drow {
          // background: #f15922;
          background: rgba(241, 89, 34, 0.7);
        }
      }
      .list-items:nth-child(3) {
        .title {
          background: rgba(40, 50, 123, 1);
        }
        .more_bg {
          background: rgba(40, 50, 123, 1);
        }
        .item-drow {
          background: rgba(40, 50, 123, 0.7);
        }
      }
      .list-items:nth-child(4) {
        .title {
          background: #f15922;
        }
        .more_bg {
          background: rgba(241, 89, 34, 1);
        }
        .item-drow {
          background: rgba(241, 89, 34, 0.7);

          .content {
            .txt:nth-child(3) {
              margin-bottom: 15px;
            }
            .txt:nth-child(4) {
              font-weight: bold;
              margin-bottom: 0px;
            }
            .txt:nth-child(5) {
              font-weight: normal;
              margin-bottom: 15px;
            }
            .txt:nth-child(6) {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#Home {
  .swiper-pagination {
    text-align: right !important;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: 15px;
    right: 45px;
    left: unset !important;
    width: 30%;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 6px !important;
      display: inline-block;
      background: #fff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #f15922;
      opacity: 1;
    }
  }
  .plan-obj-title {
    margin: 4% auto;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f15922;
    font-size: 32px;
    font-weight: bold;
    // font-family: "NotoSansCJKtc-Bold";
    transform: skew(-10deg);
    overflow: visible;
    .left {
      width: 60px;
      height: 50px;
      position: relative;
      .line-left1 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 20px;
        left: 12px;
      }
      .line-left2 {
        width: 30px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 28px;
        left: 0px;
      }
    }
    .right {
      width: 60px;
      height: 50px;
      position: relative;
      .line-right1 {
        width: 40px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 20px;
        right: 0px;
      }
      .line-right2 {
        width: 20px;
        height: 2px;
        background: #f15922;
        position: absolute;
        top: 28px;
        right: 18px;
      }
    }
  }
  .plan-txt {
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    background: #f15922;
    font-size: 29px;
    color: #fff;
    padding: 18px;
    z-index: 2;
    transform: skew(-10deg);
    span {
      display: block;
      font-size: 26px;
      transform: skew(10deg);
    }
  }
  .plan-con {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(241, 89, 34, 0.5);
    margin-top: 300px;
    margin-bottom: 100px;
    .plan-list {
      width: 80%;
      margin: -3rem auto 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .item {
        width: 15%;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 1;
        padding: 98px;
        // height: 400px;
        z-index: 1;
        .item-img {
          flex: 1;
          display: flex;
          flex-direction: row;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-con {
          flex: 1;
          width: 400px;
          .tit {
            margin: 5% auto;
            font-size: 28px;
            font-size: #000;
            font-weight: 600;
          }
          .txt {
            font-size: 22px;
            margin: 8% auto;
          }
        }
      }
    }
    .plan-txt {
      width: 90%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f15922;
      color: #fff;
      padding: 18px;
      bottom: -70px;
      z-index: 2;
      transform: skew(-10deg);
      span {
        font-size: 30px;
        transform: skew(10deg);
      }
    }
  }
  .logo img {
    max-width: 180px;
    margin-top: 100px;
  }
}
@media screen and (max-width: 480px) {
  .download {
    background: rgb(14, 133, 212);
    background: linear-gradient(90deg, rgba(14, 133, 212, 1) 0%, rgba(14, 133, 212, 1) 32%, rgba(0, 212, 255, 1) 100%);
    width: 50%;
    transform: skew(-15deg);
    height: 80px !important;
    object-fit: cover;
    margin-top: 25px !important;
    margin-bottom: 30px !important;
    /*margin-right: -15%;*/
    cursor: pointer;
    text-shadow: 2px 0px lightblue;
  }

  .register {
    background: rgb(14, 133, 212);
    background: linear-gradient(90deg, rgba(14, 133, 212, 1) 0%, rgba(14, 133, 212, 1) 32%, rgba(0, 212, 255, 1) 100%);
    width: 40%;
    transform: skew(-15deg);
    height: 100px !important;
    object-fit: cover;
    margin-top: 50px !important;
    margin-left: -15%;
    background: linear-gradient(90deg, rgba(14, 133, 212, 1) 0%, rgba(14, 133, 212, 1) 32%, rgba(0, 212, 255, 1) 100%);
    text-shadow: 2px 0px lightblue;
  }
  .register-txt-container {
    top: 35px !important;
    position: absolute;
    width: 100%;
    font-size: 20px !important;
    font-weight: bold;
    object-fit: cover;
    color: white;
  }
  .download-txt-container {
    width: 100%;
    font-size: 28px !important;
    font-weight: bold;
    margin-top: -10px;
    object-fit: cover;
    color: white;
  }
  #Home {
    .swiper-container-horizontal > .swiper-pagination-bullets {
      top: 15px;
      right: 4%;
      .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50% !important;
      }
    }
    .swiper {
      height: 500px;
      .swiper-item1 {
        .top-right-con {
          width: 42%;
          height: 14%;
          transform: skew(-15deg) translateX(7%);
        }
        .box {
          height: 86%;
          width: 54.5%;
          .img {
            height: 100%;
            width: 120%;
            right: 0%;
          }
        }
      }
    }
    .top-left-con {
      width: 76%;
      height: 368px;
      top: 0;
      left: -16%;
      .banner-tit1 {
        font-size: 35px !important;
        margin-top: 15% !important;
        padding-left: 30%;
      }
      .banner-tit2 {
        margin-top: 2% !important;
        padding-left: 30% !important;
        font-size: 35px !important;
        padding-left: 0;
      }
      .more {
        font-size: 20px;
        bottom: 12px;
        right: 6px;
      }
      .line-two {
        width: 14px;
        top: 33%;
      }
      .line-one {
        width: 60px;
        top: 20%;
      }
    }
    .bottom-left-con {
      width: 48%;
      height: 30%;
      left: -2%;
    }
    .tit1 {
      width: 90%;
      margin: 50px auto;
      font-size: 32px;
      text-align: left;
      letter-spacing: 1px;
    }
    .tit2 {
      width: 90%;
    }
    .sports-title {
      letter-spacing: 0px;
      width: 90%;
      font-size: 32px;
      .left {
        .line-left1 {
          width: 20px;
        }
        .line-left2 {
          width: 25px;
        }
      }
      .right {
        .line-right1 {
          width: 28px;
        }
        .line-right2 {
          width: 20px;
          right: 6px;
        }
      }
    }
    .list-scroll {
      width: 100%;
      margin: 0% auto;
      overflow: hidden;
      overflow-x: auto;
      .list-bg {
        width: 1100px;
        padding: 0 6%;
      }
    }
  }
}
</style>
