import Vue from 'vue'
import locale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import cn from './langs/cn'
import en from './langs/en'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: window.localStorage.getItem('locale') || 'cn',
  messages:{
    en,
    cn,
  }
})
locale.i18n((key, value) => i18n.t(key, value)) 

export default i18n