// import cnLocale from 'element-ui/lib/locale/lang/zh-CN'
import cnLocale from 'element-ui/lib/locale/lang/zh-TW' // element-ui lang

const cn = {
  coming_soon: '即將推出',
  cn: '繁體中文',
  en: 'ENGLISH',
  Log_out: '登出',
  log_in: '登入',
  welcome: '歡迎，XXX',
  Nav: {
    CoachConnect: '教練網絡',
    VerifyCoach: '凝動教你如何核實教練',
    CoachProfiles: '搜尋教練',
    Opportunities: '尋找教學機會',
    CoachAction: '教練行動',
    CoachingTools: '教學資源',
    OnlineExperts: '網上專家分享',
    CoachCorner: `教練專區    `,
    SchoolCorner: `學校、社福及工商業界專區`,
    ParentsCorner: `家長專區`,
  },
  HomeNew: {
    Finished: '完成單元',
    Accumulated: '累積',
    hours: '小時',
    bg1: '太古慈善信託基金',
    bg2: '',
    bg3: '教練網絡',
    info: '',
    about: '關於教練網絡',
    aboutinfo: '關於教練凝聚',
    inform1: '「教練行動」由凝動香港體育基金主辦，太古集團慈善信託基金捐助。本部分向平台會員提供可下載的一站式網上教學資源，包括教學方案及訓練大鋼、教練筆記、訂立學習目標的小冊子等。',
    inform2: '網上教學資源協助運動教練、體育老師或任何希望認識更多體育促進兒童及青少年發展的專業知識的相關人士在完成網上學習平台後，將平台所學的知識應用到教學之上，從而提升教學素質及專業水平。    ',
    inform3:
      '同時，本部分亦會舉辦網上專家研討會，從女性在運動參與上遇到的挑戰與處理、鼓勵有特殊學習需要的兒童及青少年參與運動、運動與心理健康等方向進一步探討相關議題，讓教練持續學習，了解和認識業界與服務對象的需要。',
    info1: '「教練網絡」由凝動香港體育基金策劃，太古集團慈善信託基金捐助。',
    info2:
      '本部分建立運動教練的連繫交流平台，讓教練建構屬於自己的個人簡歷，由學歷、教學照片、教學方針等都能夠一目了然。成功建立個人檔案後，教練可以尋找合適自己的收費教學機會及提供義務教學機會，形成本地教練網絡力量。而學校、非牟利機構、慈善團體或任何希望舉辦團體運動課程的人士，均可以利用本平台建立教學機會，瀏覽及連繫相關教練的個人檔案。',
    info3:
      '計劃一方面連繫教練與地區組織，同時亦鼓勵教練回饋社區，完成最少3小時的義務工作或教學，並透過網上系統上載義務工作證明作審核。首200名完成回饋社區時數的運動教練，將可獲得運動禮劵或禮品作嘉許。',
    pic1: '搜尋教練',
    pic2: '建立教練檔案',
    pic3: '尋找教學機會',
    pic4: '建立教學活動',
    title1: '搜尋教練',
    sport1: '全部',
    sport2: '',
    sport3: '新興運動',
    sport4: '棒類運動',
    sport5: '排類運動',
    title2: '瀏覧最新的收費',
    title3: '與義務教學機會',
    btn1: '建立教學活動',
    btn2: '瀏覽更多',
    ranking1: '學習排行榜',
    ranking2: '參與最多義務教學',
  },
  Verify: {
    title: '凝動教你如何核實教練',
    tit: '核實教練步驟',
    title3: '核實教練步驟',
    step1: '了解教練',
    step2: '教練是否已進行性罪行定罪紀錄查核',
    step3: '與教練進行會談',
    step4: '核實性罪行定罪紀錄',
    step5: '觀察教練的上課狀況',
    step1_1: '瀏覽和比較不同教練的教學背景及經驗，以找出最適合的教練人選。教練在檔案中的「了解更多」會分享甚麼資訊，是不是對自己的職業充滿熱誠、理念是否跟相近、會否主動吸收新知識。',
    step1_2:
      '舉例來說，你可以從一個教練的證書中看到他有沒有持續進修（證書並不是越多越好，這只是你可以觀察的指標之一）。你亦可以從教練分享自己的訓練中，了解對方的訓練方式，是否深入了解運動項目的細節跟技巧。',
    step1_3: '如有需要，你可以向教練要求提供教練執照、學歷及教學證明。如屬於本地認可體育機構或總會的註冊教練，一般可以透過本地體育總會的網頁來查冊教練名字，加強教練的認受性。',
    step1_4: '本地認可體育機構或總會',
    step2_1: '什甚是性罪行定罪紀錄查核？',
    step2_2: '由香港警務處推行，《性罪行定罪紀錄查核》機制該僱主在聘用僱員從事與兒童或精神上無行為能力人士有關工作的職位時，能夠知道該申請人是否有性罪行定罪紀錄，有助加強保護這類人士避免受性侵犯。',
    step2_3: '教練應該如何申請查核？',
    step2_4: '所需文件：',
    step2_5: '(1) 香港身份證；',
    step2_6: '(2) 已填妥的申請表；',
    step2_7: '(3) 申請費用 (請在警務處網頁查詢)；',
    step2_8: '(4) 由相關機構發出的信件，證明該人可能會被雇用於與兒童有關的工作',
    step2_9: '地址：',
    step2_10: '香港灣仔軍器廠街一號警察總部警政大樓十四樓 香港警務處鑑證科 ',
    step2_11: '電話：',
    step2_12: '3660 7449 ',
    step2_13: '更多資訊：',
    step2_14: `<a style='color:#16287a;text-decoration:underline;' href='https://www.police.gov.hk/ppp_tc/11_useful_info/scrc.html'>police.gov.hk/ppp_tc/11_useful_info/scrc.html</a>`,
    step3_1:
      '凝動教練培訓學院已經推出「凝動守護兒童體育學習平台」，是香港首個與體育相關守護兒童網上課程。課程共有五個單元，讓教練了解在體育中「守護兒童」的意識，為本地兒童及青少年建立一個正面及安全的運動及成長環境。',
    step3_2: '已經完成相關網上課程的教練，檔案上將會獲得奬章以作區別。你亦可以在教練檔案中查看他的學習進度。完成相關課程的教練，表示已經了解及掌握該單元的相關基本知識。',
    step4_1: '落實教練前，建議先與教練進行會談，讓教練進一步了解課程的目的和學員對象、調整期望、突發事件的應變和處理等： ',
    step4_2: '教練是否對教學充滿熱誠？',
    step4_3: '教練是否對教學充滿熱誠？',
    step4_4: '教練遇到突發情況或意外時，能否作出適當處理？',
    step4_5: `教練使用準確的用詞去教授運動技巧和傳達體育精神予學生是十分重要，因此教練跟學員之間有效溝通是不可或缺的。機構事前了解教練的教學風格，更能貼近學員的特性和需要。你亦可以在上課前，要求教練提供教案，以供參考。`,
    step4_7: '查詢系統：',
    step4_8: '致電至自動電話查詢系統：3660 7499',
    step4_9: '如何顯示結果：',
    step4_10: '自動電話查詢系統會向僱主披露申請人「有」或「沒有」性罪行定罪紀錄 (不會透露任何定罪紀錄詳情)',
    step4_11: '立即學習更多有關「守護兒童」的知識',
    step5_1:
      '活動開始以後，機構應定期觀察教練的教學情況，例如帶領學員進行怎樣的訓練、指示及使用的語言是否合適。而從教練提供的教案中，比對課堂是否按進度進行。如遇到進度落後或教案變更時，你可以主動向向教練了解。你亦可以與個別學員了解，以得悉學員上課的感受和意見。同時亦建議於長期體育訓練班設中期評估，及於全期訓練結束後設活動成效評估等，知悉活動效益。',
    step5_2: '',
    link_1: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.archery.org.hk/'>香 港 射 箭 總 會</a>`,
    link_2: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkaaa.com/hkaaa/eindex.html'>香 港 業 餘 田 徑 總 會</a>`,
    link_3: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkbadmintonassn.org.hk/en'>香 港 羽 毛 球 總 會 有 限 公 司</a>`,
    link_4: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkbaseball.org/'>香 港 棒 球 總 會 有 限 公 司</a>`,
    link_5: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkbba.org/'>香 港 籃 球 總 會</a>`,
    link_6: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkbilliardsports.org.hk/en/index.php?'>中 國 香 港 健 美 總 會</a>`,
    link_7: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkboxing.org.hk/en_index.php'>香 港 桌 球 總 會 有 限 公 司</a>`,
    link_8: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcucanoe.com.hk/'>香 港 拳 擊 總 會</a>`,
    link_9: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcmaa.com.hk/index_en.html'>香 港 獨 木 舟 總 會</a>`,
    link_10: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcmaa.com.hk/index_en.html'>香 港 中 國 國 術 龍 獅 總 會</a>`,
    link_11: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcricket.org/'>香 港 板 球 總 會</a>`,
    link_12: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.cycling.org.hk/?locale=en-US'>中 國 香 港 單 車 聯 會 有 限 公 司</a>`,
    link_13: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkdba.com.hk/'>中 國 香 港 龍 舟 總 會</a>`,
    link_14: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkfa.org.hk/EN/index.html'>香 港 劍 擊 總 會</a>`,
    link_15: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkfa.com/en/index.php?'>香 港 足 球 總 會 有 限 公 司</a>`,
    link_16: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkgateball.org.hk/E-Main/E-Main.htm'>中 國 香 港 門 球 總 會</a>`,
    link_17: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.gahk.org.hk/'>中 國 香 港 體 操 總 會</a>`,
    link_18: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.handball.org.hk/'>中 國 香 港 手 球 總 會</a>`,
    link_19: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hockey.org.hk/'>香 港 曲 棍 球 總 會</a>`,
    link_20: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkjudo.youdomain.com/eng/index.php'>中 國 香 港 柔 道 總 會</a>`,
    link_21: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkkaratedo.com.hk/en/'>中 國 香 港 空 手 道 總 會 有 限 公 司</a>`,
    link_22: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='https://www.hkkartclub.org/zh/'>香 港 小 型 賽 車 會 有 限 公 司</a>`,
    link_23: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.korfball.org.hk/new/intro.html'>中 國 香 港 合 球 總 會</a>`,
    link_24: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hklba.org/'>香 港 草 地 滾 球 總 會</a>`,
    link_25: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hklss.org.hk/public/modules_eng/index/index.asp'>香 港 拯 溺 總 會</a>`,
    link_26: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkmu.org.hk/'>中 國 香 港 攀 山 及 攀 登 總 會</a>`,
    link_27: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkmuaythai.org/'>香 港 泰 拳 理 事 會</a>`,
    link_28: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.netball.org.hk/'>香 港 投 球 總 會</a>`,
    link_29: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.oahk.org.hk/eframe.html'>香 港 定 向 總 會</a>`,
    link_30: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkparalympic.org/'>香 港 殘 疾 人 奧 委 會 暨 傷 殘 人 士 體 育 協 會 </a>`,
    link_31: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkparalympic.org/'>香 港 滾 軸 運 動 總 會</a>`,
    link_32: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://rollersports.org.hk/'>中 國 香 港 賽 艇 協 會</a>`,
    link_33: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.rowing.org.hk/rowing/index.jsp?l=en'>香 港 欖 球 總 會</a>`,
    link_34: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkrugby.com/eng/home.php'>香 港 帆 船 運 動 總 會</a>`,
    link_35: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.sailing.org.hk/'>香 港 射 擊 聯 合 總 會</a>`,
    link_36: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkshooting.org.hk/'>香 港 壘 球 總 會 </a>`,
    link_37: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://softball.org.hk/'>香 港 智 障 人 士 體 育 協 會</a>`,
    link_38: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hksam.org.hk/'>香 港 壁 球 總 會</a>`,
    link_39: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hksquash.org.hk/'>香 港 業 餘 游 泳 總 會 有 限 公 司</a>`,
    link_40: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkasa.org.hk/index.php?option=com_content&task=blogcategory&id=91&Itemid=203'>香 港 乒 乓 總 會</a>`,
    link_41: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktta.org.hk/'>香 港 乒 乓 總 會</a>`,
    link_42: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktkda.com/index.html'>香 港 跆 拳 道 協 會</a>`,
    link_43: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.tennishk.org/en/'>香 港 網 球 總 會</a>`,
    link_44: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktbc.org.hk/'>香 港 保 齡 球 總 會 有 限 公 司</a>`,
    link_45: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.triathlon.com.hk/'>香 港 三 項 鐵 人 總 會 </a>`,
    link_46: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkua.org.hk/'>香 港 潛 水 總 會</a>`,
    link_47: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.vbahk.org.hk/'>香 港 排 球 總 會</a>`,
    link_48: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.waterski.org.hk/english/e_index.htm'>香 港 滑 水 總 會</a>`,
    link_49: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.windsurfing.org.hk/en/home/index.php'>香 港 滑 浪 風 帆 會</a>`,
    link_50: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkwushuu.com.hk/'>香 港 武 術 聯 會</a>`,
    step: '步驟',
    step2_0: '機構可以如何查核結果？',
    step2_15: '申請人向授權查核的僱主提供查詢密碼及身份證號碼頭四位數字',
    step2_16: '',
    step2_17: '',
    step2_18: '',
    step2_19: '查詢系統：',
    step2_20: '致電至自動電話查詢系統：3660 7499',
    step2_21: '如何顯示結果：',
    step2_22: '自動電話查詢系統會向僱主披露申請人「有」或「沒有」性罪行定罪紀錄 (不會透露任何定罪紀錄詳情)',
    link2: `<a style='color:#16287a; text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/inspire'>「凝動體育教練培訓」網上課程</a>`,
    link3: `<a style='color:#16287a; text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/safeguarding'>「凝動守護兒童體育學習平台」網上課程</a>`,
    step6_1: '有關教練的專業道德及操守',
    step6_2: '教練應以身作則及保持專業道德及操守，使學員在參與過程得到正確的運動體驗，培養良好的體育精神。如發現教練有專業失當及及疏忽行為等，機構/家長可參考以下處理方法：',
    step6_3: '現行所有本地體育總會均有防止性騷擾政策，如涉及本地體育總會註冊教練，可以瀏覽本地體育總會網頁，向有關方面作出投訴。',
    last1: '嚴重程度',
    last2: '行為 (包括但不限於)',
    last3: '處理方法',
    last4: '低  ',
    last5: '遲到/早退、粗言穢語等',
    last6: '遲到/早退、粗言穢語等',
    last7: '中',
    last8: '利益衝突、教學錯誤等 ',
    last9: '應與教練釐清有關錯誤行為，並要求教練立即解決問題，在問題尚未解決前應先暫停課堂',
    last10: '高',
    last11: '騷擾、暴力行為等.',
    last12: '應立即終止餘下的課堂，如有需要應報警求助',
  },
  CoachProfiles: {
    All_sports: '全部運動',
    select_job: '請選擇邀請的活動',
    job_name: '活動名稱',
    cancel: '取消',
    tit: '搜尋教練',
    tit1: '',
    tit2: '教練檔案',
    btn1: '建立教練檔案',
    btn2: '更多',
    btn3: '收藏',
    btn4: '連繫教練',
    btn5: '取消收藏',
    mainsport: '運動主項:',
    position: '職位:',
    please: '請登錄或創建一個賬戶，再創建您的教練檔案。謝謝你！',
    please1: '請登錄或創建一個賬戶，再創建您的教學機會。謝謝你！',
    please3: '請登錄或創建一個賬戶。謝謝你！',
    welcome: '歡迎',
    confirm: '確認',
  },
  Jobs: {
    nodata: '無數據',
    apply: '申請',
    unapply: '取消申請',
    save: '收藏',
    unsave: '取消收藏',
    tit1: '',
    tit2: '尋找教學機會',
    tit3: '',
    tit4: '教學機會',
    btn1: '建立教學機會',
    search: '搜索',
    classSize: '班級規模',
    date: '日期',
    typeSprot: '運動類型',
    txtInfo1: '技能水平',
    txtInfo2: '授課語言',
    txtInfo3: '課堂次數',
    txtInfo4: '備註',
    txtInfo5: '價格',
    screeningTypes1: '展示所有',
    screeningTypes2: '我的教學機會',
    screeningTypes3: '我收藏的教學機會',
    screeningTypes4: '我申請的機會',
    selectTypes1: '最近',
    selectTypes2: '最後一次編輯',
    selectTypes3: '運動類型',
    paid: '收費教練',
    voluntary: '義務教練',
    information: '機構資料',
    placeholder1: '機構名稱（如適用）',
    placeholder2: '負責人姓名',
    placeholder3: '活動名稱',
    placeholder4: '活動性質',
    placeholder5: '運動項目',
    placeholder6: '無資料',
    placeholder7: '首堂上課日期',
    placeholder8: '結束上課日期',
    placeholder9: '課堂次數',
    placeholder10: '課堂開始時間',
    placeholder11: '課堂結束時間',
    placeholder12: '請選擇一個時間範圍',
    placeholder13: '課堂節數',
    placeholder14: '課堂地區',
    placeholder15: '授課對象',
    placeholder16: '課堂人數',
    placeholder17: '學生相關運動經驗',
    placeholder18: '授課語言',
    placeholder19: '備註',
    placeholder20: '教練費用',
    placeholder21: '需具備教授以下學生的經驗',
    placeholder22: '義工時間',
    placeholder23: '活動簡介 ',
    coachrequest: '教練要求',
    upload: '上傳背景圖片',
    classFrequency1: '每週一',
    classFrequency2: '每週二',
    classFrequency3: '每週三',
    classFrequency4: '每週四',
    classFrequency5: '每週五',
    classFrequency6: '每週六',
    classFrequency7: '每週日',
    classFrequency8: '每日一次',
    classFrequency9: '每週一次',
    classFrequency10: '兩星期一次',
    classFrequency11: '每月一次',
    classFrequency12: '待定',
    eventsTypes1: '恆常訓練',
    eventsTypes2: '單次活動',
    skillLevel1: '沒有經驗',
    skillLevel2: '初級',
    skillLevel3: '中級',
    skillLevel4: '進階',
    skillLevel5: '專業水平',
    mediumOfInstruction1: '廣東話',
    mediumOfInstruction2: '普通話',
    mediumOfInstruction3: '英語',
    coachFee1: '可商議',
    coachingExperiences1: '肢體傷殘',
    coachingExperiences2: '視覺障礙',
    coachingExperiences3: '聽覺障礙',
    coachingExperiences4: '專注力不足及過度活躍症',
    coachingExperiences5: '自閉症譜系障礙',
    coachingExperiences6: '特殊讀寫困難',
    coachingExperiences7: '語言障礙',
    coachingExperiences8: '精神疾病',
    coachingExperiences9: '長期病患',
    post_date: '發佈日期',
    last_edited: '最後修改日期',
    phone: '電話',
    email: '電郵',
    dialog1: '你正遞交【活動名稱】申請，一經確認遞交，活動發起人將會收到你的聯絡方式。請勾選相關聯絡方法：',
    dialog2: '您已經成功遞交【活動名稱】申請，活動發起人收到申請後將有機會與你聯繫。',
    dialog3: '讓活動發起人了解更多關於你：',
    confirm: '確認',
    submit: '提交',
  },
  Skills: {
    Personality: '性格特質',
    Motivate: '激勵團隊',
    Passionate_about_sports: '對運動富有熱誠',
    Casual: '隨性',
    Creative_Thinking: '創新思維',
    Passionate_about_teaching: '熱心教學',
    Flexibility: '具靈活性',
    Confidence: '有信心',
    With_a_clearly: '目標明確',
    Goal_oriented: '目標導向',
    Knowledgeable: '知識淵博',
    Careful: '細心',
    Respectful: '尊重學生想法',
    Patient: '有耐性',
    Chatty: '健談',
    Approachable: '平易近人',
    Fair: '公平',
    Logical: '有邏輯',
    Supportive: '擅用鼓勵及支持的說話',
    Persuasive: '有說服力',
    Calm: '冷靜',
    Curious: '好奇',
    Principled: '有原則',
    Systemic: '處事具系統性',
    Adventurous: '大膽',
    Positive: '積極',
    Optimistic: '樂觀正面',
    Leadership: '具領導能力',
    Inspiring: '具啟發性',
    Professional: '專業運動管理知識',
    Good_listener: '良好的聆聽者',
    Reliable: '可靠',
    Attention_to_detail: '著重細節',
    Self_discipline: '自律',
    Good_communication_skills: '良好溝通能力',
    Harmonious: '和諧',
    Friendly: '友善',
    Perseverance: '毅力',
    Clear_instructions_and_expressions: '指示及表達清晰',
    Accept_people_with_different_abilities: '接納不同能力人士',
    Rapid_response: '反應迅速',
    Kind: '和藹',
    Tolerant: '寬容',
    Strict_image: '形象嚴厲',
  },
  CreateProfiles: {
    p1: '教練可提供以下文件供機構/合作單位查核',
    p2: '學歷證明',
    p3: '教練證書',
    p4: '急救證書',
    p5: '性罪行定罪紀錄查核 ',
    p6: '了解更多【如何核實教練】',
    date_start: '開始年份:',
    date_end: '結束年份:',
    clickupload: '點擊上傳',
    tit: '教練簡介',
    bgBtn: '修改圖片',
    infoTit1: '供機構驗證及查核',
    infoTit2: '簡介',
    infoTit3: '教練收費',
    public: '公開',
    unpublic: '可商議',
    Perhour: '每小時',
    infoTit4: '學歷及專業資格',
    infoTit5: '凝動教練培訓學院',
    infoTit6: '成功連繫紀錄',
    infoTit7: '教學經驗',
    infoTit8: '了解更多',
    infoTit9: '我的技能',
    btn1: '性罪行定罪紀錄查核',
    btn2: '教練證書',
    btn3: '收藏',
    btn4: '連繫教練',
    star: '評分',
    name: '姓名',
    portrait: '頭像',
    position: '職位',
    mainsport: '運動主項:',
    coachtarget: '授課對象:',
    inspires: '凝動體育教練培訓!',
    learning: '學習進度',
    inspires_subtext1: '顯示已完成凝動體育教練培訓網上學習單元的學習進度。',
    inspires_subtext2: '完成愈多單元，表示教練已具備一定有關體育促進兒童及青少年發展的相關知識。',
    sharing: ' 網上專家分享',
    sharing_subtext1: '顯示出席網上專家講座的次數。',
    sharing_subtext2: '參與講座愈多，表示教練持續學習與運動相關的議題，了解和認識業界與服務對象的需要。',
    safe: '凝動守護兒童體育學習平台',
    safe_subtext1: '顯示已完成守護兒童體育學習平台的學習進度。 ',
    safe_subtext2: '完成愈多單元，表示教練已具備一定有關體育促進兒童及青少年發展的相關知識。',
    edit: '編輯',
    editinfo: '修改信息',
    remove: '移除',
    add: '新增',
    cancel: '取消',
    save: '儲存',
    upload: '上傳',
    pleaseselect: '請選擇',
    filetype: '文件類型',
    filepath: '文件連結',
    download_here: '點此處下載',
    filelink: '文件鏈接',
    jump: '跳轉',
    download: '下載',
    cover: '圖片',
    path: '連結',
    link: '網站/影片連結',
    title: '標題',
    file: '文件',
    image: '圖片',
    coachingTarget1: '小學',
    coachingTarget2: '中學',
    coachingTarget3: '親子',
    coachingTarget4: '長者',
    coachingTarget5: '其他',
    forCoachingFee1: '高於市場價格',
    forCoachingFee2: '平均市場價格',
    forCoachingFee3: '低於市場價格',
    forCoachingFee4: '保密',
    forCoachingFee5: '志願服務',
    placeholder1: '頒發機構/學校',
    placeholder2: '課程/資歷架構名稱',
    placeholder3: '年份',
    placeholder33: '結束年份',
    placeholder4: '說明',
    placeholder5: '合作機構/學校/公司名稱',
    placeholder6: '時間',
    placeholder7: '地點',
    placeholder8: '職位名稱',
    placeholder9: '參與比賽及奬項',
    placeholder10: '比賽名稱',
    placeholder11: '奬項/名次',
    treaty_tit: '你即將建立教練檔案，請確認你已閱讀以下條款:',
    treaty_ihk: 'InspiringHK Sports Foundation Company Limited (the “Foundation”)',
    paragraph1:
      '1. General \n The Foundation is committed to protecting the personal data provided by you and it is the Foundation’s policy to ensure its policies in relation to the collection, use, transfer, retention and access of personal data comply with the requirements of the Personal Data (Privacy) Ordinance, Cap.486 .',
    paragraph2:
      '2. Types of Personal Data Collected \nFor the purpose of provision of charity services by the Foundation, you may be requested to provide personal data including but not limited to the name, contact details(including but not limited to telephone number, fax number, email and / or correspondence address) and copy of any document which may contain your personal data.Provision of personal data is on a voluntary basis.',
    paragraph3:
      '3. Use of Personal Data \n The personal data provided by you may be used by the Foundationfor the purpose of: \n a) provision of charity services; \n b) external communication of the charity services provided by the Foundation(including charity programs, fund raising activities and volunteer services etc.) \n c) distribution of the Foundation’ s newsletters; \n and d) compliance with the applicable laws, rules and regulations applicable to the Foundation. \n',
    paragraph4:
      '4. Use of Personal Data for External Communication \n The Foundation intends to use the name and contact details(including telephone number, fax number, email and / or correspondence address) of you or external communication of the charity services(including charity programs, fund raising activities and volunteer services etc.), provided by the Foundation.The personal data provided by you will not be disclosed by us to any other party without your prior consent.',
    paragraph5:
      "5. Request to Cease the Use of Personal Data \n You have the right, at any time without charge, to request the Foundation to cease to use your personal data for external communication.You may write to info @inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay for making such a request.",
    paragraph6:
      '6. Security and Retention of Personal Data \n The Foundation has implemented internal control measures to ensure the security of personal data and to prevent unauthorized or accidental access, process, erasure, loss or use of personal data.',
    paragraph7:
      "7. Access to and Correction of Personal Data \n You have the right to access and/or correct your personal data. You may at any time write to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay. Please note that the Foundation has the right to charge a reasonable fee for the processing of any data access request.",
    paragraph8:
      "8. Enquiries \n Enquiries concerning your personal data provided, including making access and corrections, shall be addressed in writing to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay.",
    treaty1: '本人已閱讀並了了解上述條款',
    treaty2: '本人聲明於凝動教練培訓平台中提供的各項資料正確無誤、完備和真實',
    treaty3: '本人已知悉建立教練個人檔案後，連結教學機會時將會分享你的聯絡電郵及電話，以便機構與你進行聯絡及商討',
    message:
      '成功提交！\n 凝動教練培訓學院正在審核您的教練檔案，敬請耐心等候。 審核需時不多於3個工作天，成功審閱後，將會於網上平台發佈。\n 你可於「尋找教學機會」中查看最新的工作機會，並提出申請。 該機構/聯絡人收到申請後，將與你聯繫。\n 如有任何疑問，請電郵至academy@inspiringhk.org查詢。',
    message2:
      '成功提交！\n 凝動教練培訓學院正在審核您的教學機會，敬請耐心等候。 審核需時不多於3個工作天，成功審閱後，將會於網上平台發佈。\n 如有教練有意申請教學機會，你將直接能在「會員專區」中收到相關訊息，並取得他們的聯絡資料。 你亦可於「搜尋教練」中尋找合適教練，邀請他們申請你的教學機會。\n 如有任何疑問，請電郵至academy@inspiringhk.org查詢。',
    terms: '本人/機構明白「教練網絡」為第三方平台教練配對平台。「凝動教練培訓學院」會員來自本港運動教練、體育老師、青年工作者等，故使用「凝動教練培訓學院」尋找教學機會及物色教練時，必須自行篩選。',
    terms2:
      '本人/機構明白免責聲內之條款並同意，在使用「教練網絡」服務時獲取任何內容、資料及通訊往來，或因此而進行/參與之活動、聚會或義務工作，是基於個人/團體決定，屬用戶之間的接觸，凝動已盡力確保「凝動教練培訓學院」的資料準確無誤，但不會就任何錯誤、遺漏、或錯誤陳述或失實陳述（不論明示或隱含的）承擔任何責任。',
    verify_terms: `點擊<a style='color:#16287a;text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/coach-connect/how-verify'>這裡</a>了解更多關於如何驗證教練的信息`,
    pre_made_banner: '或者您可以使用其中一張圖片',
  },
  forget_password: '忘記密碼',
  Home: {
    email: '電郵',
    code: '驗證碼',
    password: '密碼',
    Register: '立即登記',
    subscription: '訂閱',
    nav0: '首頁',
    nav1: '關於我們 ',
    nav2: '啟發篇 ',
    nav3: '守護篇',
    // nav4: '凝聚及行動篇',
    nav4: '專區',
    nav5: '教練網絡',
    nav6: '教練行動',
    nav7: '合作夥伴',
    nav8: '會員專區',
    nav2_1: '凝動體育教練培訓',
    nav9: '「啟動」培訓 - 網上學習單元',
    nav10: '凝動「培」你教 - 體育活動示範影片庫',
    nav11: '體育活動影片庫',
    banner_tit1: ' ',
    banner_tit2: '凝動教練培訓學院',
    more: '了解更多',
    more1_detail: '教練培訓課程及影片庫',
    more2_detail: '守護兒童課程',
    more3_detail: '物色教練及尋找教學機會',
    more4_detail: '專家分享及實踐工具',
    download_short: '下載計劃海報',
    download: '下載「賽馬會社區持續抗逆基金 — 凝動體育教練培訓」計劃海報',
    txt1: '《凝動教練培訓學院》是全港首個為體育教育者而設的網上學習及聯繫平台，提供一站式網上學習資源及專家講座，旨在增值個人對體育促進兒童及青少年發展以及保護兒童的相關專業知識，並同時連結教練獲得更多收費及義務教學機會，以體育造就更多青年。',
    txt2: '啟發篇：賽馬會社區持續抗逆基金 — 凝動體育教練培訓',
    txt3: '平台率先推出賽馬會社區持續抗逆基金 — 凝動體育教練培訓，由香港賽馬會慈善信託基金捐助，為《凝動教練培訓學院》計劃的首個部分。內容包括提供七個網上學習單元及過百條體育活動示範影片，既可讓體育教育者增值有關體育促進兒童及青少年發展的相關知識，亦可激發他們的創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    txt4: '<strong>2、第二部分<br>「守護篇：守護兒童學習平台」</strong><br>由兒童事務委員會資助，希望教導並提高不同人士對保護兒童的認識。這部分將於2021年底推出，敬請期待',
    txt5: '<strong>3、最後第三部分<br>「凝聚及行動篇：凝聚教練及教練起動」</strong><br>將體育教練和服務使用者配對，並提供教練在實踐中所需的工具，以提昇本地體育業界的質素。第三部分將在2022年推出，也請密切留意凝動的社交媒體，緊貼最新消息。',
    title1: '一個在體育促進發展中作為啟發、保護、凝聚及行動的線上平台',
    nav_img1_tit: '啟發篇',
    null: '',
    nav_img1_txt1: '啟發篇',
    nav_img1_txt2: '「啟動」培訓' + '\n' + '互動式網上學習課程',
    nav_img1_txt3: '課程內容包括有關體育促進發展和面對不同兒童及青少年群體教學的相關知識及技巧',
    nav_img1_txt4: '按照各自的學習進度完成課程，完成後將獲頒發證書',
    nav_img1_txt5: '凝動「培」你教' + '\n' + '體育活動優秀示例影片庫',
    nav_img1_txt6: '大量體育活動示範影片，按青少年及兒童年齡、能力和教學目標等標籤搜尋適合你的體育活動',
    nav_img2_tit: '守護篇',
    //nav_img2_txt: '',
    nav_img2_txt1: '守護兒童學習平台',
    nav_img2_txt2: '互動式網上學習課程',
    nav_img2_txt3: '課程內容包括有關體育環境下守護兒童的策略、守則、風險評估、報告的知識',
    nav_img2_txt4: '同樣地，使用者按照各自的學習進度完成課程，完成後亦將獲頒發證書',
    nav_img3_tit: '教練網絡',
    nav_img3_txt1: '教練網絡',
    nav_img3_txt2: '我想加入「教練網絡」！',
    nav_img3_txt3: '支援本地體育教練提昇其競爭力，供他們展示個人履歷，並為他們配對潛在授課機會',
    nav_img3_txt4: '',
    nav_img3_txt5: '我想透過「教練網絡」物色教練！',
    nav_img3_txt6: '供服務使用者尋找體育教練，教導大眾認識何謂合資格和合適的教練，有助教練在運動中注入正面價值觀',

    nav_img4_tit: ' 教練行動',
    //nav_img4_txt: '',
    nav_img4_txt1: '教練起動',
    nav_img4_txt2: '社會回饋',
    nav_img4_txt3: '為基層兒童及青少年提供至少三小時的免費體育訓練，將體育促進發展的精神惠及社會',
    nav_img4_txt4: '專家分享',
    nav_img4_txt5: '舉辦不同主題的專家分享會，以互動討論的形式，深入探討如何透過體育運動推動兒童及青少年的發展',
    nav_img4_txt6: '實踐工具',
    nav_img4_txt7: '提供線上實用教學資源如年度教案、課堂規劃表、訂立目標工作紙和教學指引卡等',
    join_txt: '一起推動學習的文化，裝備通過體育為兒童及青少年創造良好影響！',
    join_tit: '馬上登記 加入我們',
    login: '登入',
    exit: '取消',
    register: '登記',
    lang: 'ENGLISH',
    change_password: '修改密碼',
    confirm: '確認',
    old_password: '舊密碼',
    new_password: '新密碼',
    confirm_password: '確認密碼',
    account_not_yet: '還沒有帳號？',
    sign_in_now: '立即登記啦！',
    coming_soon: '即將推出',
    jockey_club_url: 'https://www.charities.hkjc.com',
    login_success: '登入成功',
    login_fail: '登入失敗',
    corner_1: `<a style='color:white;' target='_blank' href='/#/coach-connect/coach-corner'>教練專區</a`,
    corner_2: `<a style='color:white;' target='_blank' href='/#/coach-connect/school-corner'>學校、社福及工商業界專區</a>`,
    corner_3: `<a style='color:white;' target='_blank' href='#/coach-connect/parents-corner'>家長專區</a>`,
  },
  CoachAction: {
    title: '教練行動',
    pic1: '網上專家分享',
    pic2: '教學資源',
    about: '關於教練行動',
    soon: '即將推出',
  },
  Tools: {
    title: '教學資源',
    tit1: '體育活動水平評估標準及指引',
    tit2: '活動帶領指引',
    tit3: '教案編排',
    tit4: '檢討教學指南',
    tit5: '預防運動受傷及處理',
    tit6: '運動機能貼布應用',
    tit7: '凝動『培』你教－體育活動影片庫',
    tit8: '網上專家分享',
    desc: '',
    desc2: '',
    warningtitle: '歡迎',
    warningtext: '請先登錄或創建一個賬戶，謝謝你！',
    cuhk_paragraph: '凝動教練培訓學院與香港中文大學體育運動科學系專業團隊合作，設計一系列教學資源予教練或與體育活動相關的人士於運動訓練時使用。 ',
  },
  Sharing: {
    partner: '夥伴',
    title1: '',
    title2: '網上專家分享',
    title3: '網上專家分享',
    btn1: '立即報名',
    btn2: '了解更多關於講者',
    online_share_title1: '運動按摩知多少',
    online_share_date1: '日期：2022年7月20日 <br>時間：7:00-7:30pm',
    online_share_description1: '運動隔天總是感到僵硬、疼痛是因為缺少適合的緩解方法。</br>學習正確的運動按摩方法有助提升增加學生運動表現、減輕運動疲勞及預防運動傷害。',
    online_share_title2: '兒童及青少年有關運動的常見骨科問題',
    online_share_speaker2: '骨科專科醫生 尹希文醫生',
    online_share_description2:
      '運動有很多好處，但若果缺乏運動的正確知識、忽視安全措施，便很容易受傷。骨科問題是青少年在成長時期最常面對的傷患，了解運動對於成長中的兒童的骨骼的影響，從而減低學員運動受傷的風險。',
    online_share_speakerdescription2:
      '香港骨科醫學院院士、香港醫學專科學院院士（骨科）、英國愛丁堡皇家外科學院骨科院士、英國皇家外科學院院士' +
      '<br> 尹希文醫生畢業於香港中文大學醫學院，現為廣華醫院骨科副顧問醫生，香港中文大學矯形外科及創傷學系榮譽臨床助理教授。主要負責運動醫學、成人關節置換，及骨折創傷等臨床服務及研究。尹醫生同時擔任香港骨科醫學院公共資訊委員會委員，香港運動醫學及科學學會醫生委員會委員，香港足球總會醫藥委員會委員。' +
      '<br>尹醫生亦是運動愛好者，參與運動包括足球、籃球、欖球、草地滾球及龍舟，亦是香港足球隊的忠實支持者！',
    online_share_title3: '實戰篇：運動貼布的應用',
    online_share_date3: '日期：2022年7月27日 <br>時間：7:00-7:30pm',
    online_share_description3:
      '運動貼布是近年在體育界很流行的一種貼紮法，用於預防運動創傷、改善人體力學、促進創傷康復等各方面。簡單掌握運動貼布的使用技巧和應用原則，在運動訓練中亦可以來防治傷患、保護身體並促進表現。',
    online_share_title4: '運動營養及訓練飲食',
    online_share_speaker4: '顧問營養師 林思為',
    online_share_date4: '日期：2022年7月27日 <br> 時間：7:30-8:30pm',
    online_share_description4:
      '青少年運動員需特別注意飲食。他們在營養方面需要有足夠的能量與營養素來滿足活動的需要和促進成長。配合運動訓練和飲食營養，青少年可以建立良好體能和健康骨骼，提升技術並爭取更好成績。',
    online_share_speakerdescription4:
      '澳洲註冊營養師丶香港認可營養師學院認可營養師丶香港營養師協會正式會員丶澳洲悉尼大學營養治療學碩士丶加拿大英屬哥倫比亞大學營養學學士丶香港中文大學糖尿病治療及教育專業文憑' +
      '<br> 2000 年畢業於澳洲悉尼大學營養治療碩士課程，現為香港私人執業的高級註冊營養師，有接近二十年為有體重問題，心臟病、糖尿病患者、飲食失調及有相關疾病人士提供營養飲食評估及個人飲食治療的經驗。她現任香港認可營養師學院專業委員會主席，在 2007-2019 年其間曾擔任香港營養師協會會長，2019至2021年擔任協會對外事務主任，全力推動香港註冊營養師專業服務。現為國際營養學聯合會（ICD）香港的地區代表及香港運動醫學與運動科學學會理事會成員。' +
      '<br>林小姐曾為各大機構包括政府機構、非牟利機構、銀行、商業機構、製藥公司、電信公司和專上學院及大學等作營養學的講者。她更常為各大報章、電視、電台和網上解答及推廣營養資訊。她曾編著十本以上營養書藉，最新著作有「運動 x 營養 - 講事又講非」、「金牌營養師抗膽固醇營養師私房菜」和「金牌營養師糖尿病營養師甜美食 譜」。其他包括「癌症患者飲食手記」、「營養師瘦身私房菜」及「營養師素食私房菜」「幸福媽咪懷 孕食譜」等等。',
  },
  AboutUs: {
    title: '關於我們',
    organizer:
      '凝動香港體育基金是根據《稅務條例》第88條獲豁免繳稅的慈善團體，於 2012 年成立，一直秉承「以體育造就青年」的願景，致力透過體育提升基層青年的社會流動力，並同時宣揚性別平等、社會共融和健康生活。本會通過長期培訓、中期項目和短期健康生活活動三大範疇來履行使命。於2019-2020年，凝動為超過2,500位學員提供不少於46,000小時的專業體育培訓，另有超過4,000位青少年及兒童受惠於短期健康生活活動。',
    about_organizer: '關於主辦機構',
    about_academy: '關於凝動教練培訓學院',
    paln_obj_title: '計劃對象',
    plan_tit1: '我是教練',
    plan_tit2: '我是社福機構、學校及 工商業界代表',
    plan_tit3: '我是家長    ',
    // plan_tit4: '青年工作者',
    // plan_tit5: '家長',
    plan_txt1: '不論是全職或兼職、專業或業餘，任何運動項目的教練均歡迎參加',
    plan_txt2: '學校或非政府組織員工、所有小學、中學和特殊學校體育老師，以及經常與兒童及青少年接觸的人士，可使用本學院資源學習如何以運動促進兒童及青少年發展',
    plan_txt3: '本學院適合所有家長參加，有助家長利用運動教授及協助子女成長',
    // plan_txt4:
    //   '經常與兒童及青少年接觸的人士，包括社會和教育發展方面的青年工作者均歡迎加入本學院',
    // plan_txt5: '本學院適合所有家長參加，有助家長利用運動教授子女成長',
    plan_con: '《凝動教練培訓學院》平台的對象為過千名本地全職及兼職體育教練和體育老師，以及致力於以體育協助兒童及青少年發展的社工、青年工作者及家長。',
    project_title: '項目成效',
    project_effect_tit1: '成效1',
    project_effect_txt1: '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit2: '成效2',
    project_effect_txt2: '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit3: '成效3',
    project_effect_txt3: '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit4: '成效4',
    project_effect_txt4: '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    stage1_title: '凝動體育教練培訓',
    stage1_txt1: '賽馬會社區持續抗逆基金 - 凝動體育教練培訓',
    stage1_txt2:
      '此階段提供七個網上學習單元及過百條體育活動示範影片，既可讓體育教育者增值有關體育促進兒童及青少年發展的相關知識，亦可激發他們的創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    stage2_title: '守護兒童學習平台',
    stage2_txt1: '守護兒童學習平台',
    stage2_txt2: '此階段由五個網上學習單元組成，內容主要講述如何在運動層面上守護兒童，亦會聚焦風險管理、慣常做法和面對虐兒事件的對應方法。',
    stage3_title: '教練網絡和教練起動',
    stage3_txt1: '教練網絡和教練起動',
    stage3_txt2: '此階段是網上教練配對平台，教練會在平台上展示個人履歷。平台使用者可以透過此平台尋找合適的教練教授運動項目。教練亦可透過平台尋找潛在學員，下載教學資源以及聽取專家意見。',
    results1_tit: '凝動體育教練培訓',
    results1_txt1: '免費修讀七個網上學習單元',
    results1_txt2: '免費瀏覽體育活動示範影片庫，庫內有大量簡短影片',
    results1_txt3: '了解以體育造就青年的概念、運動價值觀、品格和道德觀念等',
    results1_txt4: '提升與不同學生溝通和互動的技巧，當中包括特殊學習需要、女性、少數族裔等',
    results1_txt5: '提升計劃體育活動的能力',
    results2_tit: '守護兒童學習平台',
    results2_txt1: '免費修讀五個網上學習單元',
    results2_txt2: '了解有關虐待兒童和守護兒童的本地慣例以及相關概念',
    results2_txt3: '學習如何在運動層面為保護兒童作風險評估',
    results2_txt4: '明白如何妥善應對虐待兒童行為',
    results2_txt5: '',
    results3_tit: '教練網絡和教練起動',
    results3_txt1: '免費瀏覽網上配對平台',
    results3_txt2: '運動教練可發佈個人履歷，展示其經驗和專業資格等資訊，並獲得更多教學機會',
    results3_txt3: '體育老師、家長、非政府組織和青年工作者可尋找合適教練',
    results3_txt4: '一站式網上教學資源及專家講講座',
    results3_txt5: '提升教練市場兢能力',
    results3_txt6: '分享如何核實教練的實用資訊',
    results3_txt7: '提供安全又可靠的本地教練庫',

    para_1: '凝動教練培訓學院希望聆聽各位用家的意見，不管是關於我們的服務、你的體驗或任何其他問題，歡迎於辦公時間內致電或以電郵聯絡我們。    ',
    para_2: '電話：560 560 13    ',
    para_3: '電郵：',
    para_4: 'academy@inspiringhk.org',
  },
  Inspire: {
    title: '啟發篇',
    title_tip: '賽馬會社區持續抗逆基金 - <br>凝動體育教練培訓',
    content_one: '「賽馬會社區持續抗逆基金 - 凝動體育教練培訓」',
    content_two:
      '由凝動香港體育基金主辦，香港賽馬會慈善信託基金捐助，為《凝動教練培訓學院》計劃的首個部分。平台內容包括提供七個網上學習單元及過百條體育活動示範影片，既可讓體育教育者增值有關體育促進兒童及青少年發展的相關知識，亦可激發他們的創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    // click_look: '點擊下方 立即觀看相關內容',
    txt1: '「啟動」培訓',
    txt2: '凝動「培」你教',
    more: '了解更多',
    video_title: '啟發篇：賽馬會社區持續抗逆基金 — 凝動體育教練培訓',
    download: '下載「賽馬會社區持續抗逆基金 — 凝動體育教練培訓」計劃海報',
    download_short: '下載計劃海報',
  },
  SportsFilms: {
    title: '啟發篇',
    tip1: '體育活動影片庫',
    tip2: '賽馬會社區持續抗逆基金 - 凝動體育教練培訓',
    content_one: '「凝動『培』你教－體育活動影片庫」',
    content_two: '是賽馬會社區持續抗逆基金 - 凝動體育教練培訓計劃的第二部分。影片庫現階段提供接近一有段體育活動示範短片，用戶可按年齡、能力和教學目標等標籤以「進階搜索」搜尋適合教學對象的體育活動。',
    content_three: '影片由各運動界別的專業教練講解，以確保影片質素以及活動的安全性。短片下方亦提供文字版本，清晰列明玩法、活動總結以及建議反思問題，好讓使用者方便重溫短片內容。',
    sports_title: '體育活動影片庫',
    input_pla: '尋找適合你的體育活動',
    search_btn: '搜尋',
    search_stage: '進階搜尋',
    // tit1: "影片題目",
    browse_one: '觀看次數：',
    browse_two: '次',
    pop_title1: '最多人搜尋的影片',
    pop_title2: '評分最高的影片',
    look_more: '瀏覽全部',
    search_all: '所有選項',
    search_title: '搜尋結果',
    search_tit1: '運動項目：',
    search_tit2: '年齡：',
    search_tit3: '活動目標：',
    search_tit4: '價值觀：',
    search_tit5: '活躍程度：',
    search_tit6: '能力：',
    search_txt11: '傳統球類運動',
    search_txt12: '格鬥類運動',
    search_txt13: '基本動作技能',
    search_txt14: '田徑',
    search_txt15: '新興球類運動',
    search_txt16: '體能運動/伸展運動',
    search_txt17: '球拍類運動',
    search_txt21: '初小',
    search_txt22: '初中',
    search_txt23: ' ',
    search_txt24: ' ',
    search_txt25: '高小',
    search_txt26: '高中',
    search_txt27: ' ',
    search_txt28: ' ',
    search_txt31: '破冰',
    search_txt32: '熱身',
    search_txt33: '主題活動',
    search_txt34: '協調/靈敏',
    search_txt35: '整理/反思',
    search_txt41: '體育精神',
    search_txt42: '情緒技能',
    search_txt43: '智能發展',
    search_txt44: '團隊技能',
    search_txt45: '體魄健康',
    search_txt51: '低強度',
    search_txt52: '中強度',
    search_txt53: '高強度',
    search_txt61: '特殊學習需要',
    search_txt62: '身體殘障',
    search: '搜尋',
    search_error_tit: '提示',
    search_error: '未搜索到相關影片',
    search_success: '搜尋完成',
    no_login: '請先登入',
  },
  SportsFilmsDetail: {
    nav: '體育活動影片庫',
    seach: '#初中 #體育精神 #整理/反思 #特殊學習需要 #不放棄 #3分鐘',
    title: '名稱:',
    Activity_name: '捉不住的皮球',
    target: '目標:',
    target_value: '不斷嘗試捉回皮球接給主持人',
    play_title: '玩法:',
    play_content:
      '用雪糕筒畫出一個指定區域，選擇一位主持人站在中間;其他同學會站在不同方位輪流把球拋給主持人,主持人需要把球拋向其他方向，愈遠愈好，為自已爭取時間;讓拋球的同學跑去其他方位接回自已的球，重新再把球傳給主持人;當主持人未能應付多於一位同學拋回的球為輸 用雪糕筒畫出一個指定區域，選擇一位主持人站在中間用雪糕筒畫出一個指定區域，選擇一位主持人站在中間',
    Activity_summary: '活動總結:',
    summary: '明白體育精神，即使疲倦也不放棄，重複行為訓練進度活躍同學耐性、滿足自閉症的需求',
    advise: '建議反思問題:',
    tip: '回想活動期間，你有何感覺；過程中遇到甚麼困難？你以怎樣的態度應對',
  },
  OnlineCourses: {
    title: '啟發篇',
    tip1: '網上學習課程',
    tip2: '賽馬會社區持續抗逆基金 - 凝動體育教練培訓',
    content_one: '《凝動體育教練培訓》',
    content_two:
      '是一個由香港賽馬會慈善信託基金捐助的網上學習平台，現階段有共七個與「體育促進發展」相關的單元，每個單元內含有短片、互動學習資源、個案研究和小測驗等互動內容。教練及其他人士在修畢七個網上學習課程，並通過所有小測驗後，將獲發課程完成證書（10小時學習時數）。',
    tip3: '體育促進發展 : 啟發篇',
    tip4: '立即登記以解鎖更多課程!',
    img_item1: '單元一',
    img_item1_list: '認識兒童及青少年',
    img_item1_txt1: '',
    img_item1_txt2: '課時1小時',
    img_item2: '單元二',
    img_item2_list: '認識體育促進兒童及青少年發展',
    img_item3: '單元三',
    img_item3_list: '運動的價值觀、道德與倫理',
    img_item4: '單元四',
    img_item4_list: '女性在運動參與上遇到的挑戰及如何處理',
    // img_item4_list: '鼓勵女性参與更多運動',
    img_item5: '單元五',
    img_item5_list: '鼓勵有特殊學習需要的兒童及青少年參與運動',
    img_item6: '單元六',
    img_item6_list: '鼓勵少數族裔的兒童及青少年參與運動',
    img_item7: '單元七',
    img_item7_list: '運動與心理健康',
    coming_soon: '即將推出 密切期待',
  },
  Protection: {
    title: '守護篇',
    tip1: '網上學習課程',
    tip2: '凝動守護兒童體育學習平台',
    content_one: '《凝動體育教練培訓》',
    content_two:
      '「凝動守護兒童體育學習平台」是香港首個<b>與體育相關</b>守護兒童網上課程，由兒童福祉及發展資助計劃 - 兒童事務委員會贊助，為《凝動教練培訓學院》計劃的第二部分。計劃增強本港運動教練、體育教育者及家長對在體育中「守護兒童」的意識，為本地兒童及青少年建立一個正面及安全的運動及成長環境。<br/><br/>「凝動守護兒童體育學習平台」現共有五個單元。每個單元內含有短片、互動學習資源、個案研究和小測驗等互動內容。教練及其他人士在修畢五個網上學習課程，並通過所有小測驗後，將獲發課程完成證書（5小時學習時數）。<br/><br/>本課程很榮幸邀請到國際培幼會作為課程顧問。',
    tip3: '體育促進發展 : 守護篇',
    tip4: '立即登記以解鎖更多課程!',
    tip5: '「凝動守護兒童體育學習平台」啟動禮暨研討會',
    img_item1: '單元一',
    img_item1_list: '守護兒童的基本概念',
    img_item1_txt1: '',
    img_item1_txt2: '課時1小時',
    img_item2: '單元二',
    img_item2_list: '虐待兒童的基本概念、成因及影響',
    img_item3: '單元三',
    img_item3_list: '在體育中守護兒童',
    img_item4: '單元四',
    img_item4_list: '識別、回應及通報虐兒個案',
    img_item5: '單元五',
    img_item5_list: '守護兒童體制',
    coming_soon: '即將推出 密切期待',
    seminar_text:
      '<br/>活動已於2021年12月16日（星期四）完滿結束，感謝各位參與！<br/><br/>活動當日以 <b style="color:#f15922;">「各界如何協力於體育活動中守護兒童」</b> 為主題，並邀請到對守護兒童有豐富經驗及學識的學者進行研討會及「分組專題環節」，分享跨界別的合作經驗，以及各教練同體育工作者如何以自身角色，在體育中實踐「守護兒童」。<br/><br/>當日更正式推出香港首個與體育相關守護兒童網上課程 - 「凝動守護兒童體育學習平台」 ，讓一眾參加者優先登記。<br/><br/>請<a style="color:#16287a;text-decoration:underline;" target="_blank" href="https://drive.google.com/drive/folders/1fTfkLzw1fesUTp7vZCBiymaZ-NQGM1Jh?usp=sharing">按此</a>瀏覽當日活動的精彩回顧！<br/><br/><br/>',
    login_title: '未登錄',
    login_text: '請登錄/注冊后獲取課程',
    login_ok: '確認',
  },
  Register: {
    title: '立即登記增值自己！',
    txt: '免費登記成為會員後，方可學習網上單元、瀏覧及下載教學資源、尋找教練和連繫工作機會。 ',
    information: '學員資料',
    name_cn: '中文姓名',
    name_en: '英文姓名',
    password: '密碼',
    repassword: '確認密碼',
    email_address: '電郵地址',
    age: '年齡',
    bir: '出生日期',
    sex: '性別',
    area_code: '電話區號',
    mobile: '聯絡電話',
    address: '住址',
    confirm_address:
      '* 於2021年7月31日（一）前成功登記成為平台會員，將獲贈港幣$50超市現金券。於到期日前成功登記並同時完成平台上的最少一個學習單元，更可獲贈額外港幣$50超市現金券。如欲領取現金券，請確保以實名登記，並提供正確的聯絡電話和通訊地址。現金券將於7月31日後個別聯絡用家發放，本會希望藉此為 貴機構的體育教育者提供誘因持續學習。',
    sex_m: '男',
    sex_w: '女',
    lastname_cn: '姓',
    firstname_cn: '名',
    lastname_en: 'Last Name',
    firstname_en: 'First Name',
    identity: '你的身份',
    identity_login: '我的身份',
    identity_coach: '運動教練',
    identity_teacher: '體育老師',
    identity_social: '社工',
    identity_yang: '青年工作者',
    identity_parents: '家長',
    identity_stu: '學生',
    identity_other: '其他:請註明',
    interested_content: '你對平台的哪項內容感興趣？',
    e_learning_unit: '網上學習單元',
    interested_safeguarding: '體育場上守護兒童相關知識（即將推出）',
    interested_coach_connection: '教練聯繫平台（即將推出）',
    sports_demonstration_film: '體育活動示範影片',
    education_level: '你的教育程度',
    primary: '無受過教育∕幼稚園∕小學程度',
    junior: '初中程度',
    high: '高中∕專上教育程度',
    other_education_level: '其他教育程度',
    main_clients: '你主要的教授/服務對象',
    kindergarten: '幼稚園／幼兒園學生',
    primary_student: '小學學生',
    secondary_student: '中學學生',
    university: '大學或其他院校的學生',
    adult: '成年人',
    elderly: '長者',
    not_applicable: '不適用',
    other_min_client: '其他服務对象',
    edu_primary: '小學及以下',
    edu_secondary: '中學',
    edu_higher_diploma: '高級文憑/副學士',
    edu_bachelor: '學士',
    edu_master: '碩士',
    edu_doctor: '博士',
    edu_other: '其他: (請註明)',
    way_website: '凝動香港體育基金 網站',
    way_facebook: '凝動香港體育基金 Facebook',
    way_instagram: '凝動香港體育基金 Instagram',
    way_newsletter: '凝動香港體育基金 通訊',
    way_staff: '凝動香港體育基金 職員介紹',
    way_whatsapp: 'WhatsApp訊息',
    way_friends: '朋友分享',
    way_poster: '實體海報',
    way_other: '其他途徑: （請註明）',
    question1: '你對《凝動教練培訓學院》有甚麼期望？',
    question2: '你從哪裡得知《凝動教練培訓學院》？（可多選）',
    checkbox_search: '搜索引擎',
    checkbox_friend: '朋友口中',
    checkbox_social: '社交媒體',
    checkbox_magazine: '網誌或報紙',
    checkbox_other: '其他: (請註明)',
    agree: '我已閱讀及同意接受「凝動教練培訓學院」的使用條款及細則和私隱政策。',
    agree_modele: '我已閱讀及同意接受<br>《體育造就青年 教練培訓學院》<br>的使用條款及細則和私隱政策。',
    subscribe: '我不希望收取有關「凝動教練培訓學院」的最新資訊',
    return: '返回',
    continue: '繼續',
    commit: '提交',
    sex_other: '不方便透露',
    other: '其他',
    prompt: '請輸入內容',
    prompt_age: '請選擇年齡',
    prompt_data: '選擇日期',
    avatar: '設定／更換個人頭像',
    account_information: '賬戶名稱',
    Registration_email: '登記郵箱',
    my_identity: '我的身份',
    my_Accounts: '我的賬戶',
    birday: '出生日期',
    storage: '儲存',
    page: '頁：',
    error_name: '中文姓名請輸入中文，英文姓名請輸入英文',
    success: '成功登記',
    declare1: '本人明白、同意及謹此聲明︰',
    declare2:
      '一、本人提供之個人資料將用作參與活動的相關用途、簽發收據、收集意見、資料分析，及其他配合本會宗旨及使命的事項 。本人有權查閱及更改個人資料，包括通過書面的方式懇請貴機構停止在對外宣傳時使用本人個人資料的權力。本人於申請表內及隨附文件所載的一切資料，依本人所知均屬真確，並無遺漏。如需查閱或更改個人資料，可電郵至info@inspiringhk.org與本會職員聯絡。',
    declare3: '二、本人謹授權以上轉介人向本人索取更新資料及/或貴機構因推行服務而須取得之額外資料，並向貴機構提供該等資料。貴機構可按本表格所述，處理有關資料。',
  },
  MemberZone: {
    DeleteButton: '刪除',
    record: '我的上傳紀錄:',
    records: '我的上傳紀錄',
    upload_vol: '上載我的義務服務證明',
    not_started: '未開始',
    started: '開始',
    progressed: '進行中',
    passed: '已完成',
    stu_title: '我的學習進度',
    stu_done: '已完成的課程',
    studying: '修讀中的課程',
    award: '獲頒發的課程',
    stu_name: '課程名稱',
    achievement: '成績',
    stu_feedback: '課程反饋',
    upload_voluntary: '我的義務服務證明及紀錄',
    stu_record: '學習進度',
    saved: '收藏',
    my_coach: '我的教練檔案',
    my_created: '已建立/已申請的教學機會',
    inspiration: '「啟發篇」',
    safeguarding: '「守護篇」',
    please_provide: '請填寫義務服務詳情：',
    event_name: '活動名稱：',
    organisation: '主辦單位',
    contact_person: '聯絡人姓名：',
    organiser_conact: '聯絡人電話：',
    email: '聯絡人電郵：',
    date: '日期',
    time_start: '開始時間：',
    time_end: '結束時間：',
    location: '地點',
    upload_photo: '請上載你的義務服務證書及活動相片 （可上載圖片及PDF檔案）',
    make_sure: '請確認你所提交的資料沒有錯誤，凝動香港體育基金或會以電郵或電話聯絡主辦單位核實義務服務紀錄。',
    submit: '提交',
    uplpoad: '上傳',
    go_push: '跳轉到我的教練簡介頁面',
    saved_coaches: '已收藏的教練',
    saved_opportunities: '已收藏的教學機會',
    state: '學習狀態',
    created_opportunities: '已建立的教學機會',
    index: '工作卡',
    copy: '複製',
    edit: '修改',
    view: '查看',
    event_info: '活動名稱',
    coach_details: '教練詳情',
    profile: '檔案',
    Contact: '聯繫方式',
    Phone: '電話',
    Email: '電郵',
    confirm_connection: '確認聯繫',
    confirm: '確定',
    reject: '拒絕',
    post_date: '建立日期',
    last_edited: '最後修改日期',
    applied_opportunities: '我已申請的教學機會',
    Status: '狀態',
    wait: '等待負責人聯絡',
    connection: '負責人已撰擇聯繫的教練',
    connection_successed: '雙方已確認聯繫',
    rejected: '申請不成功',
    confirmed: '已確認',
    w: '等待確認',
    c: '對方已申請',
    r: '對方已拒絕',
    a: '已確認',
    c1: '已接受邀請',
    notification: '通知',
    received_invitation: '收到的邀請',
    my_invitation: '已提出的邀請',
    response: '接受邀請',
    accept: '接受',
    coach_name: '教練姓名',
    view_voach: '查看教練檔案',
    cert: '下載完成課程證書',
    notif:
      '成功提交！凝動教練培訓學院已向機構負責人發出電郵，確認你的義務工作資料。你亦可以提醒相關負責人查閱及確認郵件，以加快審核程序。一經確認，本學院將會審核您的教練檔案，敬請耐心等候。 你亦可在會員專區內查看已提交紀錄的審核進度。如有任何疑問，請電郵至academy@inspiringhk.org查詢。',
    beneficiaries: '受惠人數',
    volunteer_encourage_1: '建立教練檔案後，首6個月內填寫最少3小時的義務教學/學習時數，回饋社會！',
    volunteer_encourage_2: '馬上到「會員專區 - 我的義務服務證明及紀錄」內填寫資料及上載證明，首250名於10月31日前成功上載3小時義務記錄的運動教練，即可獲得$100禮劵。',
    volunteer_desc: '何謂「運動回饋時數」？',
    volunteer_list_1: '活動/比賽義工',
    volunteer_list_2: '運動大使 / 代表',
    volunteer_list_3: '球隊助理',
    volunteer_list_4: '講座',
    volunteer_list_5: '證書課程',
    volunteer_example_1: '陳教練為中學擔任羽毛球隊顧問，每年有30位基層學生受惠。',
    volunteer_example_2: '陳教練參加「預防青少年運動創傷」的專題講座，預計在未來的活動中能夠應用，約有100名基層學生受惠',
    volunteer_example_3: '陳教練在所屬球會的羽毛球推廣日中義務教授羽毛球活動，活動當日有100位基層學生受惠。',
    volunteer_example_4: '陳教練義務帶領15位學生參加羽毛球比賽，並於比賽結束後為與學生一同作賽後檢討。',
    volunteer_mark_a: '例子 A',
    volunteer_mark_b: '例子 B',
    volunteer_mark_c: '例子 C',
    volunteer_mark_d: '例子 D',
  },
  Partners: {
    contribution_title: '捐助夥伴',
    contribution_txt: '凝動教練培訓學院現接受以下機構的捐助，我們衷心感謝各善長慷慨解囊:',
    contribution_jocky: '[ 香港賽馬會慈善信託基金 ]',
    contribution_commission: '[ 兒童事務委員會 ]',
    contribution_swire: '[ 太古基金 ]',
    unit1: '單元一：認識兒童及青少年',
    unit2: '單元二：認識體育促進兒童及青少年發展',
    unit3: '單元三：運動的價值觀、道德及倫理',
    unit4: '單元四：鼓勵女性参與更多運動',
    unit5: '單元五：鼓勵有特殊學習需要的兒童及青少年參與運動',
    unit6: '單元六：鼓勵少數族裔的兒童及青少年參與運動',
    unit7: '單元七：運動與心理健康',
    adviser: '課程顧問',
    adviser_txt: '感謝以下專業人士/單位為我們的網上學習單元/課程提供顧問服務:',
    advisers_name: '曾潔雯博士',
    advisers_en: '',
    advisers_work: '香港大學社會工作及社會行政學系系主任及副教授',
    advisers1_name: '李翠莎博士',
    advisers1_en: 'PhD, JD, SBS, BBS',
    advisers1_work: '香港體育學院院長',
    advisers2_name: '鍾伯光教授',
    advisers2_en: 'JP',
    advisers2_work: '香港浸會大學社會科學院副院長 （發展）',
    advisers3_name: '薛慧萍教授',
    advisers3_en: '',
    advisers3_work: '香港中文大學教育學院副院長（本科課程）',
    advisers4_name: '薛慧萍教授',
    advisers4_en: '',
    advisers4_work: '香港中文大學體育運動科學系系主任及教授',
    advisers5_name: '雷雄德博士',
    advisers5_en: '',
    advisers5_work: '香港教育大學健康與體育學系副系主任及高級講師',
    advisers6_name: '謝家德博士',
    advisers6_en: '',
    advisers6_work: '香港大學運動中心總盬',
    advisers7_name: '陳玉鳳女士',
    advisers7_en: '',
    advisers7_work: '香港大學香港賽馬會防止自殺研究中心培訓顧問及輔導心理學家',
    coach_companion: '凝動「培」你教',
    coach_units: '網上資源合作夥伴',
    coach2_units: '體育活動影片庫合作單位及教練',
    coach3_units: '教學資源合作單位',
    coach4_units: '網上專家分享合作夥伴',
    coach_txt: '我們十分感謝以下專業個人或團隊教練為我們的網上學習平台提供教學資源 (排名不分先後):',
    coach4_txt: '香港運動醫學及科學學會',
    coach5_txt: '亞洲運動及體適能專業學院',
    coach1: '葉奕航先生 - ',
    coach1_work: '專業空手道教練',
    coach2: '譚國權先生 - ',
    coach2_work: '專業合球教練',
    coach3: '運動獵人 王紹宇先生 - ',
    coach3_work: '專業躲避盤教練',
    coach4: '吳偉嘉先生 - ',
    coach4_work: '專業健身教練',
    resources_1: '香港中文大學 體育運動科學系',
    resources_2: '薛慧萍教授 - 香港中文大學體育運動科學系系主任及教授',
    resources_3: '何禮樂博士 - 香港中文大學體育運動科學系講師',
    resources_4: 'Dr. SINEAD SHERIDAN - 香港中文大學體育運動科學系兼任講師',
    venue_sponsor: '場地提供',
    venue_sponsor_name: '[ 民坊 ]',
    restaurant_title: '飲食業夥伴',
    restaurant_txt: '凝動一直秉承「以體育造就青年」的願景。除了跟藝術界合作，凝動亦與飲食業界協作。在2021年，我們很榮幸跟F&B Run Club合作，為飲食業界基層家庭謀福祉。',
    fb_tit: 'F&B Run Club',
    fb_txt1: 'F&B Run Club 是一個旨在為不同慈善機構籌款的飲食業團體。與此同時，他們相信運動、自身進修和身心健康可以跟履行社會責任以及建設社區結合。',
    fb_txt2:
      '在飲食業最艱難的時候，F&B Run Club 希望為社區傳播正面的訊息，為受到嚴重影響和有需要的人出一分力，並為飲食業集結能量。在2021年， F&B Run Club的目標是要在2021年12月31日前，通過走路、遠足或跑步，累積到由地球到月球的距離，共384,400公里！',
    website: '網站',
    act_title: '首個合作活動「奔向月球」:' + '\n' + ' 為飲食業的基層年青人籌款',
    act_txt:
      '除了累積 384,400公里的距離，F&B Run Club還與凝動香港體育基金合作，希望能於2021年6月1日至2021年9月1日期間籌得港幣384,400元，為本地基層青少年舉辦兩個由專業教練帶領、長達一年的長跑課程（共40節）。是次計畫旨在幫助至少 40 名父母在餐飲業工作的學員，貢獻 2,800 小時的運動時間以及以體育造就青年。',
    table_title1: '啟發篇',
    top_details1: '賽馬會社區持續抗逆基金 - 凝動體育教練培訓',
    table_title2: '守護篇',
    top_details2: '凝動守護兒童體育學習平台',
    table_title3: '凝聚篇及行動篇',
    top_details3: '教練網絡及教練行動',
    blue_title: '賽馬會社區持續抗逆基金 - 凝動體育教練培訓',
    blue2_title: '',
    box_text: '凝動守護兒童體育學習平台',
  },
  Comingsoon: {
    coming_soon: '準備推出！ 敬請期待',
  },
  Footer: {
    organizer: '主辦機構',
    donor: '捐助機構',
    hkjcct_logo_url: '../assets/images/hkjcct_cn.png',
    signup: '立即登記',
  },
  Header: {
    google: '繼續以 Google 登入',
    linkedin: '繼續以 Linkedin 登入',
    facebook: '繼續以 Facebook 登入',
  },
  Authorize: {
    title: '授權登錄',
    text: '是否授予該用戶權限？',
    yes: '允許',
    no: '拒絕',
  },
  CoachCorner: {
    create: '建立教練檔案',
    browse: '尋找教學機會    ',
    description1: '建構屬於自己的專業教練簡歷，提供學歷、教學照片、教學方針等，爭取更多教學機會    ',
    description2: '發掘最新收費及義務教學機會，教授運動技巧及傳遞體育精神    ',
    online: '網上學習單元',
    practical: '教學資源    ',
    tit_1: '凝動體育教練培訓',
    tit_2: '守護兒童體育學習平台',
    desc1: '提供七個網上學習單元及過百條體育活動示範影片，增值有關體育促進兒童及青少年發展的相關知識，同時激發創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    desc2: '增強本港運動教練及體育教育者對在體育中「守護兒童」的意識，為本地兒童及青少年建立一個正面及安全的運動及成長環境。     ',
    desc3: '提供可下載的一站式網上教學資源及網上專家講座，將平台所學的知識應用到教學之上，從而提升教學素質及專業水平。    ',
    desc4: '',
    link1: '教學資源',
    link2: '網上專家講座',
    link3: '體育活動影片庫',
    title1: '教練專區    ',
  },
  SchoolCorner: {
    post: '建立教學活動',
    explore: '搜尋教練',
    description1: '於平台上發佈你的小組活動詳情，開始尋找合適的運動教練！',
    description2: '輕鬆查閱傳統或新興運動教練的詳細檔案，邀請合適教練申請你已建立的教學機會',
    online: '網上學習單元',
    verify: '如何核實教練身分',
    tit_1: '凝動體育教練培訓',
    tit_2: '守護兒童體育學習平台',
    desc1: '提供七個網上學習單元及過百條體育活動示範影片，增值有關體育促進兒童及青少年發展的相關知識，同時激發創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    desc2: '增強本港運動教練及體育教育者對在體育中「守護兒童」的意識，為本地兒童及青少年建立一個正面及安全的運動及成長環境。     ',
    desc3: '教練檔案多不勝數，如何才能尋找安全又可靠的教練？    ',
    desc4: '凝動教練培訓學院教你逐步核實教練！',
    title1: '學校、社福及工商業界專區    ',
    box1: '開始學習',
    box2: '開始學習',
    box3: '了解更多',
  },
  ParentsCorner: {
    post: '建立教學活動',
    explore: '搜尋教練',
    description1: '於平台上發佈你的小組活動詳情，開始尋找合適的運動教練！',
    description2: '輕鬆查閱傳統或新興運動教練的詳細檔案，邀請合適教練申請你已建立的教學機會',
    online: '網上學習單元',
    verify: '如何核實教練身分',
    tit_1: '凝動體育教練培訓',
    tit_2: '守護兒童體育學習平台',
    desc1: '提供七個網上學習單元及過百條體育活動示範影片，增值有關體育促進兒童及青少年發展的相關知識，同時激發創意，為體育課程注入更多正面元素並以更創新的方法協助本地兒童及青少年成長。',
    desc2: '增強本港運動教練及體育教育者對在體育中「守護兒童」的意識，為本地兒童及青少年建立一個正面及安全的運動及成長環境。     ',
    desc3: '教練檔案多不勝數，如何才能尋找安全又可靠的教練？    ',
    desc4: '凝動教練培訓學院教你逐步核實教練！',
    title1: '家長專區    ',
    box1: '開始學習',
    box2: '開始學習',
    box3: '了解更多',
  },

  ...cnLocale,
}
export default cn
