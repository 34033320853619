import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  coming_soon: 'coming_soon',
  cn: '繁體中文',
  en: 'ENGLISH',
  Log_out: 'Log out',
  log_in: 'Log in',
  welcome: 'Welcome, XXX',
  Nav: {
    CoachConnect: 'Coach Connect',
    VerifyCoach: 'How to Verify a Coach',
    CoachProfiles: 'Explore Coach Profiles',
    Opportunities: 'Browse Coaching Opportunities',
    CoachAction: 'Coach Action',
    CoachingTools: 'Coaching Resources',
    OnlineExperts: 'Online Experts' + '\n' + 'Sharing',
    CoachCorner: `Coaches' Corner`,
    SchoolCorner: `Schools, Commercial and Social Welfare Organisations’ Corner `,
    ParentsCorner: `Parents Corner`,
  },
  HomeNew: {
    Finished: 'Finished Unit',
    Accumulated: 'Accumulated',
    hours: 'hours',
    bg1: 'The Swire Charitable Trust',
    bg2: '',
    bg3: 'CoachConnect',
    pic1: 'Explore Coach Profiles',
    pic2: 'Create Coach Profile',
    pic3: 'Browse Coaching Opportunities',
    pic4: 'Post Coaching Opportunity',
    title1: 'Explore Coaches',
    sport1: 'ALL SPORTS',
    sport2: 'BALL',
    sport3: 'NEW SPORTS',
    sport4: 'Racket Sports',
    sport5: 'Bat Sports',
    title2: 'Recent Coaching',
    title3: 'Opportunities',
    btn1: 'Post Coaching Opportunity',
    btn2: 'Explore More',
    ranking1: 'Keen Learner',
    ranking2: 'Give Back of the Month',
    about: 'About CoachConnect',
    aboutinfo: 'About CoachConnect',
    inform1:
      'Sponsored by The Swire Group Charitable Trust, CoachAction provides members with downloadable one-stop online coaching resources, including training plans, coaching notes, goal setting materials, etc. ',
    inform2:
      'Online coaching resources aim to assist sports coaches, physical education teachers or anyone who wants to know more about the professional knowledge of sports to promote the development of children and youth through sports. After completing online learning units, coaches could apply the knowledge learned on teaching and improving their coaching quality and professional skills.',
    inform3:
      'Besides, CoachAction also provides online expert seminars to further explore related learning topics including challenges deferring girls and women from sports participation, engaging children and youths with special educational needs(SEN) in sports, sports and mental wellbeing, etc. for coaches to continue learning and understanding the needs of the sports industry and service targets.',
    info1:
      'Sponsored by The Swire Group Charitable Trust, CoachConnect establishes a free connection and communication platform for sports coaches, allowing coaches to build professional profiles. The coach profile includes coaching experiences, qualifications, learning record from the Coaching for Youth Academy. By successfully establishing a personal profile, coaches then can browse and apply for suitable paid or voluntary coaching opportunities. For schools, non-profit organisations, charitable organisations, or anyone who would like to organise group sports activities, can utilise the CoachConnect platform to create coaching opportunities and search for potential coaches.',
    info2:
      'Besides, coaches will give back to the community by taking actions to serve at least 3 hours of voluntary sports activities for the underprivileged within 6 months  upon creating coach profiles.The first 200 sports coaches who completed the 3-hour voluntary coaching opportunities for the community will receive sports coupons or gifts as incentives.',
    info3: '',
  },
  CoachProfiles: {
    All_sports: 'All sports',
    select_job: 'Please select the event to invite',
    job_name: 'Event Name',
    cancel: 'Cancel',
    tit: 'Explore Coaches',
    tit1: 'Explore Coach Profiles by Sports Categories',
    tit2: 'Coach Profiles',
    btn1: 'Create My Coach Profile',
    btn2: 'More',
    btn3: 'Save',
    btn4: 'Connect',
    btn5: 'UnSave',
    mainsport: 'Main Sport :',
    position: 'Title :',
    please: 'Please log in or create an account before creating your coach profile. Thank you!',
    please1: 'Please login or create an account before posting your Coaching Opportunity. Thank you!',
    please3: 'Please login or create an account. Thank you!',
    welcome: 'Welcome',
    confirm: 'Confirm',
  },
  Jobs: {
    nodata: 'No Data',
    apply: 'Apply',
    unapply: 'Unapply',
    save: 'Save',
    unsave: 'UnSave',
    tit1: 'Browse Coaching',
    tit2: 'Opportunities',
    tit3: 'Post Coaching',
    tit4: 'Opportunities',
    btn1: 'Post Coaching Opportunity',
    search: 'Search',
    classSize: 'Class Size',
    date: 'Date',
    typeSprot: 'Type of Sports',
    txtInfo1: 'Level of Skills',
    txtInfo2: 'Medium of Instruction',
    txtInfo3: 'Class Frequency',
    txtInfo4: 'Remarks',
    txtInfo5: 'Price',
    screeningTypes1: 'Show all',
    screeningTypes2: 'My Jobs',
    screeningTypes3: 'My collection of Jobs',
    screeningTypes4: 'My apply Jobs',
    selectTypes1: 'Lastst',
    selectTypes2: 'Last Edited',
    selectTypes3: 'Sports Catergories',
    paid: 'Connect Paid Coach',
    voluntary: 'Connect Voluntary Coach',
    information: 'Organisation Information',
    placeholder1: 'Organisation Name（if applicable）',
    placeholder2: 'Name of Person in Charge',
    placeholder3: 'Event Name',
    placeholder4: 'Event Type',
    placeholder5: 'Sports Type',
    placeholder6: 'No Data',
    placeholder7: 'Starting Date',
    placeholder8: 'Ending Date',
    placeholder9: 'Class Frequency',
    placeholder10: 'Class Duration Start Time',
    placeholder11: 'Class Duration End Time',
    placeholder12: 'Please select a time range',
    placeholder13: 'Lesson(s)',
    placeholder14: 'Location',
    placeholder15: 'Target',
    placeholder16: 'Class Size',
    placeholder17: 'Skill Level',
    placeholder18: 'Medium Of Instruction',
    placeholder19: 'Remarks ',
    placeholder20: 'Coach Fee',
    placeholder21: 'Preferred with Coaching Experiences for Below Groups:',
    placeholder22: 'Volunteer Hours',
    placeholder23: 'Description ',
    coachrequest: 'Coach Request',
    upload: 'Upload Banner Photo',
    classFrequency1: 'Every Monday',
    classFrequency2: 'Every Tuesday',
    classFrequency3: 'Every Wednesday',
    classFrequency4: 'Every Thursday',
    classFrequency5: 'Every Friday',
    classFrequency6: 'Every Saturday',
    classFrequency7: 'Every Sunday',
    classFrequency8: 'Everyday',
    classFrequency9: 'Every Week',
    classFrequency10: 'Every Second Week',
    classFrequency11: 'Every Month',
    classFrequency12: 'To Be Confirmed',
    eventsTypes1: 'Regular Training Course',
    eventsTypes2: 'One-off Event',
    skillLevel1: 'No Relevant Experiences',
    skillLevel2: 'Introductory',
    skillLevel3: 'Intermediate',
    skillLevel4: 'Advanced',
    skillLevel5: 'Professional',
    mediumOfInstruction1: 'Cantonese',
    mediumOfInstruction2: 'Putonghua',
    mediumOfInstruction3: 'English',
    coachFee1: 'Negoiable',
    coachingExperiences1: 'Physical Disability',
    coachingExperiences2: 'Visual Impairment',
    coachingExperiences3: 'Hearing Impairment',
    coachingExperiences4: 'Attention Deficit / Hyperactivity Disorder (ADHD)',
    coachingExperiences5: 'Autism Spectrum Disorder (ASD)',
    coachingExperiences6: 'Specific Learning Difficulties (SpLD)',
    coachingExperiences7: 'Speech and Language Impairment',
    coachingExperiences8: 'Mental Illness',
    coachingExperiences9: 'Chronic Illness',
    post_date: 'Post Date',
    last_edited: 'Last Edited',
    phone: 'Phone Number',
    email: 'Email',
    dialog1: 'You are now applying for IHK Sports Football Training. Once confirmed, Organisation/individual will receive your contact. Organisation/individual can contact you via:',
    dialog2: 'You have successfully applied for the below job card. Organisation/ Individual may contact you and further discuss the coaching opportunities.',
    dialog3: 'Anything you want to tell…',
    confirm: 'Confirm',
    submit: 'Submit',
  },
  Skills: {
    Personality: 'Personality',
    Motivate: 'Motivate',
    Passionate_about_sports: 'Passionate about sports',
    Casual: 'Casual',
    Creative_Thinking: 'Creative Thinking',
    Passionate_about_teaching: 'Passionate about teaching',
    Flexibility: 'Flexibility',
    Confidence: 'Confidence',
    With_a_clearly: 'With a clearly defined target',
    Goal_oriented: 'Goal-oriented',
    Knowledgeable: 'Knowledgeable',
    Careful: 'Careful',
    Respectful: 'Respectful',
    Patient: 'Patient',
    Chatty: 'Chatty',
    Approachable: 'Approachable',
    Fair: 'Fair',
    Logical: 'Logical',
    Supportive: 'Supportive',
    Persuasive: 'Persuasive',
    Calm: 'Calm',
    Curious: 'Curious',
    Principled: 'Principled',
    Systemic: 'Systemic',
    Adventurous: 'Adventurous',
    Positive: 'Positive',
    Optimistic: 'Optimistic',
    Leadership: 'Leadership',
    Inspiring: 'Inspiring',
    Professional: 'Professional',
    Good_listener: 'Good listener',
    Reliable: 'Reliable',
    Attention_to_detail: 'Attention to detail',
    Self_discipline: 'Self-discipline',
    Good_communication_skills: 'Good communication skills',
    Harmonious: 'Harmonious',
    Friendly: 'Friendly',
    Perseverance: 'Perseverance',
    Clear_instructions_and_expressions: 'Clear instructions and expressions',
    Accept_people_with_different_abilities: 'Accept people with different abilities',
    Rapid_response: 'Rapid response',
    Kind: 'Kind',
    Tolerant: 'Tolerant',
    Strict_image: 'Strict image',
  },
  CreateProfiles: {
    p1: 'Coach can provide the following items for verification:',
    p2: 'Certificate of Education',
    p3: 'Coach License',
    p4: 'Certificate in First Aid',
    p5: 'SCRC Record',
    p6: 'Click to learn more about【How to Verify a Coach】',
    date_start: 'Start Year:',
    date_end: 'End Year:',
    clickupload: 'Click on the upload',
    tit: 'Coach Profile',
    bgBtn: 'Change Picture',
    infoTit1: 'For You to Verify',
    infoTit2: 'About Me',
    infoTit3: 'Coaching Fee',
    public: 'Public',
    unpublic: 'Negotiable',
    Perhour: 'per hour',
    infoTit4: 'Qualification & Certificates',
    infoTit5: 'Coaching for Youth Academy Highlights',
    infoTit6: 'CoachConnect Record',
    infoTit7: 'Coaching Experiences',
    infoTit8: 'Something Ｍore About Me',
    infoTit9: 'My Skills',
    btn1: 'SCRC Record',
    btn2: 'Coach License',
    btn3: 'Saved',
    btn4: 'Connect to Job Opportunity NOW',
    star: 'Star Rate',
    name: 'Name',
    portrait: 'Head portrait',
    position: 'Title',
    mainsport: 'Main sports',
    coachtarget: 'Coach target',
    inspires: 'SPORTS Coach Inspires!',
    learning: 'Learning Record',
    inspires_subtext1: 'Shows the learning progress on online learning units of SPORTS Coach Inspires.',
    inspires_subtext2: 'The more units completed, the more professional knowledge the coach has acquired on better youth through sports.',
    sharing: 'Attended Experts’ Sharing',
    sharing_subtext1: 'Shows the number of online expert seminars attended.',
    sharing_subtext2:
      'The more seminars the coaches participate in, it means that the coach is willing to continue learning about sports-related issues and understanding the needs of sports industry and service targets.',
    safe: 'Child Safeguarding Learning Platform',
    safe_subtext1: 'Shows the learning progress on online learning units of Child Safeguarding Learning Platform.',
    safe_subtext2: 'The more units completed, the more professional knowledge the coach has acquired on better youth through sports.',
    edit: 'Edit',
    editinfo: 'Edit Info',
    remove: 'Remove',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    upload: 'Upload',
    pleaseselect: 'Please select',
    filetype: 'File Type',
    filepath: 'File Path',
    download_here: 'Download Here',
    filelink: 'File Link',
    jump: 'Jump',
    download: 'Download',
    cover: 'Cover',
    path: 'Path',
    link: 'Link',
    title: 'Title',
    file: 'File',
    image: 'Image',
    coachingTarget1: 'Primary School',
    coachingTarget2: 'Secondary School',
    coachingTarget3: 'Parent-child',
    coachingTarget4: 'Elderly',
    coachingTarget5: 'Others',
    forCoachingFee1: 'Above market price',
    forCoachingFee2: 'Average market price',
    forCoachingFee3: 'Below market price',
    forCoachingFee4: 'Preferred not to say',
    forCoachingFee5: 'Volunteering',
    placeholder1: 'Name Of Issued Organisation',
    placeholder2: 'Name Of Qualification',
    placeholder3: 'Start Year',
    placeholder33: 'End Year',
    placeholder4: 'Description',
    placeholder5: 'Name Of Organisation',
    placeholder6: 'Duration',
    placeholder7: 'Location',
    placeholder8: 'Job title',
    placeholder9: 'Competitions & Awards ',
    placeholder10: 'Name of Competition',
    placeholder11: 'Award',

    treaty_tit: 'You are going to create coach profile, please read the following Terms of Services:',
    treaty_ihk: 'InspiringHK Sports Foundation Company Limited (the “Foundation”)',
    paragraph1:
      '1. General \n The Foundation is committed to protecting the personal data provided by you and it is the Foundation’s policy to ensure its policies in relation to the collection, use, transfer, retention and access of personal data comply with the requirements of the Personal Data (Privacy) Ordinance, Cap.486 .',
    paragraph2:
      '2. Types of Personal Data Collected \nFor the purpose of provision of charity services by the Foundation, you may be requested to provide certain personal data including but not limited to the name, contact details (including but not limited to telephone number, fax number, email and/or correspondence address) and copies of any documents which may contain your personal data. Provision of personal data is on a voluntary basis.',
    paragraph3:
      '3. Use of Personal Data \n The personal data provided by you may be used by the Foundation for the purposes of: \n a) provision of charity services by the Foundation; \n b) external communication of the charity services provided by the Foundation (including but not limited to charity programs, fund raising activities and volunteer services etc.) \n c) distribution of the Foundation’s newsletters; and \n and d) compliance with laws, rules and regulations applicable to the Foundation from time to time. \n',
    paragraph4:
      '4. Use of Personal Data for External Communication \n The Foundation intends to use the name and contact details (including telephone number, fax number, email and/or correspondence address) of you for external communication of the charity services (including but not limited to charity programs, fund raising activities and volunteer services etc.), provided by the Foundation. The personal data provided by you will not be disclosed by us to any other third party without your prior consent.      ',
    paragraph5:
      "5. Request to Cease the Use of Personal Data and Erasure of Personal Data \n You have the right, at any time without charge, to request the Foundation to cease to use your personal data for external communication. Additionally, the Foundation may take reasonable and practicable steps to erase your personal data where the data is no longer required for the purpose for which the data was used, unless such erasure is prohibited by law or it is in the public interest not to have the data erased. You may write to info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay for making such request.",
    paragraph6:
      '6. Security and Retention of Personal Data \n The Foundation has implemented internal control measures to ensure the security of personal data and to prevent unauthorized or accidental access, process, erasure, loss or use of personal data.',
    paragraph7:
      "7. Access to and Correction of Personal Data \n You have the right to access and/or amend your personal data, data, including the right to request the Foundation to cease to use your personal data for external communication. You may at any time write to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay. Please note that the Foundation has the right to charge a reasonable fee for the processing of any data access request.",
    paragraph8:
      "8. Enquiries \n Enquiries concerning your personal data provided, including making access and corrections, shall be addressed in writing to the Development Officer of the Foundation, at info@inspiringhk.org or Room 901-903, Prosperity Millennia Plaza, 663 King's Road, Quarry Bay.",
    treaty1: 'I have read and understood the above terms of services',
    treaty2: 'I declare that all information provided in the Coaching for Youth Academy is correct, complete and true to the best of my knowledge and belief',
    treaty3: 'After creating a coach profile, I understand that my contact and email will be shared when connecting to coaching opportunities',
    message:
      'Submission successful! \n IHKSports Coaching for Youth Academy is reviewing your coach profile, please be patient. \n The review will take no more than 3 working days. Once approved, it will be published on our online platform.You can check and apply for the latest job opportunities in “Browse Coaching Opportunities" and. \n The individual/organisation will contact you once the application has been received.Should you have any queries, please email academy@inspiringhk.org.',
    message2:
      'Submission successful! \n   IHKSports Coaching for Youth Academy is reviewing your coaching opportunity, please be patient. The review will take no more than 3 working days. Once approved, it will be published on our online platform. \n If coaches are interested in applying for the coaching opportunity, you will be able to obtain their contact and relevant information in the "Members Zone”. You can also search for suitable coaches in “Explore Coaches” and invite them to apply for your teaching opportunity. \n Should you have any queries, please email academy@inspiringhk.org.',
    terms:
      'I/Organization understands that "CoachConnect” is a third-party coach connection platform.The members of IHKSports Coaching for Youth Academy are from Hong Kong local sports coaches, physical education teachers, youth workers, etc. Therefore, using IHKSports Coaching for Youth Academy to look for coaching opportunities or find coaches, the individual will undertake to screen.',
    terms2:
      'I/Organization understands the terms in the disclaimer and agrees that any content, information, and communications using IHKSports Coaching for Youth Academy services, activities, gatherings, or voluntary work, are based on individual/organization decisions. IHKSports Coaching for Youth Academy has tried its best to ensure that the information of IHKSports Coaching for Youth Academy is accurate, but will not be responsible for any errors, omissions, misstatements, or misrepresentations (whether express or implied).',
    verify_terms: `Click <a style='color:#16287a;text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/coach-connect/how-verify'>here</a> to learn more about how to verify a coach`,
    fb_ig: 'FB/IG',
    pre_made_banner: 'Or you can use one of this images',
  },

  forget_password: 'Forget the password',
  Home: {
    email: 'Email',
    code: 'Code',
    password: 'Password',
    Register: 'Register Now',
    subscription: 'Subscribe',
    nav0: 'Home Page',
    nav1: 'About Us',
    nav2: 'Inspiration',
    nav3: 'Safeguarding',
    // nav4: 'Connection & Action',
    nav4: 'Corners',
    nav5: 'CoachConnect',
    nav6: 'CoachAction',
    nav7: 'Our Partners',
    nav8: 'Member Zone',
    nav2_1: 'SPORTS COACH Inspires',
    nav9: 'Sports for Development E-learning Courses',
    nav10: 'Sports Activity Hub',
    nav11: 'Sports Activity Hub',
    banner_tit1: 'IHKSports Coaching',
    banner_tit2: 'for Youth Academy',
    more: 'Learn more',
    more1_detail: 'Sports for Development Learning' + '\n' + ' Units and Video Hub ',
    more2_detail: 'Child Safeguarding Courses ',
    more3_detail: 'Coaches Database and Teaching ' + '\n' + 'Opportunities Search Engines ',
    more4_detail: 'Coaching Materials and Online Experts Sharing',
    download: 'Download Scheme Poster',
    download_short: 'Download Scheme Poster',
    txt1: 'IHKSports Coaching for Youth Academy (The Academy) is the first and foremost online learning platform constructed by InspiringHK Sports Foundation (IHKSports) consisting of wide-ranging learning resources for sport educators. With a variety of online resources conducted by professionals, the platform aims to enrich users ‘professional knowledge on empowering children and youths through the concept of sports for development and child safeguarding. On top of that, The Academy aims to connect coaches and target users through providing volunteering or social impactful opportunities in order to "Empower Youths Through Sports".',
    txt2: 'Inspiration: Jockey Club Community Sustainability Fund - SPORTS COACH Inspires!',
    txt3: 'Supported by The Hong Kong Jockey Club Charities Trust, the platform will first launch <strong>Jockey Club Community Sustainability Fund - SPORTS COACH Inspires!</strong> in June, 2021.  In this part, it provides users 7 online learning units and more than 100 sports activities demonstration videos. This section not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.',
    txt4: 'Part 2:"Safeguarding: Child Safeguarding Learning Platform" is funded by the Commission on Children. This part  discusses the definitions, examples, and theories about child safeguarding that are often misunderstood, taken lightly or turned a blind eye to. This part will be launched in October 2021, please stay tuned.',
    txt5: 'Part 3:"Connection & Action: CoachConnect and CoachAction" matches sports coaches with service users, and provides tools coaches need to improve the quality of physical education. This part will be launched in November 2021. Please stay tuned.',
    title1: 'An online platform concerning sports for development that inspires, protects, connects and executes',
    nav_img1_tit: 'Inspiration',
    nav_img1_txt1: 'Jockey Club Community' + '\n' + 'Sustainability Fund - ' + '\n' + 'SPORTS COACH Inspires',
    nav_img1_txt2: 'Sports for Development E-Learning Courses',
    nav_img1_txt3: 'The content of the course includes knowledge and skills about coaching with the concept of sports for development and engaging children and youths of various backgrounds.',
    nav_img1_txt4: 'Users complete the course based on  their own learning pace and will be awarded a certificate after completion.',
    nav_img1_txt5: 'Sports Activity Hub',
    nav_img1_txt6: 'Numerous demonstration videos of sport activities. Users can search for suitable sport activities by students’ age, ability and teaching goals etc.',
    null: '',
    nav_img2_tit: 'Safeguarding',
    nav_img2_txt1: 'Child Safeguarding' + '\n' + 'Learning Platform',
    nav_img2_txt2: 'Interactive Online Learning Courses',
    nav_img2_txt3: 'The content of the course includes knowledge about strategies, codes, risk assessments, and reports to protect children in a sports environment.',
    nav_img2_txt4: 'Users complete the course according to their own learning progress and will be awarded a certificate after completion.',
    nav_img2_txt: '',

    nav_img3_tit: 'CoachConnect',
    nav_img3_txt1: 'CoachConnect',
    nav_img3_txt2: 'CoachConnect me now!',
    nav_img3_txt3: 'I want to join the Coach Network! Support local sports coaches to enhance their competitiveness by presenting their CVs and matching them with potential teaching opportunities',
    nav_img3_txt4:
      'I want to find coaches through the "Coach Network"! For service users to find sports coaches, educate the public about what is qualified and suitable coaches, and help coaches to inject positive values ​​into sports',
    nav_img3_txt5: 'I want to CoachConnect now!',
    nav_img3_txt6: 'Help service users look for sports coaches, teach the public about defining qualified and suitable coaches, and help coaches instill  positive values in sports.',

    nav_img4_tit: 'CoachAction',
    nav_img4_txt1: 'CoachAction',
    nav_img4_txt2: 'Social Feedback',
    nav_img4_txt3: 'Provide at least three hours of free sports training for grassroots children and youths, and benefit the society with the spirit of sports for development.',
    nav_img4_txt4: 'Expert Sharing',
    nav_img4_txt5:
      'Organize expert sharing sessions on different topics to discuss in depth how to promote the development of children and youths through sports in the form of interactive discussions.',
    nav_img4_txt6: 'Practical Tools',
    nav_img4_txt7: 'Provide online practical teaching resources such as annual teaching plans, lesson plans, goal setting worksheets and teaching guide cards, etc.',
    nav_img4_txt: '',
    join_txt: 'To create a culture of learning to equip all sports coaches, PE teachers, youth workers and parents to make positive impact on children and youths through sports',
    join_tit: 'Register now to join us!',
    login: 'Log in',
    exit: 'cancel',
    register: 'Register',
    lang: '繁體中文',
    change_password: 'Change Password',
    confirm: 'Confirm',
    old_password: 'Old Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    account_not_yet: 'No Account Yet ? ',
    sign_in_now: 'Register Now!',
    coming_soon: 'Coming Soon',
    jockey_club_url: 'https://charities.hkjc.com/charities/english/index.aspx',
    login_success: 'Login success',
    login_fail: 'Login fail',
    corner_1: `<a style='color:white;' target='_blank' href='/#/coach-connect/coach-corner'>Coaches’ Corner</a`,
    corner_2: `<a style='color:white;' target='_blank' href='/#/coach-connect/school-corner'>Schools, Commercial and Social Welfare Organisations’ Corner</a>`,
    corner_3: `<a style='color:white;' target='_blank' href='#/coach-connect/parents-corner'>Parents Corner</a>`,
  },
  Verify: {
    title: 'How to Verify a Coach',
    tit: 'Steps to Verify',
    title3: 'Steps to Verify',
    step1: 'Get to Know the Coach',
    step2: 'Sexual Conviction Record Check (SCRC) ',
    step3: 'Setup Coach Meeting',
    step4: 'Verify the SCRC Record',
    step5: 'Observe During Class',
    step1_1:
      'Browse and compare the coaching background and experiences of different coaches in order to find the most suitable coach candidates. For example, what kinds of information a coach shares in the "Learn More" of the coach profile? Is he/she enthusiastic in his/her career? Any similar objectives and ideas shared with your organisation? Is he/she willing to keep himself/herself updated on sports knowledge and skills?',
    step1_2:
      'For example, you can see from a coach’s certificate whether he has continued to learn (the more the certificate is not the better, this is just one of the indicators you can observe). You can also learn about the coaching and  training methods from the self-practice of a coach, and he/she has an in-depth understanding of the sport.',
    step1_3:
      'If necessary, ask the coach to provide proof of coach license, academic qualifications and relevant coaching photos. For a registered coach of a local recognized sports organization or federation, you can generally search the coach name through the official website to enhance their recognition and authenticity. ',
    step1_4: 'List of Local Recognized Sports Organization or Federation',
    step2_1: 'What is Sexual Conviction Record Check (SCRC)?',
    step2_2:
      'The Sexual Conviction Record Check (SCRC) is an administrative scheme to enable employers of persons undertaking child-related work and work relating to mentally incapacitated persons (MIPs) to check whether eligible applicants have any criminal conviction records against a specified list of sexual offences at Appendix. This scheme serves to help employers assess the suitability of eligible applicants for child or MIPrelated work and afford better protection to children and MIPs from sexual abuse. ',
    step2_3: 'How to apply for Sexual Conviction Record Check (SCRC)?',
    step2_4: 'Required Documents:',
    step2_5: '(1) Hong Kong Identity (HKID) Card；',
    step2_6: '(2) A Completed Appliation Form；',
    step2_7: '(3) Application Fee (Please check from the Hong Kong Police)；',
    step2_8: '(4) A documentary proof of possible employment related to children or mentally incapacitated persons (MIPs) from relevant employer',
    step2_9: 'Address:',
    step2_10: '14/F, Arsenal House, Police Headquarters (PHQs), 1 Arsenal Street, Wan Chai, Hong Kong ',
    step2_11: 'Tel:',
    step2_12: '3660 7449 ',
    step2_13: 'More Info:',
    step2_14: `<a style='color:#16287a;text-decoration:underline;' href='https://www.police.gov.hk/ppp_tc/11_useful_info/scrc.html'>police.gov.hk/ppp_tc/11_useful_info/scrc.html</a>`,
    step3_1:
      'Coaching for Youth Academy has launched the "Child Safeguarding Learning Platform”, which is the first sports-related online course for guarding children in Hong Kong. The course consists of 5 units, increasing coaches’ awareness of child protection in sports, so as to establish a positive and safe sports and growth environment for local children and youth.',
    step3_2:
      'Coaches who have completed all units will receive a badge on their coach profiles for distinction. You can also check their learning progress from the coach profile. Coaches who have completed relevant courses are expected to have obtained such basic knowledge of the unit.',
    step4_1: 'Before confirming a coach, you are advised to meet with the coach for further discussion on programme objectives, background of the students, expectation, contingency plan, etc.:',
    step4_2: 'Is the coach enthusiastic in sports?',
    step4_3: 'Is the coach able to manage the physical condition and needs of students so as to arrange suitable training?',
    step4_4: 'Can the coach handle properly when encountering a sudden situation or accident? ',
    step4_5: `It is important for coaches to use accurate wordings to teach sports skills and convey sportsmanship to students. Therefore, effective communication between coaches, students and organisation is indispensable. The organization should understand the coach's teaching style so as to maximize the programme effectiveness and be more suitable to the needs of students. You can also ask the coach to provide lesson plans for reference before class.`,
    step5_1:
      'After commencing the class, the organization should arrange regular class visits and observe during class, for example, what kind of training does the coach impose? How does the coach provide guidance to students? Is he/she able to deliver sportsmanship? Is he/she using the appropriate language? ',
    step5_2:
      'You can also reference the lesson plan provided by the coach and compare whether the class maintains a good schedule. Collect in-depth opinions by interviewing selected participants. Iit is also recommended to set up a mid-term evaluation and end-of-the-term impact measurement for long-term sports class to know the effectiveness of the activities.',
    link_1: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.archery.org.hk/'>Hong Kong Archery Association</a>`,
    link_2: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkaaa.com/hkaaa/eindex.html'>Hong Kong Amateur Athletic Association</a>`,
    link_3: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkbadmintonassn.org.hk/en'>Hong Kong Badminton Association Limited</a>`,
    link_4: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkbaseball.org/'>Hong Kong Baseball Association Limited</a>`,
    link_5: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkbba.org/'>Hong Kong China Bodybuilding and Fitness Association</a>`,
    link_6: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkbilliardsports.org.hk/en/index.php?'>Hong Kong Billiard Sports Control Council Company Limited</a>`,
    link_7: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkboxing.org.hk/en_index.php'>Hong Kong Boxing Association</a>`,
    link_8: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcucanoe.com.hk/'>Hong Kong Canoe Union</a>`,
    link_9: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcmaa.com.hk/index_en.html'>Hong Kong Chinese Martial Arts Dragon and Lion</a>`,
    link_10: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcmaa.com.hk/index_en.html'>Dance Association Limited</a>`,
    link_11: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkcricket.org/'>Hong Kong Cricket Association</a>`,
    link_12: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.cycling.org.hk/?locale=en-US'>The Cycling Association of Hong Kong, China Limited </a>`,
    link_13: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkdba.com.hk/'>Hong Kong China Dragon Boat Association</a>`,
    link_14: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkfa.org.hk/EN/index.html'>Hong Kong Fencing Association</a>`,
    link_15: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkfa.com/en/index.php?'>The Hong Kong Football Association Limited</a>`,
    link_16: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkgateball.org.hk/E-Main/E-Main.htm'>Hong Kong, China Gateball Association Company Limited</a>`,
    link_17: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.gahk.org.hk/'>The Gymnastics Association of Hong Kong, China</a>`,
    link_18: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.handball.org.hk/'>Handball Association of Hong Kong, China</a>`,
    link_19: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hockey.org.hk/'>Hong Kong Hockey Association</a>`,
    link_20: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hkjudo.youdomain.com/eng/index.php'>The Judo Association of Hong Kong, China</a>`,
    link_21: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkkaratedo.com.hk/en/'>The Karatedo Federation of Hong Kong, China Limited</a>`,
    link_22: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='https://www.hkkartclub.org/zh/'>Hong Kong Kart Club Limited</a>`,
    link_23: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.korfball.org.hk/new/intro.html'>Hong Kong China Korfball Association</a>`,
    link_24: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://hklba.org/'>Hong Kong Lawn Bowls Association</a>`,
    link_25: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hklss.org.hk/public/modules_eng/index/index.asp'>The Hong Kong Life Saving Society</a>`,
    link_26: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkmu.org.hk/'>China Hong Kong Mountaineering and Climbing Union</a>`,
    link_27: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkmuaythai.org/'>Hong Kong Muay-Thai Association</a>`,
    link_28: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.netball.org.hk/'>The Hong Kong Netball Association</a>`,
    link_29: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.oahk.org.hk/eframe.html'>Orienteering Association of Hong Kong</a>`,
    link_30: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkparalympic.org/'>Hong Kong Paralympic Committee and Sports </a>`,
    link_31: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkparalympic.org/'>Association for the Physically Disabled</a>`,
    link_32: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://rollersports.org.hk/'>Hong Kong Federation of Roller Sports</a>`,
    link_33: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.rowing.org.hk/rowing/index.jsp?l=en'>Hong Kong, China Rowing Association</a>`,
    link_34: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkrugby.com/eng/home.php'>Hong Kong Rugby Union</a>`,
    link_35: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.sailing.org.hk/'>Hong Kong Sailing Federation</a>`,
    link_36: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkshooting.org.hk/'>Hong Kong Shooting Association</a>`,
    link_37: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://softball.org.hk/'>Hong Kong Softball Association</a>`,
    link_38: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hksam.org.hk/'>Hong Kong Sports Association for Persons with Intellectual Disability</a>`,
    link_39: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hksquash.org.hk/'>Hong Kong Squash</a>`,
    link_40: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkasa.org.hk/index.php?option=com_content&task=blogcategory&id=91&Itemid=203'>Hong Kong Amateur Swimming Association Limited</a>`,
    link_41: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktta.org.hk/'>Hong Kong Table Tennis Association </a>`,
    link_42: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktkda.com/index.html'>Hong Kong Taekwondo Association</a>`,
    link_43: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.tennishk.org/en/'>Hong Kong Tennis Association</a>`,
    link_44: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hktbc.org.hk/'>Hong Kong Tenpin Bowling Congress Limited</a>`,
    link_45: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.triathlon.com.hk/'>Hong Kong Triathlon Association</a>`,
    link_46: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkua.org.hk/'>Hong Kong Underwater Association</a>`,
    link_47: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.vbahk.org.hk/'>Volleyball Association of Hong Kong, China</a>`,
    link_48: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.waterski.org.hk/english/e_index.htm'>Hong Kong Water Ski Association</a>`,
    link_49: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.windsurfing.org.hk/en/home/index.php'>Windsurfing Association of Hong Kong</a>`,
    link_50: `<a style='color:#16287a; text-decoration:none;' target='_blank' href='http://www.hkwushuu.com.hk/'>Hong Kong Wushu Union</a>`,
    step: 'STEP',
    step2_0: 'How to check the SCRC result?',
    step2_15: 'With authorisation from coach, organisation / individual can obtain the below information:',
    step2_16: '(1) Letter with the random 14-digit computer generated unique checking code*',
    step2_17: '(2) The first four digits of the applicant’s HKID card number',
    step2_18: '*The checking code has a validity period of 18 months dated from the first uploading of the check result onto the ATAS',
    step2_19: 'Checking Method:',
    step2_20: 'Check result through the Auto Telephone Answering System (ATAS) at 3660 7499',
    step2_21: 'Release of Result:',
    step2_22:
      'The Auto Telephone Answering System will only disclose to the employer the applicant “with” or “without” sexual conviction record (Details of conviction record will NOT be disclosed). A transaction number will be provided for reference whenever the result is checked.',
    link2: `<a style='color:#16287a; text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/inspire'>SPORTS COACH Inspires!</a>`,
    link3: `<a style='color:#16287a; text-decoration:underline;' target='_blank' href='https://sportscoachhk.inspiringhk.org/#/safeguarding'>Child Safeguarding Learning Platform</a>`,
    step6_1: 'Professional ethics and conduct of coaches',
    step6_2:
      'Coaches should lead as an example and maintain professional ethics and conduct, so as to enable students with joyful sports experience and understand sportsmanship. If the coach is found to have professional misconduct and negligence, you may refer to the following handling methods:',
    step6_3:
      'All local sports associations and federations currently have policies to prevent sexual harassment. If involved with a registered coach from a local sports association, contact with related parties immediately.',
    last1: 'Servity Level',
    last2: 'Conduct (including but not limited to)',
    last3: 'Approach',
    last4: 'Low',
    last5: 'Late arrival/early departure, foul language, etc.',
    last6: 'Discuss with the coach about the situation and avoids repeating',
    last7: 'Middle',
    last8: 'Conflicts of interest, teaching errors, etc',
    last9: 'Clarify with coach immediately, and the class should be suspended until the problem is resolved',
    last10: 'High',
    last11: 'Harassment, violence, etc.',
    last12: 'Terminate remaining classes immediately, and contact with police if necessary',
  },
  CoachAction: {
    title: 'CoachAction',
    pic1: 'Online Expert Sharing',
    pic2: 'Coaching Resources',
    about: 'About CoachAction',
    soon: 'Coming Soon',
  },
  Tools: {
    title: 'Coaching Resources',
    tit1: 'Physical Activities Level Assessment Criteria and Guidelines',
    tit2: 'Coaching Cards',
    tit3: 'Lesson Plan Templates',
    tit4: 'Sports Injury: Prevention and Treatment',
    tit5: 'Coach Review Resources & Handouts',
    tit6: 'Kinesiology Taping Guides',
    tit7: 'Sports Activity Hub',
    tit8: 'Online Expert Sharing',
    desc: 'The Sports Activity Hub consists of a myriad of teaching resources planned, explained and demonstrated by professional coaches from various sports sectors to assist sport coaches and educators to gain access to demonstration videos and lesson notes ranging from on-the-spot physical activities to post-activity debriefing notes when doing lesson planning.',
    desc2: 'Description Here',
    warningtitle: 'Welcome',
    warningtext: 'Please log in or create an account. Thank you!',
    cuhk_paragraph:
      'Collaborated with the professional team from the Department of Sports Science and Physical Education of the Chinese University of Hong Kong, the Coaching for Youth Academy co-creates a series of coaching resources for sports coaches and any individuals related to sports to utilise during sports training.',
  },
  Sharing: {
    partner: 'Partner',
    title1: 'Online Experts',
    title2: 'Sharing',
    title3: 'Past Online Sharing',
    btn1: 'Apply Now',
    btn2: 'Learn More About the Speaker',
    online_share_title1: 'Understanding Sports Massage',
    online_share_date1: 'Date: 20 July, 2022 <br> Time: 7:00-7:30pm',
    online_share_description1:
      'Stiffness and pain on the next day after training is because of lacking proper relieving methods.<br>' +
      'Understanding sports massage can help improve students sports performance, reduce sports fatigue, and prevent sports injuries.',
    online_share_title2: 'Common Orthopaedics Injury on Children and Youths',
    online_share_speaker2: 'Dr Keith Wan. Specialist Orthopaedic Surgeon',
    online_share_description2:
      'Sports have a lot of advantages; however, it will be vulnerable to injuries if we have little accurate knowledge of exercises and limited awareness of safety measures. Different types of sports cause specific risks and injuries to different regions of the body, especially on the skeleton of a growing child. To understand the specific injuries that have different effects in the growing child, coaches could avoid and minimize the potential risk of sports injuries.',
    online_share_speakerdescription2:
      'Fellow of the Hong Kong College of Orthopaedic Surgeons, Fellow of the Hong Kong Academy of Medicine (Orthopaedic Surgery), Fellow of the Royal College of Surgeons of Edinburgh (Orthopaedic Surgery), Fellow of the Royal College of Surgeons of England.' +
      '<br>Dr Keith Wan graduated from the Faculty of Medicine of the Chinese University of Hong Kong and is currently the Associate Consultant of the Department of Orthopaedics and Traumatology in Kwong Wah Hospital, as well as the honorary Clinical Assistant Professor of the Department of Orthopaedics and Traumatology of the Chinese University of Hong Kong.  Dr Wan specializes in the management of sports injuries, adult joint reconstructions and trauma surgeries.  Dr Wan also serves in the Public Information Committee of the Hong Kong College of Orthopaedic Surgeons, the Doctors Commission of the Hong Kong Association of Sports Medicine and Sports Science, and the Medical Committee of the Hong Kong Football Association. ' +
      '<br>Dr Wan is a sports lover. He plays football, basketball, rugby, lawn bowl and participates in dragon boat racing. He is also a proud supporter of the Hong Kong Football Team!',
    online_share_title3: 'Kinesiology Taping Guides',
    online_share_date3: 'Date: 27 July, 2022 <br>Time: 7:00-7:30pm',
    online_share_description3:
      'Kinesiology Taping is a popular taping method in the sports industry in recent years, which is used to prevent sports injuries and improve human mechanics. Simply mastering the skills and applying the principles of sports tapes could be useful to prevent injuries, protect our body and improve performance in sports training.',
    online_share_title4: 'Sports Nutrition on Training',
    online_share_speaker4: 'Ms. Sylvia Lam, Consultant Dietitian, See Way Consultancy',
    online_share_date4: 'Date: 27 July, 2022 <br> Time: 7:30-8:30pm',
    online_share_description4:
      'Children and youths need to pay special attention to their diet. They need adequate energy and nutrient requirements for their daily activities and growth. Together with appropriate sports training and nutrition planning, students could maintain a high-standard physical fitness level and healthy bones, to improve their sports skills and strive for better results.',
    online_share_speakerdescription4:
      'Accredited Practicing Dietitian (Australia), Accredited Dietitian (HKAAD), Full member (HKDA), Approved Practitioner (Australian Centre of Eating Disorders ACFED) MSc (Nutrition and Dietetics), BSc (Nutritional Sciences), Professional Diploma in Diabetes Management and Education (CUHK)' +
      '<br>Sylvia obtained her Master of Nutrition and Dietetics qualification from the University Sydney, Australia in 2000. She has been practicing in Hong Kong for 20 years specializing mainly on areas of diabetes, cardiac rehabilitation, weight management and other obesity related conditions and also eating disorders.' +
      '<br>Currently, Sylvia is the Chairperson of the Professional Council of the Hong Kong Academy of Accredited Dietitians (HKAAD). She was the Chairperson of the Hong Kong Dietitians Association from 2007 to 2019 and the External Affair Officers from 2019 to 2021, actively promoting Hong Kong’s dietitian profession. She is also the country representative of Hong Kong of the International Confederation of Dietetics (ICD), and the council member of the Hong Kong Association of Sports Medicine and Sport Science (HKASMSS).' +
      '<br>She often provides nutrition seminars to local and international conferences, corporate companies, school and academic institutions. She is also a reputable spokesperson for the dietetic profession providing accurate and up-to-date nutrition education and information to the general public through newspapers, television and radio programs. Besides, she published more than 10 nutrition cookbooks including topics in sports nutrition, diabetes, heart diseases, weight management, nutrition support for cancer patients, vegetarianism, infant and child nutrition and more.',
  },
  AboutUs: {
    title: 'About Us',
    organizer:
      'InspiringHK Sports Foundation is a local charity established in 2012, with a vision of developing better youths through sports. Our mission is to promote social mobility, gender equality, social inclusion and healthy lifestyle through sports. We carry out our mission through long-term programme, medium-term projects and short-term healthy lifestyle activities. In 2019-2020, IHKSports has provided services to over 2,500 beneficiaries with not less than 46,000 hours of professional sports training. In addition, over 4,000 children and youth have benefitted from short-term healthy lifestyle activities.',
    about_organizer: 'About the Organiser',
    about_academy: 'About IHKSports Coaching for Youth Academy',
    paln_obj_title: 'Target Users',
    plan_tit1: '<a href="/#/coach-connect/coach-corner">I am a coach</a>',
    plan_tit2: '<a href="/#/coach-connect/school-corner">I am from schools, commercial and social welfare organisations</a>',
    plan_tit3: '<a href="/#/coach-connect/school-corner">I am a parent</a>',
    // plan_tit4: 'Youth Workers',
    // plan_tit5: 'Parents',
    plan_txt1: 'Regardless of full-time or part-time, professional or amateur, coaches from any sports are welcome to join the Academy.',
    plan_txt2:
      'Schools or non-governmental organisations staffs, all teachers from primary school, secondary school and special schools, and people who are in frequent contact with children and youths, can understand how to use sports to promote the development of children and youths.    ',
    plan_txt3: 'The Academy is suitable for all parents to help cultivate their children’s growth through sports.',
    // plan_txt4: 'People who are in frequent contact with children and youths, including youth workers in social and educational development, are welcome to join the academy.',
    // plan_txt5: 'This academy is suitable for all parents to use to help cultivate their children’s growth through sports.',
    plan_con:
      'The platform targets more than 1000 local full-time and part-time sports coaches and teachers, and social workers, youth workers and parents that are interested in bettering youth through sports.',
    project_title: 'Your Key Takeaways',
    /* project_effect_tit1: '成效1',
    project_effect_txt1:
      '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit2: '成效2',
    project_effect_txt2:
      '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit3: '成效3',
    project_effect_txt3:
      '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。',
    project_effect_tit4: '成效4',
    project_effect_txt4:
      '為凝動香港體育基金的體育造就青年教練培訓學院旗下的計劃。', */
    stage1_title: 'Inspiration',
    stage1_txt1: 'Jockey Club Community Sustainability Fund - SPORTS COACH Inspires!',
    stage1_txt2:
      'This phase provides users 7 e-learning units and more than 100 sports activities demonstration videos. This section not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.',
    stage2_title: 'Safeguarding',
    stage2_txt1: 'Child Safeguarding Learning Platform',
    stage2_txt2:
      'This phase is an e-learning course with five units, which mainly describes how to protect children at the sports level. It also focuses on risk management, common practices and corresponding methods when dealing with child abuse.',
    stage3_title: 'CoachConnect and CoachAction',
    stage3_txt1: 'CoachConnect and CoachAction',
    stage3_txt2:
      'This phase is an online coach matching platform, where the coaches can display their personal resumes. Platform users can find suitable coaches to teach sports through this platform. Coaches can also use the platform to find potential students, download teaching resources and have access to expert opinions.',
    results1_tit: 'SPORTS COACH Inspires!',
    results1_txt1: 'Gain FREE Access to seven units of online courses or resources',
    results1_txt2: 'Gain FREE Access to a Sports Activity Hub containing a huge archive of videos',
    results1_txt3: 'Understand the concept of "Better Youths, Through Sports",as well as values,character,ethics and morals in sports',
    results1_txt4: 'Improve communication and interaction skills among students of different backgrounds including special education needs, females and ethnic minorities',
    results1_txt5: 'Improve skills in sports activity planning',
    results2_tit: 'CoachConnect and CoachAction',
    results2_txt1: 'Gain FREE Access to five units of online courses or resources',
    results2_txt2: 'Understand local practices and related concepts about child abuse and child safeguarding',
    results2_txt3: 'Able to conduct risk assessment of child safeguarding in sports',
    results2_txt4: 'Able to respond to child abuse appropriately',
    results2_txt5: '',
    results3_tit: 'CoachConnect and CoachAction',
    results3_txt1: 'Gain FREE Access to the online matching platform',
    results3_txt2: 'Sport coaches can post personal resumes, exhibit their professional qualifications and experiences, and in turn get more teaching opportunities',
    results3_txt3: 'Service users like physical education teachers,parents, NGOs, and youth workers can find suitable coaches for their programmes',
    results3_txt4: `One-stop online coaching resources and experts' sharing`,
    results3_txt5: `Improve coaches' competitiveness`,
    results3_txt6: 'Share useful information on how to verify coaches',
    results3_txt7: 'Provide a safe and reliable local coach library',
    para_1:
      'At IHKSports Coaching for Youth Academy, we love hearing feedback from users—about our service, your experiences, as well as any other questions you may have. Please feel free to contact us by phone or email.    ',
    para_2: 'General Enquiry: 560 560 13    ',
    para_3: 'Email: ',
    para_4: 'academy@inspiringhk.org',
  },
  Inspire: {
    title: 'Inspiration',
    title_tip: 'Jockey Club Community Sustainability Fund - <br>SPORTS COACH Inspires!',
    content_one: 'Jockey Club Community Sustainability Fund - SPORTS COACH Inspires',
    content_two:
      ', organised by InspiringHK Sports Foundation and funded by The Hong Kong Jockey Club Charities Trust, is the first part of the IHKSports Coaching for Youth Academy. In this part, it provides users 7 online learning units and more than 100 sports activities demonstration videos. This section not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.',
    // click_look: 'Click below and view more details',
    txt1: 'Sports for Development' + '\n' + 'E-Learning Hub',
    txt2: 'Sports Activity Hub',
    more: 'Learn more',
    video_title: 'Inspiration: Jockey Club Community Sustainability Fund - SPORTS COACH Inspires!',
    download: 'Download Scheme Poster',
    download_short: 'Download Scheme Poster',
  },
  SportsFilms: {
    title: 'Inspiration',
    tip1: 'Sports Activity<br><span style="margin-left: 27%">Hub</span>',
    tip2: 'Jockey Club Community Sustainability Fund -' + '\n' + 'SPORTS COACH Inspires',
    content_one: 'The Sports Activity Hub ',
    content_two:
      'consists of a myriad of teaching resources planned, explained and demonstrated by professional coaches from various sports sectors to assist sport coaches and educators to gain access to demonstration videos and lesson notes ranging from on-the-spot physical activities to post-activity debriefing notes when doing lesson planning.',
    content_three:
      'Users can narrow down the type of videos they need by using filters in the “Advanced Search Engine” as shown below. A written summary is also provided along with the videos, stating the rules, procedures and suggested reflection questions for users to review the content of the videos easily.',
    sports_title: 'Sports Activity Hub',
    input_pla: 'Search for suitable sports activities',
    search_btn: 'Search',
    search_stage: 'Advanced Search',
    // tit1: "影片題目",
    browse_one: 'Views: ',
    browse_two: ' times',
    pop_title1: 'Most Searched',
    pop_title2: 'Highest rated',
    look_more: 'View All',
    search_all: 'Select All',
    search_title: 'Search Results',
    search_tit1: 'Types of sports:',
    search_tit2: 'Age:',
    search_tit3: 'Activity Objective:',
    search_tit4: 'Value:',
    search_tit5: 'Exertion Level:',
    search_tit6: 'Ability:',
    search_txt11: 'Traditional ball games',
    search_txt12: 'Combat sport',
    search_txt13: 'Fundamentals/Movements',
    search_txt14: 'Track and Field',
    search_txt15: 'Newly emerging ball games',
    search_txt16: 'Fitness and stretching',
    search_txt17: 'Racket sports',
    search_txt21: 'Junior Primary',
    search_txt22: 'Junior Secondary',
    search_txt23: ' ',
    search_txt24: ' ',
    search_txt25: 'Senior Primary',
    search_txt26: 'Senior Secondary',
    search_txt27: ' ',
    search_txt28: ' ',
    search_txt31: 'Ice-breaking',
    search_txt32: 'Warm-up',
    search_txt33: 'Main content',
    search_txt34: 'Coordination/Agility',
    search_txt35: 'Debriefing/Reflection',
    search_txt41: 'Sportsmanship',
    search_txt42: 'Emotional skills',
    search_txt43: 'Intellectual development ',
    search_txt44: 'Team skills',
    search_txt45: 'Physical health',
    search_txt51: 'Low intensity',
    search_txt52: 'Medium intensity',
    search_txt53: 'High intensity',
    search_txt61: 'Special education needs',
    search_txt62: 'Physical disability',
    search: 'Search',
    search_error_tit: 'Hint',
    search_error: 'not related videos found',
    search_success: 'Searching finished',
    no_login: 'Please log in first',
  },
  SportsFilmsDetail: {
    nav: 'Sports Activity Hub',
    seach: '#初中 #體育精神 #整理/反思 #特殊學習需要 #不放棄 #3分鐘',
    title: 'Activity name:',
    Activity_name: '捉不住的皮球',
    target: 'Objective:',
    target_value: '不斷嘗試捉回皮球接給主持人',
    play_title: 'Instruction:',
    play_content:
      '用雪糕筒畫出一個指定區域，選擇一位主持人站在中間;其他同學會站在不同方位輪流把球拋給主持人,主持人需要把球拋向其他方向，愈遠愈好，為自已爭取時間;讓拋球的同學跑去其他方位接回自已的球，重新再把球傳給主持人;當主持人未能應付多於一位同學拋回的球為輸',
    Activity_summary: 'Conclusion:',
    summary: '明白體育精神，即使疲倦也不放棄，重複行為訓練進度活躍同學耐性、滿足自閉症的需求',
    advise: 'Suggested reflective question:',
    tip: '回想活動期間，你有何感覺；過程中遇到甚麼困難？你以怎樣的態度應對',
  },
  OnlineCourses: {
    title: 'Inspiration',
    tip1: 'Sports for Development<br>E-learning Hub',
    tip2: 'Jockey Club Community Sustainability Fund - SPORTS COACH Inspires',
    content_one: 'Sports for Development E-learning Hub ',
    content_two:
      'is an online learning platform donated by The Hong Kong Jockey Club Charities Trust. At this stage, there are a total of seven units related to "Sports for Development". Each unit contains short videos, interactive learning resources, case studies and quizzes. Learners who have completed seven online learning courses and passed all the quizzes, will be issued a course completion certificate (10 total learning hours).',
    tip3: 'Sports for Development: Inspiration',
    tip4: 'Register now to unlock more courses!',
    img_item1: 'Unit 1',
    img_item1_list: 'Understanding Children and Youths',
    img_item1_txt1: '',
    img_item1_txt2: 'Total 1 hour',
    img_item2: 'Unit 2',
    img_item2_list: 'Introduction to Sports for Development (SFD) for Children and Youths',
    img_item3: 'Unit 3',
    img_item3_list: 'Values, Morals and Ethics in Sports',
    img_item4: 'Unit 4',
    img_item4_list: 'Challenges Deterring Girls and Women From Sport Participation and How to Tackle Them',
    img_item5: 'Unit 5',
    img_item5_list: 'Engaging Children and Youths with Special Educational Needs (SEN) in Sports',
    img_item6: 'Unit 6',
    img_item6_list: 'Engaging Ethnic Minorities Children and Youths in Sports',
    img_item7: 'Unit 7',
    img_item7_list: 'Sports and Mental Wellbeing',
    coming_soon: 'To be launched soon, stay tuned!',
  },
  Protection: {
    title: 'Safeguarding',
    tip1: 'Sports for Development<br>',
    tip2: 'Sports For Development - Child Safeguarding Learning Platform',
    content_one: '《凝動體育教練培訓》',
    content_two:
      'The ‘Sports for Development - Child Safeguarding Learning Platform’, the second part of the IHKSports Coaching for Youth Academy, is the first <b>sports-related online learning platform </b> to increase coaches and the general society’s understanding of ‘Child Safeguarding’ in Hong Kong. The platform is funded by the Funding Scheme for Children’s Well-being and Development of the Commission on Children.<br/><br/>There are a total of five units related to ‘Safeguarding Children in Sports’. Each unit contains short videos, interactive learning resources, case studies and quizzes. Learners who have completed five online learning courses and passed all the quizzes, will be issued a course completion certificate (5 total learning hours).<br/><br/>We are honoured to have Plan International Hong Kong to be our course consultant.',
    tip3: 'Sports for Development: Safeguarding',
    tip4: 'Register now to unlock more courses!',
    tip5: 'Sports For Development - Child Safeguarding Learning Platform Launch Ceremony and Seminar',
    img_item1: 'Unit 1',
    img_item1_list: 'What is Child Safeguarding? ',
    img_item1_txt1: '',
    img_item1_txt2: 'Total 1 hour',
    img_item2: 'Unit 2',
    img_item2_list: 'Basic Concepts, Causes and Effects of Child Abuse ',
    img_item3: 'Unit 3',
    img_item3_list: 'Child Safeguarding in Sports ',
    img_item4: 'Unit 4',
    img_item4_list: 'Identifying, Responding to and Reporting Child Abuse',
    img_item5: 'Unit 5',
    img_item5_list: 'Safeguarding Children as a System',
    coming_soon: 'To be launched soon, stay tuned!',
    seminar_text:
      "<br/>The event was successfully held on 16th December 2021 (Thursday) in the afternoon at Y Square in Chai Wan. Thank you for joining us!<br/><br/>The theme of the Seminar was ‘ <b style='color:#f15922;'>How Can We Work Together to Safeguard Children in Sports?</b> ’. The event focused on education, dialogue and brainstorming. Professional experts in child safeguarding and sports for development participated in a panel discussion and learning sessions. They shared their experience and inspired the public on the significance of preventing child abuse in the sports industry.<br/><br/>Meanwhile, IHKSports launched the ‘Sports For Development - Child Safeguarding Learning Platform’ - the first and foremost online learning platform regarding child safeguarding in sports in Hong Kong. Participants of the event were given the priority to pre-register the course.<br/><br/>Please <a style='color:#16287a;text-decoration:underline;' target='_blank' href='https://drive.google.com/drive/folders/1fTfkLzw1fesUTp7vZCBiymaZ-NQGM1Jh?usp=sharing'>click here</a> for the event highlights!",
    login_title: 'Not Login',
    login_text: 'Please login or register for the course',
    login_ok: 'OK',
  },
  Register: {
    title: 'Register Now to Enhance Yourself！',
    txt: 'After registration, members can access to online learning units, coaching resources, coaches profiles and coaching opportunities search engines.    ',
    information: 'Member Information',
    name_cn: 'Chinese Name',
    name_en: 'English Name',
    password: 'Password',
    repassword: 'Confirm Password',
    email_address: 'Email',
    age: 'Age',
    bir: 'Date Of Birth',
    sex: 'Gender',
    area_code: '電話區號',
    mobile: 'Contact Number',
    address: 'Address',
    confirm_address:
      '* Individuals who register before 31 July 2021 will receive a gift coupon with the value of HK$50. For those who complete and pass the assessment of at least one of the learning units before the same deadline will receive an additional HK$50 gift coupon. If you wish to receive the gift coupon, please provide your full name and correct communication number and address. The gift coupons will be distributed after 31 July 2021.',
    sex_m: 'Male',
    sex_w: 'Female',
    lastname_cn: '姓',
    firstname_cn: '名',
    lastname_en: 'Last Name',
    firstname_en: 'First Name',
    identity: 'Which one(s) are you?',
    identity_login: '我的身份',
    identity_coach: 'Sports Coach',
    identity_teacher: 'Physical Education Teacher',
    identity_social: 'Social Worker',
    identity_yang: 'Youth Worker',
    identity_parents: 'Parent',
    identity_stu: 'Student',
    identity_other: 'Other(s)',
    interested_content: 'Which of the following content are you interested in?',
    e_learning_unit: 'E-learning Units',
    interested_safeguarding: 'Safeguarding Child in Sports Setting',
    interested_coach_connection: 'Coach Connection Platform',
    sports_demonstration_film: 'Demonstration videos of sports activities',
    education_level: 'Level of Education',
    primary: 'No schooling completed/ Kindergarten/ Primary School',
    junior: 'Middle School',
    high: 'High School/ Tertiary Education',
    other_education_level: 'Others',
    main_clients: 'Which of the following group(s) is/are your target service/coaching group?',
    kindergarten: 'Kindergarten/ Kindergarten students',
    primary_student: 'Primary school students',
    secondary_student: 'Secondary school students',
    university: 'Tertiary Students',
    adult: 'Adults',
    elderly: 'Elderly',
    not_applicable: 'Not Applicable',
    other_min_client: '其他服务对象',
    edu_primary: 'Primary education or below',
    edu_secondary: 'Secondary education',
    edu_higher_diploma: "Higher diploma/Associate's degree",
    edu_bachelor: "Bachelor's degree",
    edu_master: "Master's degree",
    edu_doctor: 'Doctors degree',
    edu_other: 'Other(s): (Please specify)',
    way_website: 'IHKSports Website',
    way_facebook: 'IHKSports Facebook',
    way_instagram: 'IHKSports Instagram',
    way_newsletter: 'IHKSports Newsletter',
    way_staff: 'IHKSports Staff Referral',
    way_whatsapp: 'WhatsApp Message',
    way_friends: 'Shared by friends',
    way_poster: 'Poster',
    way_other: 'Other means: (Please specify)',
    question1: 'What is your expectation towards IHKSports Coaching for Youth Academy?',
    question2: 'How did you find out about IHKSports Coaching for Youth Academy?',
    checkbox_search: 'Search Engine',
    error_name: 'Please enter Chinese for Chinese name, English for English name',
    checkbox_friend: 'Word of Mouth',
    checkbox_social: 'Social Media',
    checkbox_magazine: 'Blog or Newspaper',
    checkbox_other: 'Other(s):',
    agree: 'I have read and agree to accept the terms' + '\n' + 'and conditions of use and privacy policy' + '\n' + 'of <IHKSports Coaching for Youth Academy>.',
    subscribe: 'I DO NOT wish to receive the latest news of the IHKSports Coaching for Youth Academy.',
    return: 'Back',
    continue: 'Continue',
    commit: 'Submit',
    sex_other: 'Not Disclose',
    other: 'other',
    prompt: 'Please Enter',
    prompt_age: 'Select Age',
    prompt_data: 'Select Date',
    avatar: '設定／更換個人頭像',
    account_information: 'Account name',
    Registration_email: 'Email',
    my_identity: 'My Identity',
    my_Accounts: 'My Account',
    birday: 'Birthday',
    storage: 'Save',
    page: 'Page: ',
    success: 'Registration successful',
    declare1: 'It is hereby DECLARED, UNDERSTOOD AND AGREED that:',
    declare2:
      '1. I hereby acknowledge that any personal data I provided will be used for the purposes related to the participation in IHKSports Coaching for Youth Academy for the issuance of receipts, collecting user feedback, conducting analyses, and any other initiatives in relation to the aims and objectives of the foundation. I have the right to request access to and correction of my personal data. All information given in the application form and the attached documents are to the best of my knowledge, accurate and complete. For inquiries or updating your personal data, please contact us at info@inspiringhk.org .',
    declare3:
      '2. I hereby authorise the referrer above to obtain from me any updated information and / or any additional information that are reasonably required by your organisation and to provide the same to InspiringHK Sports Foundation. Such information may be processed by your organisation in the same manner as mentioned above in this form.',
  },
  MemberZone: {
    DeleteButton: 'Delete',
    record: 'My Submission',
    upload_vol: 'Upload My Voluntary Record',
    records: 'My Submission',
    not_started: 'Not started',
    started: 'Started',
    progressed: 'Progressed',
    passed: 'Passed',
    stu_title: 'My Study Progress',
    stu_done: 'Finished Courses',
    studying: 'In-progress Courses',
    award: '獲頒發的課程',
    stu_name: 'Course Name',
    achievement: 'Results',
    stu_feedback: '課程反饋',
    upload_voluntary: 'My Voluntary Record',
    stu_record: 'My Study Record',
    saved: 'Saved',
    my_coach: 'My Coach Profile',
    my_created: 'Created / Applied Coaching Opportunities',
    inspiration: '"Inspiration"',
    safeguarding: '"Safeguarding"',
    please_provide: 'Please provide details for your voluntary record：',
    event_name: 'Event Name：',
    organisation: 'Organiser',
    contact_person: 'Contact Person:',
    organiser_conact: 'Organiser Contact:',
    email: 'Organiser Email (for verification):',
    date: 'Date',
    time_start: 'Start Time：',
    time_end: 'End Time：',
    location: 'Location',
    upload_photo: 'Please provide your completion certificate of voluntary record and event photos (in jpg or pdf format)',
    make_sure:
      'Please make sure the information provided is correct. Email will be automatically sent to the above email address for verification. The above organisation needs to verify your voluntary record.',
    submit: 'Submit',
    uplpoad: 'Upload',
    go_push: 'Link to My Coach Profile Page',
    saved_coaches: 'Saved Coaches',
    saved_opportunities: 'Saved Coaching opportunities',
    state: 'State',
    created_opportunities: 'Created Coaching Opportunities',
    index: 'Job Card',
    copy: 'Copy',
    edit: 'Edit',
    view: 'View',
    event_info: 'Event Name',
    coach_details: 'Coach Details',
    profile: 'Profile',
    Contact: 'Contact',
    Phone: 'Phone',
    Email: 'Email',
    confirm_connection: 'Confirm Connection',
    confirm: 'Confirm',
    reject: 'Reject',
    post_date: 'Post Date',
    last_edited: 'Last Edited',
    applied_opportunities: 'Applied Coaching Opportunities',
    Status: 'Status',
    wait: 'Waiting for Organiser for further discussion',
    connection: 'Connection Confirmed by Organiser',
    connection_successed: 'Connection Successed',
    rejected: 'Rejected by Organiser',
    w: 'Waiting for Response',
    confirmed: 'Confirmed',
    c: 'Accepted Invitation',
    r: 'Rejected Invitation',
    a: 'Confirmed',
    c1: 'Accepted Invitation',
    notification: 'Notification',
    received_invitation: 'Received Coaching Opportunities Invitation',
    my_invitation: 'My Invitation to Coaches',
    response: 'Response',
    accept: 'Accept',
    coach_name: 'Coach Name',
    view_voach: 'View Coach Profiles',
    cert: 'Download Certification',
    cert_top_cn: '茲證明',
    cert_top: 'This is to certify that',
    cert_txt1_cn: '已完成下列課程',
    cert_txt1: 'has successfully completed the course of',
    cert_txt2_cn: '賽馬會社區持續抗逆基金 凝動體育教練培訓',
    cert_txt2: 'Jockey Club Community Sustainability Fund - SPORTS COACH Inspire',
    cert_txt3: '(共10小時 hours)',
    safe_top_cn: '茲證明',
    safe_top: 'This is to certify that',
    safe_txt1_cn: '已完成下列課程',
    safe_txt1: 'has successfully completed the course of',
    safe_txt2_cn: '凝動守護兒童體育學習平台',
    safe_txt2: 'Sports for Development - Child Safeguarding Learning Platform',
    safe_txt3: '(共5小時 hours)',
    safe_txt4: '',
    notif:
      'An automatic email has sent to the provided email address for verification. In order to speed up the review process, you are advised to remind the person in charge to check the email and verify. Once confirmed, the Academy will review the record for final approval. You can also check the submitted records status in the member zone. Should you have any queries, please email academy@inspiringhk.org.',
    beneficiaries: 'Number of Beneficiaries',
    volunteer_encourage_1: 'After creating your coach profile, please upload at least 3-hour voluntary/ learning record within 6 months to show your support to our community!',
    volunteer_encourage_2: 'The first 250 users who upload 3-hour voluntary/ learning record in the member zone before 31 Oct 2022 will receive $100 coupon as incentive.',
    volunteer_desc: 'What could be counted as "Voluntary / Learning Record"？',
    volunteer_list_1: 'Event / Competition Helper',
    volunteer_list_2: 'Sports Ambassador ',
    volunteer_list_3: 'Team Assistant',
    volunteer_list_4: 'Seminar',
    volunteer_list_5: 'Learning Courses',
    volunteer_example_1: 'Coach Chan served as a badminton team consultant for a secondary school, with 30 underprivileged students benefited every year.',
    volunteer_example_2: 'Coach Chan attended a special lecture on "Prevention of Sports Injuries in Youths", about 100 underprivileged students will be benefited.',
    volunteer_example_3: 'Coach Chan volunteered to teach badminton in a promotion day for his badminton club, benefiting 100 underprivileged students.',
    volunteer_example_4: 'Coach Chan voluntarily led 15 students to participate in the badminton competition, and evaluated with students after the competition.',
    volunteer_mark_a: 'Example A',
    volunteer_mark_b: 'Example B',
    volunteer_mark_c: 'Example C',
    volunteer_mark_d: 'Example D',
  },
  Partners: {
    contribution_title: 'Funders',
    contribution_txt: 'The following partners provide donations to support IHKSports Coaching for Youth Academy. We are most grateful for their generous support.',
    contribution_jocky: '[ The Hong Kong Jockey Club Charities Trust ]',
    contribution_commission: '[ Commission on Children ]',
    contribution_swire: '[ Swire Trust ]',
    unit1: 'Unit 1: Understanding Children and Youths',
    unit2: 'Unit 2: Introduction to Sports for Development (SFD) for Children and Youths',
    unit3: 'Unit 3: Values, Morals and Ethics in Sports',
    unit4: 'Unit 4: Challenges Deterring Girls and Women From Sport Participation and How to Tackle Them',
    unit5: 'Unit 5: Engaging Children and Youths with Special Educational Needs (SEN) in Sports',
    unit6: 'Unit 6: Engaging Ethnic Minorities Children and Youths in Sports',
    unit7: 'Unit 7: Sports and Mental Wellbeing',
    adviser: 'Course Consultants',
    adviser_txt: 'We are very grateful for the following professionals/ partners who provide consulting service to our e-learning units:',
    advisers_name: 'Dr. TSANG K.M. Sandra',
    advisers_en: '',
    advisers_work: 'Associate Professor, Department of Social Work and Social Administration, The University of Hong Kong',
    advisers1_name: 'Dr. Trisha Leahy',
    advisers1_en: 'PhD, JD, SBS, BBS',
    advisers1_work: 'Chief Executive of the Hong Kong Sports Institute',
    advisers2_name: 'Prof. Chung Pak Kwong, JP',
    advisers2_en: '',
    advisers2_work: 'Associate Dean (Development), Faculty of Social Sciences, Hong Kong Baptist University',
    advisers3_name: 'Prof. Cindy Hui-Ping Sit',
    adviser3_en: '',
    advisers3_work: 'Associate Dean (Undergraduate Studies), Faculty of Education, The Chinese University of Hong Kong',
    advisers4_name: 'Prof. Cindy Hui-Ping Sit',
    advisers4_en: '',
    advisers4_work: 'Chairperson and Professor, Department of Sports Science and Physical Education, The Chinese University of Hong Kong',
    advisers5_name: 'Dr. Louie Hung Tak',
    advisers5_en: '',
    advisers5_work: 'Associate Head of Department and Senior Lecturer, Department of Health and Physical Education (HPE), The Education University of Hong Kong ',
    advisers6_name: 'Dr. Michael Tse',
    advisers6_en: '',
    advisers6_work: 'Director of the Centre for Sports and Exercise',
    advisers7_name: 'Ms. Anna Chan',
    advisers7_en: '',
    advisers7_work: 'Training Consultant / Counselling Psychologist, HKJC Centre for Suicide Research and Prevention, The University of Hong Kong',
    coach_companion: 'Sports Activity Hub',
    coach_units: 'Online Resources Collaboration Partners',
    coach2_units: 'Sports Activity Hub',
    coach3_units: 'Coaching Resources Collaboration Partners',
    coach4_units: 'Online Experts Sharing Collaboration Partners',
    coach_txt: 'We are very grateful for the following individuals and organizations who have contributed for Sports Activity Hub and Coaching Resources (in no particular order):',
    coach4_txt: 'Hong Kong Association of Sports\nMedicine and Sports Science',
    coach5_txt: 'AASFP',
    coach1: 'Mr Woody Yip - ',
    coach1_work: 'Professional karate coach',
    coach2: 'Mr Ham Kwok Kuen - ',
    coach2_work: 'Professional korfball coach',
    coach3: 'Mr Edwin Wong (Sports Hunter) - ',
    coach3_work: 'Professional dodgebee coach',
    coach4: 'Mr Oscar Ng - ',
    coach4_work: 'Professional fitness coach',
    resources_1: 'Department of Sports Science and Physical Education, The Chinese University of Hong Kong',
    resources_2: 'Prof. Cindy Hui-Ping SIT - Chairperson and Professor, Department of Sports Science and Physical Education, The Chinese University of Hong Kong',
    resources_3: 'Dr. John O’REILLY - Lecturer, Department of Sports Science and Physical Education, The Chinese University of Hong Kong',
    resources_4: 'Dr. SINEAD SHERIDAN - Part-time Lecturer, Department of Sports Science and Physical Education, The Chinese University of Hong Kong',
    venue_sponsor: 'Venue Sponsor',
    venue_sponsor_name: "[ People's Place ]",
    restaurant_title: 'Partner in F&B',
    restaurant_txt:
      'InspiringHK Sports Foundation has a vision to empower "better youths, through sports". Apart from cooperating with the art sector, IHKSports also takes the initiative to work with the F&B industry in the territory. In 2021, we are grateful to partner F&B Run Club and contribute to the common good of the society, especially for the local underprivileged families in the F&B industry.',
    fb_tit: 'F&B Run Club',
    fb_txt1:
      'F&B Run Club is a network of proud individuals working within the food & beverage industry and are on a mission to raise money for worthy charities. At the same time they believe in integrating wellness, self-betterment, health, together with social responsibilities and community building.',
    fb_txt2:
      'In these devastating times for the F&B industry, F&B RUN CLUB just wants to infuse their community with positivity, and help those who have been impacted the hardest, channel energy into a worthy cause collectively as an industry, help others in need – and have a good time along the way! In 2021, the mission of the F&B Run Club is to collectively accumulate 384,400 km to reach the moon before 31st December 2021. People will be allowed to walk, hike, run to tally kms.',
    website: 'Website',
    act_title: 'Inaugural Campaign:' + '\n' + 'Run To the Moon for Underprivileged F&B Families',
    act_txt:
      'Besides running 384,400km, F&B RUN CLUB partners with InspiringHK Sports Foundation to raise HK $384,400 from June 1st, 2021 till September 1st, 2021, aiming to raise two one-year long run classes (40 sessions each) led by professional coaches with sports gears provided for the local underprivileged youths, benefiting at least 40 students whose parents are working in the F&B industry, contributing to 2,800 sporting hours, empowering better youths through sports.',
    table_title1: 'Inspiration',
    top_details1: 'Jockey Club Community Sustainability Fund - SPORTS COACH Inspires!',
    table_title2: 'Safeguarding',
    top_details2: 'Sports For Development - Child Safeguarding Learning Platform',
    table_title3: 'Connection & Action',
    top_details3: 'CoachConnect & CoachAction',
    blue_title: 'Jockey Club Community Sustainability Fund - ',
    blue2_title: ' SPORTS COACH Inspires!',
    box_text: 'Sports For Development - Child Safeguarding Learning Platform',
  },
  Comingsoon: {
    coming_soon: 'Coming Soon! Stay Tuned',
  },
  Footer: {
    organizer: 'Organized by',
    donor: 'Funded by',
    hkjcct_logo_url: '../assets/images/hkjcct_en.png',
    signup: 'Sign up now!',
  },
  Header: {
    google: 'Continue to log in with Google',
    linkedin: 'Continue to log in with Linkedin',
    facebook: 'Continue to log in with Facebook',
  },
  Authorize: {
    title: 'Login Authorization',
    text: 'Grant the access right to this site?',
    yes: 'Yes',
    no: 'No',
  },
  CoachCorner: {
    create: 'Create Coach Profile',
    browse: 'Browse Coaching Opportunities',
    description1: 'Build your own professional coach profiles, with academic qualifications, past coaching experiences, photo references, etc., and strive for more coaching opportunities',
    description2: 'Discover the latest paid and volunteer coaching opportunities to teach sports skills and convey sportsmanship',
    online: 'Online Learning Platform',
    practical: 'Practical Tools',
    tit_1: 'Sports for Development E-Learning Hub',
    tit_2: 'Child Safeguarding Learning Platform',
    desc1:
      'With 7 online learning units and more than 100 sports activities demonstration videos, the E-learning Hub not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.    ',
    desc2: 'To increase coaches and the general society’s understanding of ‘Child Safeguarding’ in Hong Kong.     ',
    desc3: 'Provide online practical teaching resources such as annual teaching plans, lesson plans, goal setting worksheets and teaching guide cards, etc.    ',
    desc4: 'Provide downloadable one-stop online coaching resources and online experts’ sharing to improve teaching quality and professional level.    ',
    link1: 'Coaching Resources    ',
    link2: 'Online Experts’ Sharing    ',
    link3: 'Sports Activity Hub    ',
    title1: `Coaches' Corner`,
  },
  SchoolCorner: {
    post: 'Post Coaching Opportunities',
    explore: 'Explore Coach Profiles',
    description1: 'Post your group activity details and start searching for a suitable coach!',
    description2: 'Easily view detailed coach profiles for all types of sports and invite suitable coaches to apply for your coaching opportunities',
    online: 'Online Learning Platform',
    verify: 'How to Verify a Coach    ',
    tit_1: 'Sports for Development E-Learning Hub',
    tit_2: 'Child Safeguarding Learning Platform',
    desc1:
      'With 7 online learning units and more than 100 sports activities demonstration videos, the E-learning Hub not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.    ',
    desc2: 'To increase coaches and the general society’s understanding of ‘Child Safeguarding’ in Hong Kong.     ',
    desc3: 'With lots of coach profiles, how can I find a safe and reliable coach?    ',
    desc4: 'IHKSports Coaching for Youth Academy shares the tips on how to verify a coach!',
    title1: `Schools, Commercial and Social Welfare Organisations’ Corner    `,
    box1: 'Study Now',
    box2: 'Study Now',
    box3: 'Learn More',
  },
  ParentsCorner: {
    post: 'Post Coaching Opportunities',
    explore: 'Explore Coach Profiles',
    description1: 'Post your group activity details and start searching for a suitable coach!',
    description2: 'Easily view detailed coach profiles for all types of sports and invite suitable coaches to apply for your coaching opportunities',
    online: 'Online Learning Platform',
    verify: 'How to Verify a Coach    ',
    tit_1: 'Sports for Development E-Learning Hub',
    tit_2: 'Child Safeguarding Learning Platform',
    desc1:
      'With 7 online learning units and more than 100 sports activities demonstration videos, the E-learning Hub not only equipped users with professional knowledge on better youth through sports, but also stimulated their creativity to insert more positive values into children and youth’s personal development.    ',
    desc2: 'To increase coaches and the general society’s understanding of ‘Child Safeguarding’ in Hong Kong.     ',
    desc3: 'With lots of coach profiles, how can I find a safe and reliable coach?    ',
    desc4: 'IHKSports Coaching for Youth Academy shares the tips on how to verify a coach!',
    title1: `Parents' Corner    `,
    box1: 'Study Now',
    box2: 'Study Now',
    box3: 'Learn More',
  },
  ...enLocale,
}
export default en
