<template>
  <div class="footer-container">
    <!-- <div
      class="footer-upper"
      v-if="
        $route.name == 'Inspire' ||
          $route.name == 'OnlineCourses' ||
          $route.name == 'SportsFilms'
      "
    >
      <div class="logo-container">
        <div class="logo-title">
          <strong>{{ $t('Footer.organizer') }}</strong>
        </div>
        <div class="logo-content">
          <div class="logo">
            <a href="https://inspiringhk.org/" target="_blank">
              <img src="../assets/images/ihk-logo.png" />
            </a>
          </div>
        </div>
      </div>
      <div class="logo-container">
        <div class="logo-title">
          <strong>{{ $t('Footer.donor') }}</strong>
        </div>
        <div class="logo-content">
          <div class="logo">
            <a :href="$t('Home.jockey_club_url')" target="_blank">
              <img
                v-if="$i18n.locale == 'en'"
                style="max-width: 350px;"
                src="../assets/images/hkjcct_en.png"
              />
              <img
                v-else
                style="max-width: 350px;"
                src="../assets/images/hkjcct_cn.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div> -->

    <div class="footer-bg">
      <div class="footer-logo-group">
        <div class="logo logo-ihk">
          <img class="logo-img" src="../assets/images/ihk-logo.png" alt="" />
        </div>
        <div v-if="path.indexOf('/coach-connect') > -1 && $i18n.locale == 'en'" class="footer-logo">
          <img class="logo-img" src="https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-en.png" alt="" />
        </div>
        <div v-if="path.indexOf('/coach-action') > -1 && $i18n.locale == 'en'" class="footer-logo">
          <img class="logo-img" src="https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-en.png" alt="" />
        </div>
        <div v-if="path.indexOf('/coach-connect') > -1 && $i18n.locale == 'cn'" class="footer-logo">
          <img class="logo-img" src="https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-cn.png" alt="" />
        </div>
        <div v-if="path.indexOf('/coach-action') > -1 && $i18n.locale == 'cn'" class="footer-logo">
          <img class="logo-img" src="https://df7ocie544p6c.cloudfront.net/images/05-12-22-footer-cn.png" alt="" />
        </div>
        <div class="footer-logo" v-if="path == '/inspire' || path == '/inspire/online-courses'">
          <img class="logo-img" src="../assets/images/hkjcct_logo2.png" alt="" />
        </div>
        <div class="footer-logo" v-if="path.indexOf('/inspire/sports-films') > -1">
          <img class="logo-img" src="../assets/images/hkjcct_logo2.png" alt="" />
        </div>
        <div v-if="path == '/safeguarding'">
          <!-- <img
            class="coc-logo"
            src="../assets/images/Plan 國際培幼會 Logo_RGB.jpg"
            alt=""
            style="margin-right: 40px"
          /> -->
        </div>
        <div v-if="path == '/safeguarding'">
          <img class="coc-logo" src="../assets/images/comission on children logo.jpg" alt="" />
        </div>
      </div>
      <div class="icons-txt">
        <!-- <div class="icons">
          <a href="https://www.facebook.com/ihksports/" target="_blank"></a>
          <a href="https://www.instagram.com/ihksports/" target="_blank"></a>
          <a
            href="https://www.linkedin.com/company-beta/3766637/"
            target="_blank"
          ></a>
          <a
            href="https://www.youtube.com/channel/UCaUTwxuFhaqfLTmnreBFjrg"
            target="_blank"
          ></a>
          <a href="mailto:academy@inspiringhk.org" target="_blank"></a>
          <a href="https://donation.inspiringhk.org/" target="_blank"></a>
        </div> -->
        <div v-if="screenWidth > 480" class="txt">
          <span @click="$router.push('/copyright')">Copyright</span>
          <span @click="$router.push('/privacy')">Privacy Policy</span>
          <span @click="$router.push('/term-of-use')">Term of Use</span>
        </div>
        <p v-if="screenWidth > 480" class="copyright">Copyright © 2022 InspiringHK Sports Foundation. All rights reserved.</p>
        <p v-else class="copyright">
          Copyright©2022 InspiringHK Sports Foundation.<br />
          All rights reserved.
        </p>
      </div>
      <!-- <div class="input-phone">
        <div class="input-txt" v-if="!loggedIn">
          <input
            ref="email"
            value=""
            class="input"
            type="text"
            placeholder="Your Email"
            v-model="email"
          />
          <button @click="signUp" class="sub">{{ $t('Footer.signup') }}</button>
        </div>
        <div v-if="screenWidth > 480" class="phone-item">
          <p class="phone">+852 560 560 13</p>
          <p>academy@inspiringhk.org</p>
        </div>
        <div v-else class="icons-txt">
          <div class="txt">
            <span class="phone">+852 5605 6013</span>
            <span>academy@inspiringhk.org</span>
          </div>
          <div class="txt">
            <span @click="$router.push('/copyright')">Copyright</span>
            <span @click="$router.push('/privacy')">Privacy Policy</span>
            <span @click="$router.push('/term-of-use')">Term of Use</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFooter',
  data() {
    return {
      screenWidth: window.document.body.clientWidth,
      email: '',
      loggedIn: false,
      path: '',
    }
  },
  watch: {
    $route: 'getPath',
  },
  created() {
    this.checkLogin()
    this.getPath()
  },
  methods: {
    getPath() {
      this.path = this.$route.path
    },
    signUp() {
      let email = this.email
      this.email = ''
      this.$router.push({
        name: 'Message',
        query: {
          email: email,
        },
      })
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      this.loggedIn = token == null ? false : true
    },
    goPush(page, i) {
      this.mobileDrawer = false
      let str = window.location.href
      let index = str.lastIndexOf('#/')
      if (page === str.substring(index + 1, str.length)) {
        return
      }
      this.current = i
      // window.localStorage.setItem("navActive", 0);
      this.$router.push(page)
    },
  },
}
</script>
<style scoped lang="scss">
.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 462px;
}
.footer-logo-group {
  display: flex;
  .logo-ihk {
    width: 271px;
    height: 150px;
    margin-left: 0;
    // background: url('../assets/images/ihk-logo.png') no-repeat 95%/95%;
    cursor: pointer;
    .logo-img {
      width: 100%;
    }
  }
  .footer-logo {
    width: 488px;
    height: 174px;
    background: #ffffff;
    // background: url('../assets/img/1-Box-Images/footer.jpg') no-repeat 100%/100%;
    .logo-img {
      width: 100%;
      margin-top: 50px;
    }
  }
  .coc-logo {
    width: auto;
    height: 168px;
  }
}

@media screen and (min-width: 481px) {
  .footer-upper {
    display: flex;
    justify-content: center;
    font-size: 24px;
  }
  .logo-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-top: 20px;
    .logo-title {
      position: relative;
      display: flex;
    }
    .logo-content {
      padding-left: 50px;
      display: flex;
      flex-direction: row;
      .logo {
        margin-right: 10px;
        padding-top: 30px;
        display: flex;
        a img {
          max-height: 120px;
        }
      }
    }
  }
}

.footer-bg {
  .footer-logo-group {
    margin: 150px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // display: flex;
  // align-items: center;
  position: relative;
  letter-spacing: 0;
  // font-family: 'Exo2-Regular';
  font-size: 14px;
  height: 218px;
  .logo {
    width: 271px;
    height: 150px;
    margin-right: 110px;
  }
  .phone-row {
    font-size: 20px;
    color: #333;
  }
  .icons-txt {
    // position: absolute;
    // top: 32%;
    // left: 50%;
    // transform: translateX(-50%);
    // width: 40%;
    // margin: 0px auto;
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    .icons {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0px auto;
      width: 55%;
      height: 50px;

      a {
        display: inline-block;
        width: 30px;
        height: 30px;
      }
      a:nth-child(1) {
        background: url('../assets/images/svg/icon-fb.svg') no-repeat 100%/100%;
      }
      a:nth-child(2) {
        background: url('../assets/images/svg/icon-ig.svg') no-repeat 100%/100%;
      }
      a:nth-child(3) {
        background: url('../assets/images/svg/icon-linkedin.svg') no-repeat 100%/100%;
      }
      a:nth-child(4) {
        width: 32px;
        background: url('../assets/images/svg/icon-youtube.svg') no-repeat 100%/100%;
      }
      a:nth-child(5) {
        width: 32px;
        background: url('../assets/images/svg/icon-mail.svg') no-repeat 100%/105%;
      }
      // a:nth-child(6) {
      //   // width: 20px;
      //   background: url('../assets/images/svg/icon-map.svg') no-repeat 100%/100%
      //     100%;
      // }
    }
    .txt {
      width: 100%;
      font-size: 12px;
      margin-top: 10px;
      span {
        padding: 0px 40px;
        display: flex-inline;
        height: 18px;
        align-items: center;
        border-right: 1px solid #333333;
        cursor: pointer;
      }
      span:last-child {
        border-right: 0;
      }
    }
    .copyright {
      margin: 6px auto;
      font-size: 14px;
    }
  }
  .input-phone {
    position: absolute;
    top: 32%;
    right: 3%;
    text-align: right;
    .input-txt {
      display: flex;
      align-items: center;
      justify-content: space-around;
      height: 32px;
      margin-bottom: 20px;
      border-radius: 5px;
      border: 1px solid #f15922;
      overflow: hidden;
      .input {
        height: 100%;
        font-size: 19px;
        color: #808080;
        padding-left: 20px;
        border: none;
        border-radius: 5px 0 0 5px;
        &:focus {
          outline: none;
        }
      }
      .sub {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #fff;
        border: none;
        padding: 3px 10px;
        background: #f15922;
        cursor: pointer;
        font-family: 'Helvetica Neue', Helvetica;
      }
    }
    .phone-item {
      font-size: 20px;
    }
  }
}
@media screen and (max-width: 480px) {
  .footer-upper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .logo-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    .logo-title {
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 26px;
    }
    .logo-content {
      display: flex;
      flex-direction: column;
      .logo {
        justify-content: center;
        padding: 30px 0;
        display: flex;
        a img {
          max-height: 90px;
        }
      }
    }
  }
  .footer-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 70px 0;
    .icons-txt {
      position: relative;
      top: 0;
      left: 0;
      order: 2;
      width: 100%;
      margin-left: 0;
      transform: none;
      .icons {
        width: 45%;
      }
      .copyright {
        font-size: 14px;
        color: #333;
        margin-top: 50px;
      }
    }
    .input-phone {
      top: 0;
      right: 0;
      order: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-bottom: 60px;
      border-radius: 6px;
      .icons-txt {
        font-size: 28px;
        color: #333;
        white-space: nowrap;
        .txt {
          font-size: 18px;
          text-align: center;
          margin-top: 44px;
        }
      }
      .input-txt {
        width: 520px;
        height: 48px;
        margin-bottom: 20px;
        white-space: nowrap;
        .input,
        .sub {
          height: 48px;
        }
        .input {
          font-size: 26px;
          color: #808080;
          padding: 0 10px 0 28px;
        }
        .sub {
          font-size: 26px;
        }
      }
    }
  }
  .footer-logo-group {
    // flex-direction: column;
    margin: 0 !important;
    .logo-ihk {
      width: 200px;
      margin-right: 10px;
    }
    .coc-logo {
      width: 200px;
      height: auto;
      margin-right: 10px !important;
    }
  }
}
</style>
