<template>
  <div class="header-container" ref="header">
    <div
      class="logo logo-ihk"
      @click="goPush('https://inspiringhk.org/', 'ihk')"
    ></div>
    <div class="logo logo-cfya" @click="goPush('/', '0')"></div>
    <div
      class="login-lang"
      :class="loggedIn ? 'lang-right' : ''"
      v-if="screenWidth > 680"
    >
      <div class="login-box" @click="loginMemberInfo">
        <img v-if="!loggedIn" src="../assets/images/svg/Group 739.svg" alt="" />
        <div class="login-icon" v-if="!loggedIn">
          {{ $t('Home.login') }}
        </div>
        <el-dropdown v-else @command="exitMember" placement="bottom">
          <div>
            <span>{{ welcomeMsg }}</span>
            <i
              v-show="welcomeMsg"
              class="el-icon-arrow-down el-icon--right"
            ></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a" :disabled="loggedIn ? false : true">{{
              $t('Log_out')
            }}</el-dropdown-item>
            <el-dropdown-item
              command="b"
              :disabled="loggedIn && thirdParty == 'false' ? false : true"
            >
              {{ $t('Home.change_password') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="lang-box" @click="changLang">
        <img src="../assets/images/svg/Group 740.svg" alt="" />
        <!-- <i class="lang"></i> -->
        <div class="lang-icon">{{ $t('Home.lang') }}</div>
      </div>
    </div>
    <div class="nav-con">
      <div class="nav-list" v-if="screenWidth > 680">
        <p
          class="nav-item"
          v-for="(item, index) in navList"
          :key="index"
          @click="goPush(item, index)"
          :class="[index == current ? 'nav-active' : '']"
        >
          <span v-if="index === 2">
            <el-dropdown
              placement="bottom"
              size="medium"
              @command="handleCommand"
              style="color: #666666"
            >
              <span
                :class="[current == 2 ? 'nav-active' : '']"
                class="el-dropdown-link"
              >
                {{ $t('Home.nav' + index) }}
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                <el-dropdown-item
                  style="color: #666666"
                  command="/inspire/online-courses"
                  >{{ $t('Home.nav2_1') }}</el-dropdown-item
                >
                <el-dropdown-item
                  style="color: #666666"
                  command="/inspire/sports-films"
                  >{{ $t('Home.nav10') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span v-else-if="index === 4">
            <el-dropdown
              placement="bottom"
              size="medium"
              @command="handleCommand"
              style="color: #666666"
            >
              <span
                :class="[current == 4 ? 'nav-active' : '']"
                class="el-dropdown-link"
              >
                {{ $t('Home.nav' + index) }}
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                 <el-dropdown-item
                  style="color: #666666"
                  command="/corner/coach-corner"
                  >{{ $t('Nav.CoachCorner') }}</el-dropdown-item
                >
                 <el-dropdown-item
                  style="color: #666666"
                  command="/corner/school-corner"
                  >{{ $t('Nav.SchoolCorner') }}</el-dropdown-item
                >
                 <el-dropdown-item
                  style="color: #666666"
                  command="/corner/parents-corner"
                  >{{ $t('Nav.ParentsCorner') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span v-else-if="index === 5">
            <el-dropdown
              placement="bottom"
              size="medium"
              @command="handleCommand"
              style="color: #666666"
            >
              <span
                :class="[current == 5 ? 'nav-active' : '']"
                class="el-dropdown-link"
              >
                {{ $t('Home.nav' + index) }}
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                <el-dropdown-item
                  style="color: #666666"
                  command="/coach-connect/coach-profiles"
                  >{{ $t('Nav.CoachProfiles') }}</el-dropdown-item
                >
                <el-dropdown-item
                  style="color: #666666"
                  command="/coach-connect/opportunities"
                  >{{ $t('Nav.Opportunities') }}</el-dropdown-item
                >
                <el-dropdown-item
                  style="color: #666666"
                  command="/coach-connect/how-verify"
                  >{{ $t('Nav.VerifyCoach') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span v-else-if="index === 6">
            <el-dropdown
              placement="bottom"
              size="medium"
              @command="handleCommand"
              style="color: #666666"
            >
              <span
                :class="[current == 6 ? 'nav-active' : '']"
                class="el-dropdown-link"
              >
                {{ $t('Home.nav' + index) }}
              </span>
              <el-dropdown-menu slot="dropdown" class="dropdown-menu">
                <el-dropdown-item
                  style="color: #666666"
                  command="/coach-action/coaching-tools"
                  >{{ $t('Nav.CoachingTools') }}</el-dropdown-item
                >
                <el-dropdown-item
                  style="color: #666666"
                  command="/coach-action/sharing"
                  >{{ $t('Nav.OnlineExperts') }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span v-else>{{ $t('Home.nav' + index) }}</span>
        </p>
      </div>
      <div class="nav-btn" style="cursor: default">
        <span v-if="!loggedIn" class="reg-btn" @click="register">{{
          $t('Home.Register')
        }}</span>
        <i v-if="screenWidth < 680" @click="openCloseMenu" class="menu-icon">
        </i>
      </div>
    </div>
    <!-- 登入 -->
    <el-dialog
      v-loading="loadingLogin"
      class="dialog-con"
      :title="$t('Home.login')"
      :visible="centerDialogVisible"
      :width="screenWidth > 480 ? '30%' : '90%'"
      :append-to-body="true"
      center
      :modal="true"
      :before-close="closeDialog"
    >
      <div class="login_box">
        <!-- 头像 -->
        <div
          class="avatar-box"
          style="
            width: 100px;
            height: 100px;
            margin: 0 auto 5%;
            border: 1px solid #eee;
            border-radius: 50%;
          "
        >
          <img
            style="width: 100px; height: 100px; border-radius: 50%"
            src="@/assets/images/ihk-logo.jpeg"
            alt=""
          />
        </div>
        <!-- 表单 -->
        <el-form
          ref="LoginFormRef"
          :model="loginForm"
          label-width="0"
          :rules="LoginFormRules"
          class="login_form"
        >
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              prefix-icon="el-icon-lock"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span
        style="color: #0084d2; display:block; cursor: pointer; text-align:right; width: 100%;"
        type="text"
        class="forget"
        @click="
          forgetDialogVisible = true
          centerDialogVisible = false
        "
      >
        {{ $t('forget_password') }}
      </span>
      <template #footer>
        <span class="dialog-footer" style="width: 180px">
          <el-button
            style="padding: 12px 30px !important; color: #fff;"
            type="primary"
            @click="login"
            >{{ $t('log_in') }}</el-button
          >
          <!--<el-button
            style="padding: 12px 34px !important; color: #666666"
            @click="register"
            >{{ $t("Home.register") }}</el-button
          >-->
          <p style="color: #333333; font-size: 14px; margin-top: 20px">
            {{ $t('Home.account_not_yet')
            }}<span
              style="color: #0084d2; cursor: pointer"
              @click="$router.push('/message'), (centerDialogVisible = false)"
              >{{ $t('Home.sign_in_now') }}</span
            >
          </p>
        </span>
        <div class="third-party-login">
          <div class="container" @click="thirdPartyLogin('facebook')">
            <div class="logo">
              <img src="@/assets/images/svg/facebook.svg" />
            </div>
            <div class="text">
              <span>{{ $t('Header.facebook') }}</span>
            </div>
          </div>
          <div class="container" @click="thirdPartyLogin('linkedin')">
            <div class="logo">
              <img src="@/assets/images/svg/linkedin.svg" />
            </div>
            <div class="text">
              <span>{{ $t('Header.linkedin') }}</span>
            </div>
          </div>
          <div class="container" @click="thirdPartyLogin('google')">
            <div class="logo">
              <img src="@/assets/images/svg/google.svg" />
            </div>
            <div class="text">
              <span>{{ $t('Header.google') }}</span>
            </div>
          </div>
        </div>
        <div
          style="
            display: none;
            justify-content: center;
            align-items: center;"
        >
          <fb-signin-button
            ref="facebook_login"
            :params="fbSignInParams"
            @success="onSignInSuccess"
            @error="onSignInError"
            style=" width:20px;
                    margin:10px 4px -5px;
                    display: block;
                    padding: 5px 5px;
                    border-radius: 3px;
                    color: #fff;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    cursor: pointer;"
          >
            <img
              style="
             position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            color:#fff
           "
              src="../assets/images/svg/icon-fb2.svg"
            />
          </fb-signin-button>
        </div>
      </template>
    </el-dialog>
    <!-- drawer -->
    <el-drawer
      title=" "
      :visible="mobileDrawer"
      direction="ttb"
      custom-class="moblie-menu"
      :show-close="false"
      :modal="false"
      :withHeader="false"
      size="100%"
    >
      <div class="nav">
        <div class="logo" @click="goPush('/', '0')"></div>
        <div class="nav-btn">
          <span v-if="!loggedIn" class="reg-btn" @click="register">{{
            $t('Home.Register')
          }}</span>
          <i @click="openCloseMenu" class="el-icon-close menu"></i>
        </div>
      </div>
      <!-- <div class="moblie-nav"> -->
      <div class="moblie-nav">
        <span
          v-for="(item, index) in navList"
          :key="index"
          @click="goPush(item, index)"
          :class="[index == current ? 'nav-active' : '', 'nav-item']"
          >{{ $t('Home.nav' + index) }}</span
        >
        <span class="nav-item" @click="loginMemberInfo"
          ><i class="el-icon-user" style="margin-right: 5px"></i
          >{{ $t('Home.login') }}</span
        >
        <p class="nav-item">
          <i class="icon-cn"></i>
          <span
            @click="changLangMobileCN"
            class="hover-color"
            style="border-right: 1px solid"
            >{{ $t('cn') }}</span
          >
          <span @click="changLangMobileEN" class="hover-color">{{
            $t('en')
          }}</span>
        </p>
      </div>
      <div class="icons-txt">
        <div class="txt">
          <span class="phone">+852 5605 6013</span>
          <span>info@inspiringhk.org</span>
        </div>
        <div class="txt">
          <span @click="$router.push('/copyright'), (mobileDrawer = false)"
            >Copyright</span
          >
          <span @click="$router.push('/privacy'), (mobileDrawer = false)"
            >Privacy Policy</span
          >
          <span @click="$router.push('/term-of-use'), (mobileDrawer = false)"
            >Term of Use</span
          >
        </div>
        <div class="icons">
          <a href="https://www.facebook.com/ihksports/" target="_blank"></a>
          <a href="https://www.instagram.com/ihksports/" target="_blank"></a>
          <a
            href="https://www.linkedin.com/company-beta/3766637/"
            target="_blank"
          ></a>
          <a
            href="https://www.youtube.com/channel/UCaUTwxuFhaqfLTmnreBFjrg"
            target="_blank"
          ></a>
          <a href="mailto:info@inspiringhk.org" target="_blank"></a>
          <a href="https://donation.inspiringhk.org/" target="_blank"></a>
        </div>
      </div>
    </el-drawer>
    <!-- 忘记密碼 -->

    <el-dialog
      v-loading="forgetPasswordLoading"
      class="dialog-con"
      :title="$t('forget_password')"
      :visible="forgetDialogVisible"
      :append-to-body="true"
      center
      :show-close="false"
      :modal="true"
      width="30%"
    >
      <div class="login_box">
        <!-- 表单 -->
        <el-form  label-width="0" class="login_form">
          <el-form-item>
            <el-input
              :placeholder="$t('Home.email')"
              v-model="forget_email"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="forgetDialogVisible = false">{{
            $t('Home.exit')
          }}</el-button>
          <el-button type="primary" @click="forgetPasswordCode">{{
            $t('Home.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="forgetLoading"
      class="dialog-con"
      :title="$t('forget_password')"
      :visible="forgetPasswordDialog"
      :append-to-body="true"
      center
      :show-close="false"
      :modal="true"
      width="30%"
    >
      <div class="login_box">
        <!-- 表单 -->
        <el-form :model="forgetPassword" label-width="0" class="login_form">
          <el-form-item>
            <el-input
              :placeholder="$t('Home.code')"
              v-model="forgetPassword.code"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              :placeholder="$t('Home.email')"
              v-model="forgetPassword.email"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              show-password
              :placeholder="$t('Home.password')"
              v-model="forgetPassword.password"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="forgetPasswordDialog = false">{{
            $t('Home.exit')
          }}</el-button>
          <el-button type="primary" @click="forgetPasswordFun">{{
            $t('Home.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>

     <!-- 修改密碼 -->

    <el-dialog
      v-loading="chengePasswordLoading"
      class="dialog-con"
      :title="$t('Home.change_password')"
      :visible="chengePasswordDialog"
      :append-to-body="true"
      center
      :show-close="false"
      :modal="true"
      width="30%"
    >
      <div class="login_box">
        <!-- 表单 -->
        <el-form :model="memberPassword" label-width="0" class="login_form">
          <el-form-item>
            <el-input
              :placeholder="$t('Home.old_password')"
              v-model="memberPassword.old_password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              show-password
              :placeholder="$t('Home.new_password')"
              v-model="memberPassword.new_password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              show-password
              :placeholder="$t('Home.confirm_password')"
              v-model="reNewPassword"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="chengePasswordDialog = false">{{
            $t('Home.exit')
          }}</el-button>
          <el-button type="primary" @click="updatePassword">{{
            $t('Home.confirm')
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  loginUser,
  updatePassword,
  loginFacebookUser,
  getUserProfile,
  forgetPasswordCode,
  forgetPassword,
  validateToken
} from '@/api/memberUser'
export default {
  name: 'myHeader',
  data () {
    return {
      loadingLogin: false,
      mobileDrawer: false,
      // 修改新密码窗口
      chengePasswordDialog: false,
      screenWidth: window.document.body.clientWidth,
      centerDialogVisible: false,
      chengePasswordLoading: false,
      memberPassword: {
        old_password: '',
        new_password: ''
      },
      forget_email:'',

      forgetDialogVisible: false,
      forgetPasswordLoading: false,

      forgetPasswordDialog: false,
      forgetLoading: false,

      forgetPassword: {
        email: '',
        code: '',
        password: ''
      },
      reNewPassword: '',
      navList: [
        '/',
        '/about-us',
        '/inspire',
        '/safeguarding',
        // '/coach-connect',
        '/corner',
        '/coach-connect',
        '/coach-action',
        '/partners',
        '/member-zone'
      ],
      navLists: [
        '/',
        '/about-us',
        '/inspire',
        '/safeguarding',
        '/corner',
        '/coach-connect',
        '/coach-action',
        // '/coach-connect',
        '/partners'
      ],
      navListtow: ['/online-courses', 'sports-films'],
      current: 0,
      loginForm: {
        username: '',
        password: ''
      },
      LoginFormRules: {
        username: [{ required: true, message: '請輸入郵箱', trigger: 'blur' }],
        password: [{ required: true, message: '請輸入密碼', trigger: 'blur' }]
      },
      fbSignInParams: {
        scope: 'email,user_likes',
        return_scopes: true
      },
      facebookInfo: null,
      userProfile: null,
      welcomeMsg: '',
      loggedIn: false,
      thirdParty: false,
      getPath:'',
    }
  },
  created () {
    this.unchangedTab()
    this.isLogin()
  },
  watch: {
    $route: 'unchangedTab', // getTabelValueReset 是路由改变后触发的unchangedTab函数
    userProfile: function () {
      window.sessionStorage.setItem('user_id', this.userProfile.id);
      this.welcomeMsg = this.$t('welcome').replace(
        'XXX',
        this.$i18n.locale == 'en'
          ? this.userProfile.name_en
          : this.userProfile.name_zh_tw == '[EMPTY]' ||
            !this.userProfile.name_zh_tw
          ? this.userProfile.name_en
          : this.userProfile.name_zh_tw
      )
    }
  },
  methods: {
    thirdPartyLogin (party) {
      window.sessionStorage.setItem('party', party)
      if (party == 'google') {
        window.location.href = process.env.VUE_APP_GOOGLE_LOGIN_URL
      } else if (party == 'linkedin') {
        window.location.href = process.env.VUE_APP_LINKEDIN_LOGIN_URL
      } else if (party == 'facebook') {
        this.$refs.facebook_login.$el.click()
      }
    },
    onSignInSuccess (response) {
      this.loadingLogin = true
      //console.log(response); //返回第三方的登录信息 tolen等
      this.facebookInfo = response.authResponse
      let that = this
      window.FB.api(
        '/me?fields=id,email,education,age_range,birthday,first_name,gender,last_name',
        function (res) {
          //console.log(res)
          //let birthOfDate = res.birthday.split("/");
          const data = {
            email: res.email,
            name_en: res.first_name + ' ' + res.last_name,
            name_zh_tw: res.last_name + res.first_name,
            provider_user_id: res.id,
            access_token: that.facebookInfo.accessToken,
            access_token_expires_at:
              that.facebookInfo.data_access_expiration_time
            /*birth_of_date:
              birthOfDate[2] + "-" + birthOfDate[0] + "-" + birthOfDate[1],
            gender: res.gender.substring(0, 1),*/
          }
          loginFacebookUser(data)
            .then(res => {
              if (res.result == 'success') {
                window.sessionStorage.setItem('token', res.data.access_token)
                window.sessionStorage.setItem('third_party', true)
                that.centerDialogVisible = false
                that.mobileDrawer = false
                that.$message({
                  message: that.$t('Home.login_success'),
                  type: 'success'
                })
                that.isLogin()
                that.loadingLogin = false
                location.reload()
              }
            })
            .catch(() => {
              that.$message({
                message: that.$t('Home.login_fail'),
                type: 'error'
              })
              that.loadingLogin = false
            })
        }
      )
    },
    onSignInError (error) {
      console.log(error)
    },
    updatePassword () {
      if (
        this.memberPassword.old_password === '' ||
        this.memberPassword.new_password === ''
      ) {
        this.$message({
          message: '請輸入',
          type: 'error'
        })
        return
      }
      if (this.memberPassword.new_password.length < 6) {
        this.$message({
          message: '密碼不少於6位',
          type: 'error'
        })
        return
      }
      if (this.memberPassword.new_password !== this.reNewPassword) {
        this.$message({
          message: '兩次輸入密碼不壹致',
          type: 'error'
        })
        return
      }
      this.chengePasswordLoading = true
      updatePassword(this.memberPassword)
        .then(res => {
          if (res.result === 'success') {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.chengePasswordDialog = false
          }
        })
        .catch(() => {
          this.$message({
            message: '修改失敗',
            type: 'error'
          })
          this.chengePasswordLoading = false
        })
    },
    forgetPasswordCode(){
      if (this.forget_email === '') {
        this.$message({
          message: '請輸入',
          type: 'error'
        })
        return
      }
      this.forgetPasswordLoading = true;
      forgetPasswordCode({email:this.forget_email})
        .then(res => {
          if (res.result === 'success') {
            this.$message({
              message: '成功',
              type: 'success'
            })
            this.forgetPasswordDialog = true;
            this.forgetDialogVisible = false;
            this.forgetPasswordLoading = false
          }
        })
        .catch(() => {
          this.$message({
            message: '失敗',
            type: 'error'
          })
          this.forgetDialogVisible = false;
          this.forgetPasswordLoading = false
        })
    },
    forgetPasswordFun(){
      if (
        this.forgetPassword.email === '' ||
        this.forgetPassword.code === '' ||
        this.forgetPassword.password === ''
      ) {
        this.$message({
          message: '請輸入',
          type: 'error'
        })
        return
      }
      if (this.forgetPassword.password.length < 6) {
        this.$message({
          message: '密碼不少於6位',
          type: 'error'
        })
        return
      }
      forgetPassword(this.forgetPassword).then(res => {
          if (res.result === 'success') {
            this.$message({
              message: '成功',
              type: 'success'
            })
            this.forgetPasswordDialog = false
            this.forgetPasswordLoading = false
          }
        })
        .catch(() => {
          this.$message({
            message: '失敗',
            type: 'error'
          })
          this.forgetPasswordLoading = false
          this.forgetPasswordDialog = false

        })
    },
    exitMember (command) {
      if (command === 'a') {
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('third_party')
        window.sessionStorage.removeItem('user_id');
        this.isLogin()
        this.goPush('/', '0')
        location.reload()
        this.$message({
          message: '登出成功',
          type: 'success'
        })
      } else {
        this.memberPassword.old_password = ''
        this.memberPassword.new_password = ''
        this.reNewPassword = ''
        this.chengePasswordDialog = true
      }
    },
    isLogin: async function () {
      let locale = window.localStorage.getItem('locale')
      let token = window.sessionStorage.getItem('token')
      this.thirdParty = window.sessionStorage.getItem('third_party')
      if (!token) {
        this.loggedIn = false
        this.navList = this.navLists
      } else {
        let res = await validateToken(token)
        if (!res.valid) {
          this.loggedIn = false
          this.navList = this.navLists
          window.sessionStorage.removeItem('token')
          token = null
        } else {
          this.loggedIn = true
          if (!this.userProfile) {
            getUserProfile().then(res => {
              this.userProfile = res.data
            })
          }
        }
      }
      if (locale === 'en') {
        this.$t('Home').login = window.sessionStorage.getItem('token')
          ? 'Member Details'
          : 'Log in'
      } else {
        this.$t('Home').login = window.sessionStorage.getItem('token')
          ? '賬戶資料'
          : '登入'
      }
    },
    loginMemberInfo () {
      // if (this.isTokens) {
      //   this.goMember();
      // } else {
      //   this.centerDialogVisible = true;
      // }
      if (!this.loggedIn) {
        this.centerDialogVisible = true
      }
    },
    closeDialog () {
      this.centerDialogVisible = false
    },
    openCloseMenu () {
      this.mobileDrawer = !this.mobileDrawer
    },
    resetLoginForm () {
      this.$refs.LoginFormRef.resetFields()
    },
    login () {
      this.$refs['LoginFormRef'].validate(async valid => {
        if (valid) {
          this.loadingLogin = true
          const data = {
            username: this.loginForm.username,
            password: this.loginForm.password
          }
          loginUser(data)
            .then(async res => {
              if (res.result == 'success') {
                window.sessionStorage.setItem('token', res.data.access_token)
                window.sessionStorage.setItem(
                  'third_party',
                  res.data.third_party
                )
                this.centerDialogVisible = false
                this.mobileDrawer = false
                this.$message({
                  message: '登錄成功',
                  type: 'success'
                })
                this.userProfile = await getUserProfile().data
                this.isLogin()
                this.loadingLogin = false
                await new Promise((resolve, reject) => {
                  // resolve(this.$router.push('/'))
                  resolve(this.$router.go(0))
                })
                location.reload()
              }
            })
            .catch(() => {
              this.$message({
                message: '郵箱或密碼錯誤',
                type: 'error'
              })
              this.loadingLogin = false
            })
        } else {
          return false
        }
      })
    },
    goMember () {
      this.$router.push('/member-zone')
    },
    register () {
      this.$router.push('/message'),
        (this.centerDialogVisible = false),
        (this.mobileDrawer = false)
    },
    changLang () {
      let lang = window.localStorage.getItem('locale') == 'cn' ? 'en' : 'cn'
      window.localStorage.setItem('locale', lang)
      location.reload()
    },
    changLangMobileCN () {
      window.localStorage.setItem('locale', 'cn')
      location.reload()
    },
    changLangMobileEN () {
      window.localStorage.setItem('locale', 'en')
      location.reload()
    },
    goPush (page, i) {
      if (page === '/member-zone') {
        let isToken = window.sessionStorage.getItem('token') ? true : false
        if (!isToken) {
          this.$message({
            message: '請先登錄',
            type: 'error'
          })
          return
        }
      } else if (i == 'ihk') {
        window.open(page)
        return
      }
      this.mobileDrawer = false
      let str = window.location.href
      let index = str.lastIndexOf('#/')
      if (page === str.substring(index + 1, str.length)) {
        return
      }
      this.current = i
      this.$router.push(page)
      sessionStorage.setItem('pathName',page)
      this.getPath = sessionStorage.getItem('pathName')
      this.$emit('getpath',this.getPath)
    },
    //刷新保持tab
    unchangedTab () {
      let str = window.location.href
      let url = str.split('#/')[1]
      let route = '/' + url.split('/')[0]
      for (let i = 0; i < this.navList.length; i++) {
        if (route === this.navList[i]) {
          this.current = i
          return
        }
      }
    },
    handleCommand (command) {
      this.$router.push(command)
    },
    subscribe () {
      window.open('http://inspiringhk.org/#/inspiringHk/subscribe')
    }
  }
}
</script>
<style scoped lang="scss">
.el-button,
.el-button:focus,
.el-button:active {
  background-color: #399efc;
}
.el-button:hover {
  background-color: rgba(40, 50, 123, 0.75);
}
.third-party-login {
  display: flex;
  margin: 30px auto;
  flex-direction: column;
  align-self: center;
  align-items: stretch;
  max-width: 380px;
  .container {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 5px;
    min-height: 32px;
    box-sizing: border-box;
    padding: 10px 15px;
    cursor: pointer;
    /*width: 350px;*/
    margin-bottom: 20px;
    .logo {
      flex: 0 0 auto;
    }
    .logo img {
      max-width: 32px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .text {
      /*width: 320px;*/
      font-size: 20px;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }
    .text span {
      text-align: center;
      width: 100%;
    }
  }
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: #666666;
  //margin-bottom: 2%;
  height: 200px;
  .dialog-con {
    z-index: 5;
    .login_box {
      width: 450px;
      height: 200px;
      background-color: #fff;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .avatar-box {
        background: url('../assets/images/ihk-logo.jpeg') no-repeat 100%/100%;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background-color: #eee;
        }
      }

      .login_form {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        .facebook-icon {
          background: url('../assets/images/svg/icon-fb.svg') no-repeat 100%/100% !important;
          width: 20px;
          height: 20px;
          display: inline-block;
        }
        .btns {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .nav-active {
    color: #0e85d4;
  }
  .logo-ihk {
    width: 271px;
    height: 150px;
    margin-left: 0;
    background: url('../assets/images/ihk-logo.png') no-repeat 95%/95%;
    cursor: pointer;
  }
  .logo-cfya {
    width: 310px;
    height: 150px;
    margin-top: 65px;
    margin-left: 0;
    background: url('../assets/images/svg/cfya.svg') no-repeat 95%/95%;
    cursor: pointer;
  }
  .login-lang {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 50px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 8px;
    letter-spacing: 0px;
    cursor: pointer;
    ::v-deep .el-dropdown {
      font-size: 16px;
      font-weight: bold;
      margin-left: 8px;
    }
    img {
      width: 25px;
      height: 25px;
    }
    .login-box {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin-right: 20px;
      .login-icon {
        margin-left: 8px;
      }
    }
    .lang-box {
      display: flex;
      align-items: center;
      .lang-icon {
        margin-left: 8px;
        white-space: nowrap;
      }
    }
  }
  .nav-con {
    height: 50px;
    font-size: 22px;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 50px;
    .nav-item {
      ::v-deep .el-dropdown-link {
        font-size: 22px;
      }
    }
  }
  .nav-btn {
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: bold;
    // font-family: 'MHeiHK-Xbold';
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
      padding: 3px 10px;
      border: 2px solid #f15922;
      color: #f15922;
      letter-spacing: 0px;
      border-radius: 3px;
    }
    .reg-btn {
      margin-left: 15px;
      cursor: pointer;
    }
  }
  .nav-list {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -15px;
    .el-dropdown-link {
      cursor: pointer;
      font-size: 16px;
    }
    p {
      padding: 0px 20px;
      display: flex;
      height: 18px;
      align-items: center;
      border-right: 1px solid #333333;
      white-space: nowrap;
      cursor: pointer;
    }
    p:last-child {
      border-right: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .header-container {
    z-index: 5;
    height: 154px;
    .logo {
      width: 190px;
      height: 106px;
      margin-left: 50px;
      z-index: 6;
    }
    .logo-cfya {
      margin-top: 5%;
      margin-left: 0;
    }
    .nav-con {
      font-size: 12px;
      z-index: 6;
      margin-top: 0;
      .nav-btn {
        width: 100%;
        justify-content: flex-end;
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 34px;
          font-size: 20px;
          margin: 0 30px 0 20px;
          white-space: nowrap;
        }
        .menu-icon {
          width: 36px;
          height: 24px;
          margin: 0 50px 0 10px;
          background: url('../assets/images/svg/ic menu 24px.svg') no-repeat
            100%/100%;
        }
        .menu {
          font-size: 28px;
          margin-left: 8%;
        }
      }
    }
    ::v-deep .moblie-menu {
      width: 100%;
      height: 500px;
      padding: 0 50px;
      .nav {
        width: 100%;
        height: 154px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          width: 190px;
          height: 106px;
          margin-left: 0;
        }
        .nav-btn {
          top: 4%;
          justify-content: flex-end;
          width: 51%;
          font-weight: bold;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            font-size: 20px;
            white-space: nowrap;
          }
          .menu {
            font-size: 42px;
            margin-left: 40px;
          }
        }
      }
      .moblie-nav {
        width: 100%;
        .nav-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 90px;
          font-size: 32px;
          color: #333;
          border-right: 0;
          border-bottom: 1px solid #333333;
          cursor: pointer;
          .icon-cn {
            width: 44px;
            height: 44px;
            background: url('../assets/images/svg/Group 740.svg') no-repeat 100%/100%;
          }
          span {
            padding: 0 20px;
          }
          /* span:nth-child(1) {
            border-right: 1px solid #333;
          } */
          .hover-color:hover {
            color: #0e85d4;
          }
        }
        .nav-active {
          color: #0e85d4;
        }
      }
      .icons-txt {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        bottom: 0;
        width: calc(100% - 100px);
        font-size: 28px;
        color: #333;
        padding-bottom: 40px;
        margin: 0 auto;
        letter-spacing: 0;
        .icons {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50px;
          padding: 40px 0 30px 0;
          a {
            display: inline-block;
            width: 50px;
            height: 50px;
            margin: 30px 20px;
          }
          a:nth-child(1) {
            background: url('../assets/images/svg/icon-fb.svg') no-repeat 100%/100%;
          }
          a:nth-child(2) {
            background: url('../assets/images/svg/icon-ig.svg') no-repeat 100%/100%;
          }
          a:nth-child(3) {
            background: url('../assets/images/svg/icon-linkedin.svg') no-repeat
              100%/100%;
          }
          a:nth-child(4) {
            background: url('../assets/images/svg/icon-youtube.svg') no-repeat
              100%/100%;
          }
          a:nth-child(5) {
            background: url('../assets/images/svg/icon-mail.svg') no-repeat 100%/105%;
          }
          a:nth-child(6) {
            background: url('../assets/images/svg/icon-map.svg') no-repeat 100%/100%
              100%;
          }
        }
        .txt {
          width: 100%;
          margin-top: 30px;
          span {
            padding: 0px 20px;
            height: 18px;
            align-items: center;
            border-right: 1px solid #333333;
            cursor: pointer;
          }
          span:last-child {
            border-right: 0;
          }
        }
        .copyright {
          margin: 6px auto;
        }
      }
    }
  }
}
</style>
