import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import './style/reset.scss'
import './style/common.scss'
import i18n from '@/i18n/i18n'
import VueI18n from 'vue-i18n'
import 'lib-flexible/flexible'
import VueAnalytics from 'vue-analytics'
// import './utils/flexible'
import {
  Icon,
  Input,
  Popover,
  Button,
  CheckboxGroup,
  Checkbox,
  Form,
  Dialog,
  Upload,
  FormItem,
  Loading,
  Notification,
  Drawer,
  Radio,
  Select,
  Col,
  DatePicker,
  TimePicker,
  Switch,
  RadioGroup,
  RadioButton,
  Row,
  Option,
  Tabs,
  TabPane,
  Message,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  MessageBox,
  Table,
  TableColumn,
  Breadcrumb,
  BreadcrumbItem,
  Pagination,
  Menu,
  Submenu,
  Progress,
  Tooltip,
  Image,
} from 'element-ui'
// import ElementPlus from 'element-plus'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/font/font.css'
import '@/style/enStyle.scss'
import FBSignInButton from 'vue-facebook-signin-button'
Vue.use(FBSignInButton)
Vue.config.productionTip = false
// 挂载到$notify
Vue.prototype.$notify = Notification
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox
Vue.use(VueI18n)
Vue.use(VueAwesomeSwiper)
Vue.use(Icon)
  .use(Input)
  .use(Popover)
  .use(Button)
  .use(CheckboxGroup)
  .use(Checkbox)
  .use(Form)
  .use(Dialog)
  .use(Upload)
  .use(FormItem)
  .use(Loading)
  .use(Drawer)
  .use(Radio)
  .use(Select)
  .use(Option)
  .use(Option)
  .use(Col)
  .use(DatePicker)
  .use(TimePicker)
  .use(Switch)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Row)
  .use(Tabs)
  .use(TabPane)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Dropdown)
  .use(Table)
  .use(TableColumn)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Pagination)
  .use(Menu)
  .use(Submenu)
  .use(Progress)
  .use(Tooltip)
  .use(Image)
  .use(VueAnalytics, {
    id: 'G-18NB7H0LTY',
    router: router,
    autoTracking: {
      screenview: true,
    },
  })

// Custom directives
Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden'
})

// Global variables
Vue.prototype.$globals = Vue.observable({
  viewAllFilms: false,
})

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
